
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import api from "@/api/index.js";
import MonomerTabWindow from "./tab.vue";

export default {
    name: "monomerHouse",
    computed: {
        ...mapGetters("publicInfo", ["slideShowType"]),
        ...mapGetters("cesiumInfo", [
            "nowChooseMonomerInfo",
            "nowChooseMonomerInfoObj",
        ]),
        ...mapState(["findPersonValue"]),
    },
    components: {
        MonomerTabWindow,
    },
    data() {
        return {
            houseInfo: {},
            searchList: [],
            houseHolder: "",
        };
    },
    watch: {
        nowChooseMonomerInfo(newInfo, oldInfo) {
            console.log(newInfo);
            if (newInfo.info.type == "house") {
                this.getHouseInfo();
            }
        },
        slideShowType(newType, oldType) {
            console.log(newType);
            if (this.slideShowType == "monomer_house_search") {
                this.getHouseList();
            }
        },
        findPersonValue(newValue, oldValue) {
            if (newValue) {
                if (this.slideShowType == "monomer_house_search") {
                    this.getHouseList();
                }
            }
        },
    },
    mounted() {
        if (this.slideShowType == "monomer_house") {
            this.getHouseInfo();
        }

        if (this.slideShowType == "monomer_house_search") {
            this.getHouseList();
        }
    },
    methods: {
        ...mapActions("publicInfo", ["changeSlideTypeAction"]),
        ...mapMutations("cesiumInfo", ["changeNowChooseMonomerInfoObj"]),
        getHouseInfo() {
            let _this = this;
            let { nowChooseMonomerInfo, nowChooseMonomerInfoObj } = this;
            console.log(nowChooseMonomerInfo);
            console.log(nowChooseMonomerInfoObj);

            this.$getApi(api.getHouseInfo, {
                houseId: nowChooseMonomerInfoObj.id || nowChooseMonomerInfo.id,
            }).then((res) => {
                console.log(res);
                if (1 == res.code) {
                    _this.houseInfo = res.data;
                    _this.initHouseMonomer(res.data.monomer);
                    res.data.houseInfo.forEach((item) => {
                        if (item.relation == "户主") {
                            _this.houseHolder = item.userName;
                        }
                    });
                }
            });
        },
        getHouseList(item) {
            let _this = this;
            let { findPersonValue } = this;

            this.$getApi(api.findPerson, {
                queryName: findPersonValue,
            }).then((res) => {
                console.log(res);
                if (1 == res.code) {
                    _this.searchList = res.data.houseInfo;
                }
            });
        },
        initHouseMonomer(item) {
            let _this = this;
            let { slideShowType } = this;
            if (item.coordinates.length > 0) {
                item.coordinates = JSON.parse(item.coordinates);
                let obj = {
                    id:
                        slideShowType == "monomer_house"
                            ? item.data.id
                            : item.areaId,
                    info: {
                        type: item.type,
                    },
                    type: "monomer",
                };
                item.id = obj;
                window.primitiveLayer.addPolygon(item);

                setTimeout(() => {
                    window.primitiveLayer.changePrimitiveColor(
                        slideShowType == "monomer_house"
                            ? item.data.id
                            : item.areaId,
                        [1, 1, 0, 0.5]
                    );

                    _this.showHousePrimitive(
                        slideShowType == "monomer_house"
                            ? item.data.id
                            : item.areaId
                    );
                }, 1000);
            }
        },
        showHousePrimitive(id) {
            window.primitiveLayer.flyTo(id);
            window.primitiveLayer.changePrimitiveColor(id);
        },
        closeWindow() {
            let { houseInfo, slideShowType } = this;

            if (slideShowType == "monomer_house") {
                window.primitiveLayer.changePrimitiveColor(
                    houseInfo.monomer.data.id,
                    [0, 0, 0, 0]
                );
            }

            this.changeSlideTypeAction("zhzl");
        },
    },
};
