
import PropertyManage from "../components/propertyManager.vue";
import GarbageClassification from "../components/garbageClassification.vue";
import LoadPark from "../components/loadPark.vue";

import { mapGetters } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
    name: "slideRoom",
    components: {
        PropertyManage,
        GarbageClassification,
        LoadPark,
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive,
    },
    computed: {
        ...mapGetters("publicInfo", [
            "slideShowType",
            "slideRoomShow",
            "slideTypeIndex",
        ]),
    },
    watch: {},
    data() {
        return {
            slideType: "right",
        };
    },
    mounted() {},
    methods: {},
};
