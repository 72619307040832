
import Modal from "@/components/publicDillog/index.vue";
import TitleText from "@/components/titleText.vue";
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
        Modal,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },

    data() {
        return {
            show: false,
            left: "",
            top: "",
            lineleft: "",
            linetop: "",
            toplineleft: "",
            toplinetop: "",
            diaValue: "",
            diaContent: "",
            posIndex: -1,
        };
    },
    computed: {
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
        swiperList() {
            let _this = this;

            return {
                volunteer: _this.$refs.volunteer.$swiper,
            };
        },
        swiperOptionsList() {
            let _this = this;

            return {
                volunteer: {
                    autoplay: {
                        disableOnInteraction: false,
                        delay: 2000,
                    }, //可选选项，自动滑动
                    slidesPerView: 3.1,
                    // width: 5 * fontBaseSize * scaleNum,
                    // height: ((windowHeight / 3) - (1.5 * fontBaseSize))
                    observer: true,
                    on: {
                        init: function (swiper) {
                            //Swiper初始化了
                            setTimeout(() => {
                                let { swiperList } = _this;
                                swiperList.volunteer.$el[0].onmouseenter =
                                    function () {
                                        swiperList.volunteer.autoplay.stop();
                                    };
                                swiperList.volunteer.$el[0].onmouseleave =
                                    function () {
                                        swiperList.volunteer.autoplay.start();
                                    };
                            }, 1000);
                        },
                    },
                },
            };
        },
    },
    watch: {
        show(newShow, oldShow) {
            console.log(this.swiperList);
            if (newShow) {
                this.swiperList.volunteer.autoplay.pause();
            } else {
                this.swiperList.volunteer.autoplay.start();
            }
        },
    },
    mounted() {
    },
    methods: {
        changeStyle(item, index, e) {
            let { posIndex } = this;

            this.posIndex = index == posIndex ? -1 : index;
            this.diaContent = item.content;
            this.diaValue = item.value;
            this.show = true;
            this.left = e.pageX - 300 + "px";
            this.top = e.pageY - 284 + "px";
            this.lineleft = e.pageX - 186 + "px";
            this.linetop = e.pageY - 304 + "px";
            this.toplineleft = e.pageX - 86 + "px";
            this.toplinetop = e.pageY - 494 + "px";
        },
        hideModal() {
            this.posIndex = -1
            this.show = false;
        },
    },
};
