export default {
    hldj: '/api_btq/hldj',
    pajk: '/api_btq/pajk',
    msbz: '/api_btq/msbz',
    gfzz: '/api_btq/gfzz',
    csgl: '/api_btq/csgl',
    street:'/api_btq/street',
    saveDot: '/api_btq/saveDot',
    getDotList: '/api_btq/getDotList',
    getDotParams: '/api_btq/getDotParams',
    getDotIcon: '/api_btq/getDotIcon',
    monomerDelete: '/api_btq/monomerDelete',
    monomerGet: '/api_btq/monomerGet',
    monomerSave: '/api_btq/monomerSave',
    getBuildingInfo: "/api_btq/buildingInfo",
    getMonomerList: '/api_btq/monomerList',
    getAreaList: '/api_btq/getAreaList',
    getVillageList: '/api_btq/villageList',
    getBuildList: '/api_btq/buildingList',
    getUnitList: '/api_btq/cellList',
    getUnitInfo: '/api_btq/cellInfo',
    getBuildInfo: '/api_btq/buildingInfo',
    getCellInfo: '/api_btq/cellInfo',
    getHouseInfo: '/api_btq/houseInfo',
    getCrowdList: '/api_btq/getBuildingResidentList',//获取楼栋特殊人群信息
    saveArea: '/api_btq/areaSave',
    refresh: '/api_btq/refresh',
    videos: '/v1/videos/url/ST_00000017',
    findPerson: '/api_btq/findPerson', // 人找房
    getResidentMonomerList: '/api_btq/getResidentMonomerList',
    warningRead: '/api_btq/warningRead', // 告警阅读信息
    login:'/api_btq/login'
}