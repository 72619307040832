
import { mapGetters } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    components: {
        TitleText,
    },
    computed: {
        ...mapGetters("msbz", ["msbzData", "msbzGetApi"]),
    },
    watch: {
        msbzGetApi(newData, oldData) {
            if (newData) {
                this.getCmpData();
            }
        },
    },
    data() {
        return {
            activityList: [],
        };
    },
    mounted() {
        if (this.msbzGetApi) {
            this.getCmpData();
        }
    },
    methods: {
        getCmpData() {
            let { msbzData } = this;

            this.activityList = msbzData.activityList;
        },
    },
};
