
import { mapGetters } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    computed: {
        ...mapGetters("publicInfo", ["eChartsLoad"]),
        ...mapGetters("csgl", ["csglData", "csglGetApi"]),
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
    },
    components: {
        TitleText,
    },
    data() {
        return {
            numberStyle: {
                fontSize: 10,
                color: "#1877F5",
                // width: 10,
                // letterSpacing:0,
                height: 20,
            },
            pageData: {},
        };
    },
    watch: {
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { csglGetApi } = this;

            let eChart = this.$echarts.init(
                document.getElementById("reportEChart")
            );

            if (csglGetApi && newStaue) {
                this.cmpData = this.csglData;

                setTimeout(() => {
                    _this.drawEchart();
                }, 500);
            } else {
                eChart.dispose();
            }
        },
        csglGetApi(newObj, oldObj) {
            let _this = this;
            let { eChartsLoad } = this;
            if (eChartsLoad && newObj) {
                this.cmpData = this.csglData;
                setTimeout(() => {
                    _this.drawEchart();
                }, 500);
            }
        },
    },
    mounted() {
        let _this = this;
        if (this.eChartsLoad && this.csglGetApi) {
            this.cmpData = this.csglData;
            setTimeout(() => {
                _this.drawEchart();
            }, 500);
        }
    },
    methods: {
        drawEchart() {
            let _this = this;
            let { cmpData } = this;
            let nowDate = new Date();
            let nowMonth = nowDate.getMonth();
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("reportEChart")
            );

            myChart.setOption({
                // tooltip: {
                //     show: true,
                //     trigger: "item",
                //     formatter: "{b}: {c}人",
                // },
                // legend: {
                //     top: "5%",
                //     left: "center",
                // },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                legend: {
                    data: ["已处理", "处理中", "待处理"],
                    right: 0,
                    top: 10,
                    textStyle: {
                        color: "#fff",
                    },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "5%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        axisLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color: "rgba(255,255,255,0.1)",
                            },
                        },
                        axisLabel: {
                            // formatter: '{value}',
                            textStyle: {
                                color: "rgba(255, 255, 255, 0.8)",
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        type: "category",
                        data: cmpData.non_build_1.map((item) => {
                            return item.month;
                        }),
                    },
                ],
                yAxis: [
                    {
                        axisLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: "rgba(255, 255, 255, 0.8)",
                            },
                        },
                        minInterval: 1,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color: "rgba(255, 255, 255, 0.1)",
                            },
                        },
                        type: "value",
                    },
                ],
                series: [
                    {
                        name: "待处理",
                        type: "bar",
                        stack: "type",
                        barWidth: 12,
                        emphasis: {
                            focus: "series",
                        },
                        data: cmpData.non_build_1.map((item) => {
                            return item.ct;
                        }),
                        itemStyle: {
                            color: "#16FB89",
                        },
                    },
                    {
                        name: "处理中",
                        type: "bar",
                        stack: "type",
                        barWidth: 12,
                        emphasis: {
                            focus: "series",
                        },
                        data: cmpData.non_build_2.map((item) => {
                            return item.ct;
                        }),
                        itemStyle: {
                            color: "#2AF3FF",
                        },
                    },
                    {
                        name: "已处理",
                        type: "bar",
                        stack: "type",
                        emphasis: {
                            focus: "series",
                        },
                        barWidth: 12,
                        data: cmpData.non_build_3.map((item) => {
                            return item.ct;
                        }),
                        itemStyle: {
                            color: "#FFE32A",
                        },
                    },
                ],
            });
        },
    },
};
