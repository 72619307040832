
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "toolTop",
  computed: {
    ...mapGetters("publicInfo", [
      "slideShowType",
      "slideRoomShow",
      "loadingPageHide",
      "slideTypeIndex",
      "nowOrgInfo",
    ]),
  },
  data() {
    return {
      lastPageType: "",
    };
  },
  watch: {
    slideShowType(newType, oldType) {
      if (
        oldType == "point" ||
        oldType == "lively" ||
        oldType == "party" ||
        oldType == "safe" ||
        oldType == "city"
      ) {
        this.lastPageType = oldType;
      }
    },
  },
  mounted() {},
  methods: {
    ...mapMutations("publicInfo", ["changeSlideTypeIndex"]),
    ...mapActions("publicInfo", [
      "changeSlideRoomShowAction",
      "changeSlideTypeAction",
    ]),
    backStreetView() {
      this.changeSlideTypeIndex(-1);
      this.changeSlideTypeAction("street");
    },
    goCommunity() {
      let { lastPageType } = this;
      this.changeSlideTypeAction(lastPageType);

      window.toolMakeMonomer.close();
    },
    goTool() {
      this.changeSlideRoomShowAction(false);
      this.changeSlideTypeAction("tool");
    },
    logout() {
      this.$confirm("确认退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.clear();
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {});
    },
  },
};
