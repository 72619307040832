
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["expertShow", "expertShowId", "slideShowType"]),
    },
    data() {
        return {
            drawerList: {},
            imgList: [],
        };
    },
    watch: {
        expertShow(newType, oldType) {
            console.log(newType);

            if (newType) {
                this.getDrawer();
            }
        },
    },
    mounted() {
        this.getDrawer();
    },
    methods: {
        dataTitle() {
            this.typeList = this.type.split(",");
        },
        getDrawer() {
            let _this = this;
            let { expertShowId } = this;
            _this
                .$getApi("api_btq/expertDetail", {
                    userId: expertShowId,
                })
                .then((res) => {
                    if (1 == res.code) {
                        console.log(res);
                        _this.drawerList = res.data;
                        // _this.drawerList.forEach((item, index) => {
                        //   if (item.pics != "" && item.pics) {
                        //     item.pics = item.pics.split(",");
                        //   }

                        //   item.flag = index > 0 ? false : true;
                        // });
                    }
                });
        },
        changeDiaFn(item, index) {
            let { drawerList } = this;

            drawerList[index].flag = !item.flag;
            this.drawerList = drawerList.concat([]);
        },
    },
};
