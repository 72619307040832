
import { mapGetters, mapActions } from "vuex";
import { CollBox, Ball } from "@/assets/js/public/collision";
import publicFunc from "@/assets/js/public/index";
import TitleText from "@/components/titleText.vue";

export default {
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {
            proList: [],
            cmpData: {
                proList: [],
            },
        };
    },
    components: {
        TitleText,
    },
    computed: {
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
        ...mapGetters("publicInfo", ["eChartsLoad"]),
    },
    watch: {
        gfzzGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;
            if (eChartsLoad && newData) {
                this.cmpData = this.gfzzData;
                // _this.proList = gfzzData.proList;

                if (eChartsLoad) {
                    setTimeout(() => {
                        _this.ballContainer();
                        _this.drawEchart();
                    }, 500);
                }
            }
        },
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { gfzzData, gfzzGetApi } = this;

            if (gfzzGetApi && newStaue && newStaue != oldStaue) {
                setTimeout(() => {
                    _this.drawEchart();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("echartSpecialist")
                );
                let eChartBg = this.$echarts.init(
                    document.getElementById("echartSpecialistBg")
                );

                eChart.dispose();
                eChartBg.dispose();
            }
        },
    },
    mounted() {
        let _this = this;
        window.toGetSpecialManDetail = (userId) => {
            _this.toGetSpecialManDetail(userId);
        };
        if (this.gfzzGetApi) {
            this.cmpData = this.gfzzData;

            setTimeout(() => {
                _this.ballContainer();
                _this.drawEchart();
            }, 500);
        }
    },
    methods: {
        ...mapActions(["changeExpertShowAction", "changeExpertShowIdAction"]),
        toGetSpecialManDetail(id) {
            let _this = this;
            _this.changeExpertShowIdAction(id);
            _this.changeExpertShowAction(true);
        },
        drawEchart() {
            let _this = this;
            let { cmpData } = this;
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("echartSpecialist")
            );

            myChart.setOption({
                series: [
                    {
                        type: "pie",
                        center: ["50%", "50%"],
                        radius: ["55%", "65%"],
                        selectedMode: "single",
                        avoidLabelOverlap: false,
                        itemStyle: {},
                        label: {
                            alignTo: "edge",
                            formatter: "{b}:\n{c}",
                            color: "#fff",
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(255, 255, 255, 0.3)",
                            },
                            label: {
                                show: true,
                                fontSize: "15",
                                fontWeight: "bold",
                                textBorderColor: "rgba(0,0,0,0)",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: cmpData.chatCount,
                                name: "线上交流(次)",
                                itemStyle: {
                                    color: "#42A6AF",
                                },
                            },
                            {
                                value: cmpData.rcdCount,
                                name: "咨询问题(个)",
                                itemStyle: {
                                    color: "#1877F5",
                                },
                            },
                        ],
                    },
                ],
            });

            let echartBg = this.$echarts.init(
                document.getElementById("echartSpecialistBg")
            );

            echartBg.setOption({
                angleAxis: {
                    startAngle: 360,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    minorTick: {
                        show: false,
                    },
                    min: 0,
                    max: 1,
                },
                grid: {
                    left: 10,
                    top: 10,
                    right: 0,
                    bottom: 0,
                },
                radiusAxis: {
                    type: "category",
                    data: [""],
                    axisLabel: {
                        //    interval:0
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                },
                polar: {
                    center: ["50%", "50%"],
                    radius: ["69%", "75%"],
                },
                series: [
                    {
                        type: "bar",
                        data: [1],
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                1,
                                0,
                                0,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(66, 166, 175, 0.5)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(24, 119, 245, 0)",
                                    },
                                ]
                            ),
                        },
                        label: {
                            fontSize: 10,
                        },
                        barWidth: 8,
                        coordinateSystem: "polar",
                        // emphasis: {
                        //     focus: 'series'
                        // }
                    },
                ],
            });
        },

        ballContainer() {
            // 民意直通车生成碰撞球
            let _this = this;
            var says = this.cmpData.proList;
            // var saysLength = says.length ? says.length : 6;
            var oC = document.getElementById("container");
            var mxwidth = oC.offsetWidth;
            var mxheight = oC.offsetHeight;

            var oB = new CollBox("container");
            oB.ballRun();

            for (var i = 0; i < says.length; i++) {
                var b = publicFunc.rand(5, 10);
                var x = publicFunc.rand(b, mxwidth - b);
                var y = publicFunc.rand(b, mxheight - b);
                var ball = new Ball({
                    b: b,
                    x: x,
                    y: y,
                    sx: publicFunc.rand(1, 2),
                    sy: publicFunc.rand(1, 2),
                    opa: 1,
                    fontSize: publicFunc.rand(10, 16),
                    callBack: function (n) {
                        this.setOpa(publicFunc.rand(30, 100) / 100);
                    },
                });

                let onlineDiv = "";
                if (says[i].onlineStatus == 1) {
                    onlineDiv = `<div class="_online"></div>`;
                }

                ball.setHTML(`
                    <div class="_tagBall" onClick="toGetSpecialManDetail('${says[i].userId}')">
                        <img class="_ball_image" src="${says[i].headPortrait}" />
                        <div class="_ball_name">${says[i].realName} ${onlineDiv}</div>
                    </div>
                `);

                oB.addBall(ball);
            }
        },
    },
};
