
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["winShow", "winShowType", "slideShowType"]),
  },
  data() {
    return {
      drawerList: [],
      imgList: [],
      type: "",
    };
  },
  watch: {
    winShow(newType, oldType) {
      console.log(newType);

      if (newType) {
        this.getDrawer();
      }
    },
    winShowType(newType, oldType) {
      console.log(newType);

      if (winShowType) {
        this.getDrawer();
      }
    },
  },
  mounted() {
    this.getDrawer();
  },
  methods: {
    dataTitle() {
      this.typeList = this.type.split(",");
    },
    getDrawer() {
      let _this = this;
      let { winShowType } = this;
      console.log(winShowType);
      _this
        .$getApi("api_btq/drawer", {
          type: winShowType,
        })
        .then((res) => {
          if (1 == res.code) {
            console.log(res);
            _this.drawerList = res.data.drawerList;
            _this.drawerList.forEach((item, index) => {
              if (item.pics != "" && item.pics) {
                item.picsArr = item.pics.split(",");
              }
              if (item.picPs != "" && item.picPs) {
                item.picsPArr = item.picPs.split(",");
              }
              if (item.picRs != "" && item.picRs) {
                item.picsRArr = item.picRs.split(",");
              }
              if (item.status != "" && item.status) {
                if (item.status == 0) {
                  item.statusStr = "失效";
                } else if (item.status == 1) {
                  item.statusStr = "进行中";
                } else {
                  item.statusStr = "报名截止";
                }
              }

              item.flag = index > 0 ? false : true;
            });
          }
        });
    },
    changeDiaFn(item, index) {
      let { drawerList } = this;

      drawerList[index].flag = !item.flag;
      this.drawerList = drawerList.concat([]);
    },
  },
};
