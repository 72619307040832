
import { mapGetters, mapActions } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters("publicInfo", ["eChartsLoad"]),
        ...mapGetters("street", ["streetGetApi", "streetData"]),
    },
    components: {
        TitleText,
    },
    watch: {
        streetGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;

            if (newData && eChartsLoad) {
                setTimeout(() => {
                    _this.drawLineLeft();
                    _this.drawLineRight();
                }, 500);
            }
        },
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { streetGetApi } = this;

            if (streetGetApi && newStaue) {
                setTimeout(() => {
                    _this.drawLineLeft();
                    _this.drawLineRight();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("publicServices")
                );

                let eChartBg = this.$echarts.init(
                    document.getElementById("publicServicesBg")
                );
                eChartBg.dispose();
                eChart.dispose();

                let eChart2 = this.$echarts.init(
                    document.getElementById("publicServicesRight")
                );
                let eChart2Bg = this.$echarts.init(
                    document.getElementById("publicServicesRightBg")
                );
                eChart2.dispose();
                eChart2Bg.dispose();
            }
        },
    },
    mounted() {
        let _this = this;
        if (this.streetGetApi && this.eChartsLoad) {
            setTimeout(() => {
                _this.drawLineLeft();
                _this.drawLineRight();
            }, 500);
        }
    },
    methods: {
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        drawLineLeft() {
            let _this = this;
            let { streetData, streetGetApi } = this;
            let publicServicesBg = this.$echarts.init(
                document.getElementById("publicServicesBg")
            );

            publicServicesBg.setOption({
                angleAxis: {
                    startAngle: 360,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    minorTick: {
                        show: false,
                    },
                    min: 0,
                    max: 1,
                },
                grid: {
                    left: 10,
                    top: 10,
                    right: 0,
                    bottom: 0,
                },
                radiusAxis: {
                    type: "category",
                    data: [""],
                    axisLabel: {
                        //    interval:0
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                },
                polar: {
                    center: ["50%", "50%"],
                    radius: ["74%", "80%"],
                },
                series: [
                    {
                        type: "bar",
                        data: [1],
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                1,
                                0,
                                0,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(66, 166, 175, 0.5)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(24, 119, 245, 0)",
                                    },
                                ]
                            ),
                        },
                        label: {
                            fontSize: 10,
                        },
                        barWidth: 8,
                        coordinateSystem: "polar",
                        // emphasis: {
                        //     focus: 'series'
                        // }
                    },
                ],
            });

            // 基于准备好的dom，初始化echarts实例
            let publicServices = this.$echarts.init(
                document.getElementById("publicServices")
            );
            // 绘制图表
            publicServices.setOption({
                color: ["#42A6AF", "#1877F5"],
                series: [
                    {
                        type: "pie",
                        center: ["50%", "50%"],
                        radius: ["60%", "70%"],
                        selectedMode: "single",
                        avoidLabelOverlap: false,
                        itemStyle: {},
                        label: {
                            // position: "outer",
                            alignTo: "edge",
                            formatter: "{b}:\n{c}",
                            color: "#fff",
                            textBorderColor: "rgba(0,0,0,0)",
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(255, 255, 255, 0.3)",
                            },
                            label: {
                                show: true,
                                fontSize: "12",
                                textBorderColor: "rgba(0,0,0,0)",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: streetGetApi
                                    ? streetData.goods_point[1].value * 1
                                    : 0,
                                name: "功分服务(个)",
                                itemStyle: {
                                    color: "#42A6AF",
                                },
                            },
                            {
                                value: streetGetApi
                                    ? streetData.goods_point[0].value * 1
                                    : 0,
                                name: "功分商品(个)",
                                itemStyle: {
                                    color: "#1877F5",
                                },
                            },
                        ],
                    },
                ],
            });
            publicServices.on("click", function (params) {
                _this.changeWinShowAction(true);
                _this.changeWinShowTypeAction("goods_point");
            });
        },
        drawLineRight() {
            // 基于准备好的dom，初始化echarts实例
            let _this = this;
            let { streetData, streetGetApi } = this;

            let publicServicesRightBg = this.$echarts.init(
                document.getElementById("publicServicesRightBg")
            );

            publicServicesRightBg.setOption({
                angleAxis: {
                    startAngle: 360,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    minorTick: {
                        show: false,
                    },
                    min: 0,
                    max: 1,
                },
                grid: {
                    left: 10,
                    top: 10,
                    right: 0,
                    bottom: 0,
                },
                radiusAxis: {
                    type: "category",
                    data: [""],
                    axisLabel: {
                        //    interval:0
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                },
                polar: {
                    center: ["50%", "50%"],
                    radius: ["74%", "80%"],
                },
                series: [
                    {
                        type: "bar",
                        data: [1],
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                1,
                                0,
                                0,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(66, 166, 175, 0.5)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(24, 119, 245, 0)",
                                    },
                                ]
                            ),
                        },
                        label: {
                            fontSize: 10,
                        },
                        barWidth: 8,
                        coordinateSystem: "polar",
                        // emphasis: {
                        //     focus: 'series'
                        // }
                    },
                ],
            });

            let publicServicesRight = this.$echarts.init(
                document.getElementById("publicServicesRight")
            );
            // 绘制图表
            publicServicesRight.setOption({
                color: ["#42A6AF", "#1877F5"],
                series: [
                    {
                        type: "pie",
                        center: ["50%", "50%"],
                        radius: ["60%", "70%"],
                        selectedMode: "single",
                        avoidLabelOverlap: false,
                        itemStyle: {},
                        label: {
                            // position: "outer",
                            alignTo: "edge",
                            formatter: "{b}:\n{c}",
                            color: "#fff",
                            textBorderColor: "rgba(0,0,0,0)",
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(255, 255, 255, 0.3)",
                            },
                            label: {
                                show: true,
                                fontSize: "14",
                                textBorderColor: "rgba(0,0,0,0)",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: streetGetApi
                                    ? streetData.org_point[1].value * 1
                                    : 0,
                                name: "合作商家(个)",
                                itemStyle: {
                                    color: "#42A6AF",
                                },
                            },
                            {
                                value: streetGetApi
                                    ? streetData.org_point[0].value * 1
                                    : 0,
                                name: "公益组织(个)",
                                itemStyle: {
                                    color: "#1877F5",
                                },
                            },
                        ],
                    },
                ],
            });
            publicServicesRight.on("click", function (params) {
                _this.changeWinShowAction(true);
                _this.changeWinShowTypeAction("org_point");
            });
        },
    },
};
