
import { mapGetters, mapActions } from "vuex";
import Number from "@/components/number/index.vue";
import TitleText from "@/components/titleText.vue";
export default {
    name: "componentDemo",
    components: {
        Number,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    computed: {
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
    },
    watch: {
        gfzzGetApi(newType, oldType) {
            if (newType) {
                this.cmtCount = this.gfzzData.cmt_count;
            }
        },
    },
    data() {
        return {
            cmtCount: [
                {
                    key: "投票",
                    value: 0,
                },
                {
                    key: "摇号",
                    value: 0,
                },
                {
                    key: "社区公告",
                    value: 0,
                },
                {
                    key: "议事厅",
                    value: 0,
                },
            ],
            numberStyle: {
                fontSize: 50,
                color: "#2AF3FF",
                width: 30,
                height: 60,
                fontFamily: "DigitalReadoutThickUpright",
            },
            numberMinStyle: {
                fontSize: 24,
                color: "#fff",
                width: 16,
                height: 36,
                fontFamily: "DigitalReadoutThickUpright",
            },
        };
    },
    mounted() {
        let _this = this;
        let { gfzzData, cmtCount } = this;
        if (this.gfzzGetApi) {
            this.cmtCount = gfzzData?.cmt_count ?? cmtCount;
            console.log(this.cmtCount);
        }
    },
    methods: {
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        showFn(type) {
            let _this = this;
            _this.changeWinShowAction(true);
            _this.changeWinShowTypeAction(type);
        },
    },
};
