
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import api from "@/api/index";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    components: {
        TitleText,
    },
    computed: {
        ...mapState(["winShow", "winShowType"]),
        ...mapGetters("pajk", ["pajkData", "pajkGetApi"]),
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    watch: {
        pajkGetApi(newObj, oldObj) {
            if (newObj) {
                this.getContentList();
            }
        },
    },
    data() {
        return {
            safelyList: [
                {
                    avatar: "icontuandui",
                    unit: "人",
                    num: 0,
                    title: "总人口",
                    type: "person",
                },
                {
                    avatar: "iconzhufang-mianxing",
                    unit: "户",
                    num: 0,
                    title: "住房数",
                    type: "house",
                },
                {
                    avatar: "iconqiye",
                    unit: "个",
                    num: 0,
                    title: "辖区企业",
                    type: "company",
                },
                {
                    avatar: "icondianpu",
                    unit: "个",
                    num: 0,
                    title: "九小场所",
                    type: "place",
                },
                {
                    avatar: "iconxinaixin",
                    unit: "个",
                    num: 0,
                    title: "公益机构",
                    type: "publicCompany",
                },
                {
                    avatar: "iconshexiangtou-01",
                    unit: "件",
                    num: 0,
                    title: "监控总数",
                    type: "CAMERA",
                },
            ],
            safelyIndex: -1,
        };
    },

    mounted() {
        if (this.pajkGetApi) {
            this.getContentList();
        }
        // this.safelyListFn();
    },
    methods: {
        ...mapMutations("cesiumInfo", ["changeCesiumMarkerList"]),
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        showFn(item, index) {

            let _this = this;

            let { winShow, winShowType, safelyIndex } = this;
            
            this.changeCesiumMarkerList([]);

            switch (item.type) {
                case "company":
                    winShow = true;
                    winShowType = "company_count";
                    break;
                case "place":
                    winShow = true;
                    winShowType = "nine_small_count";
                    break;
                case "publicCompany":
                    winShow = true;
                    winShowType = "welfare_count";
                    break;
                default:
                    if (index !== safelyIndex) {
                        this.showCesiumMarker(item);
                    }
                    break;
            }

            this.safelyIndex = index == safelyIndex ? -1 : index;

            if (winShow) {
                _this.changeWinShowAction(true);
                _this.changeWinShowTypeAction(winShowType);
            }
        },
        showCesiumMarker(item) {
            let _this = this;

            let iconObj = {
                src: "",
                width: 45,
                height: 45,
            };

            let list = [
                {
                    id: "company_01",
                    info: {
                        type: "company",
                    },
                    position: {
                        lon: 118.76717398585708,
                        lat: 32.10762875410027,
                        height: 55,
                    },
                    text: "",
                    icon: iconObj,
                },
                {
                    id: "company_02",
                    info: {
                        type: "company",
                    },
                    position: {
                        lon: 118.7675420893485,
                        lat: 32.10799468877504,
                        height: 55,
                    },
                    text: "",
                    icon: iconObj,
                },
            ];

            switch (item.type) {
                case "company":
                    iconObj.src = "/images/company.png";
                    this.changeCesiumMarkerList(list);
                    break;
                case "CAMERA":
                    iconObj.src = "/images/video.png";
                    this.$getApi(api.getDotList, {
                        type: item.type,
                        page: 1,
                        size: 1000,
                    }).then((res) => {
                        if (1 == res.code) {
                            _this.changeCesiumMarkerList(
                                res.data.dotList.map((item) => {
                                    return {
                                        id: item.id,
                                        info: {
                                            ...item,
                                        },
                                        position: {
                                            lon: item.lon,
                                            lat: item.lat,
                                            height: item.height,
                                        },
                                        text: "",
                                        icon: iconObj,
                                    };
                                })
                            );
                        }
                    });
                    break;
            }
        },
        getContentList() {
            let { pajkGetApi, pajkData } = this;
            this.safelyList[0].num = pajkGetApi
                ? pajkData.community_info_residents * 1
                : 0;
            this.safelyList[1].num = pajkGetApi
                ? pajkData.community_info_building * 1
                : 0;
            this.safelyList[2].num = pajkGetApi
                ? pajkData.company_count * 1
                : 0;
            this.safelyList[3].num = pajkGetApi
                ? pajkData.nine_small_count * 1
                : 0;
            this.safelyList[4].num = pajkGetApi
                ? pajkData.welfare_count * 1
                : 0;
            this.safelyList[5].num = pajkGetApi
                ? pajkData.safe_device_camera * 1
                : 0;

            // this.contentList[1].number = 154;
        },
    },
};
