
import * as Cesium from "cesium";
import api from "@/api/index";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Number from "@/components/number/index.vue";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    components: {
        Number,
        TitleText,
    },
    computed: {
        ...mapGetters("publicInfo", ["eChartsLoad"]),
        ...mapGetters("csgl", ["csglData", "csglGetApi"]),
    },
    watch: {
        csglGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;
            if (newData && eChartsLoad) {
                _this.cmpData = _this.csglData;
                setTimeout(() => {
                    _this.drawEChart();
                }, 500);
            }
        },

        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { csglGetApi } = this;

            if (csglGetApi && newStaue && newStaue != oldStaue) {
                setTimeout(() => {
                    _this.drawEChart();
                    // _this.drawLineRight();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("infoReduceChart_2")
                );
                eChart.dispose();
            }
        },
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },

    data() {
        return {
            numberStyle: {
                fontSize: 24,
                width: 16,
            },
            cmpData: {
                garbageCurDay: [],
            },
            dotIconList: [],
            garbageCurDayIndex: -1,
        };
    },
    mounted() {
        if (this.csglGetApi) {
            this.cmpData = this.csglData;
            this.drawEChart();
        }
    },
    methods: {
        ...mapMutations("cesiumInfo", ["changeCesiumMarkerList"]),
        iconFlyTo(item, index) {
            console.log(item);
            let { dotIconList, garbageCurDayIndex } = this;

            this.garbageCurDayIndex == index
                ? (this.garbageCurDayIndex = -1)
                : (this.garbageCurDayIndex = index);

            if (dotIconList.length > 0 && index > -1) {
                window.cesiumViewer.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(
                        item.lon,
                        item.lat,
                        80
                    ),
                });
            } else {
                this.showIcon().then((list) => {
                    console.log(list);
                    if (list.length > 0) {
                        window.cesiumViewer.camera.flyTo({
                            destination: Cesium.Cartesian3.fromDegrees(
                                list[index].position.lon,
                                list[index].position.lat,
                                80
                            ),
                        });
                    }
                });
            }
        },
        showIcon(item) {
            let _this = this;
            this.$emit("strikeRefsWithAreaCameraFly");
            let { dotIconList } = this;
            return new Promise((resolve, reject) => {
                _this
                    .$getApi(api.getDotList, {
                        type: "GARBAGE_STATION",
                        page: 1,
                        size: 1000,
                    })
                    .then((res) => {
                        if (1 == res.code) {
                            let iconList = res.data.dotList.map((item) => {
                                return {
                                    id: item.id,
                                    info: item,
                                    position: {
                                        lon: item.lon,
                                        lat: item.lat,
                                        height: item.height,
                                    },
                                    text: "",
                                    icon: {
                                        src: "https://nb-test.oss-cn-beijing.aliyuncs.com/image/85549a75-878d-4df7-9ee3-afeb0361397d.png",
                                        width: 45,
                                        height: 45,
                                    },
                                };
                            });
                            _this.dotIconList = iconList;
                            _this.changeCesiumMarkerList(iconList);
                            resolve(iconList);
                        }
                    });
            });
        },
        drawEChart() {
            let _this = this;
            let { cmpData } = this;
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("infoReduceChart_2")
            );
            // 绘制图表
            myChart.setOption(
                {
                    grid: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: "100%",
                        height: "100%",
                    },
                    series: [
                        {
                            type: "gauge",
                            center: ["50%", "50%"],
                            radius: "100%",
                            startAngle: 90,
                            endAngle: 450,
                            max: 1,
                            pointer: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 15,
                                    color: [
                                        [
                                            1,
                                            new _this.$echarts.graphic.LinearGradient(
                                                0,
                                                0,
                                                1,
                                                0,
                                                [
                                                    {
                                                        offset: 0,
                                                        color: "rgba(31, 144, 225, 1)",
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: "rgba(42, 243, 255, 0)",
                                                    },
                                                ]
                                            ),
                                        ],
                                    ],
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: false,
                            },
                            anchor: {
                                show: false,
                            },
                            title: {
                                show: false,
                            },
                            detail: {
                                show: true,
                                valueAnimation: true,
                                fontSize: 36,
                                fontFamily: "DigitalReadoutThickUpright",
                                color: "#FFF",
                                offsetCenter: [0, 0],
                            },
                            data: [
                                {
                                    value: cmpData.garbageCurDay.length * 1,
                                },
                            ],
                        },
                    ],
                },
                true
            );
        },
    },
};
