
import { mapGetters } from "vuex";
import Number from "@/components/number/index";
import TitleText from "@/components/titleText.vue";

export default {
    name: "pointRanking",
    components: {
        Number,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
    },
    computed: {
        ...mapGetters("csgl", ["csglData", "csglGetApi"]),
    },
    watch: {
        csglGetApi(newData, oldData) {
            if (newData && !oldData) {
                this.pageData = this.csglData;
                // this.volunteerList = this.csglData.volunteer_user_point
            }
        },
    },
    data() {
        return {
            numberStyle: {
                fontSize: 10,
                color: "#1877F5",
                // width: 10,
                // letterSpacing:0,
                height: 20,
            },
            pageData: {},
        };
    },

    mounted() {
        this.drawEchart();
    },
    methods: {
        drawEchart() {
            let _this = this;
            let { gfzzData } = this;
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("classificalRank")
            );

            myChart.setOption({
                legend: {
                    orient: "vertical",
                    right: "5%",
                    bottom: "5%",
                    width: "80px",
                    height: "100%",
                    textStyle: {
                        color: "#fff",
                    },
                    data: ["金域中央", "星河翠庭"],
                },
                tooltip: {
                    trigger: "item",
                    textStyle: {
                        align: "left",
                    },
                    // formatter: (params) => {
                    //     console.log(params);
                    // },
                },
                polar: {
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.25)",
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.25)",
                        },
                    },
                    splitArea: {
                        show: false,
                    },
                    indicator: [
                        { name: "点位数(个)", max: 5, color: "#FFF" },
                        { name: "应收量", max: 1, color: "#FFF" },
                        { name: "当月总实收量", max: 20, color: "#FFF" },
                        { name: "平均分出量", max: 1, color: "#FFF" },
                        { name: "平均均完成率(%)", max: 100, color: "#FFF" },
                    ],
                    center: ["30%", "50%"],
                },
                series: [
                    {
                        type: "radar",
                        areaStyle: {
                            color: "rgba(0,0,0,0)",
                            opacity: 0,
                        },
                        data: [
                            {
                                areaStyle: {
                                    color: "rgba(0,0,0,0)",
                                    opacity: 0,
                                },
                                value: [3, 0.72, 18.13, 0.58, 80.88],
                                name: "金域中央",
                            },
                            {
                                areaStyle: {
                                    color: "rgba(0,0,0,0)",
                                    opacity: 0,
                                },
                                value: [1, 0.47, 12.58, 0.41, 86.41],
                                name: "星河翠庭",
                            },
                        ],
                    },
                ],
            });
        },
    },
};
