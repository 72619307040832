
import { mapGetters, mapMutations } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import api from "@/api/index";
import Number from "@/components/number/index.vue";
import TitleText from "@/components/titleText.vue";
import * as Cesium from "cesium";

export default {
  name: "componentDemo",
  components: {
    Number,
    TitleText,
    Swiper,
    SwiperSlide,
  },
  props: {
    title: {
      type: String,
      default: () => {
        return "组件标题";
      },
    },
    slideType: {
      type: String,
      default: () => "left",
    },
  },
  computed: {
    ...mapGetters("msbz", ["msbzData", "msbzGetApi"]),
    ...mapGetters("cesiumInfo", ["primitiveColorIdArr"]),
    swiperList() {
      let _this = this;

      return {
        facilitySwiper: _this.$refs.facilitySwiper.$swiper,
      };
    },
    facilitySwiperOption() {
      let _this = this;
      return {
        autoplay: {
          disableOnInteraction: false,
          delay: 4000,
        },
        direction: "vertical",
        observer: true,
        slidesPerView: 2,
        on: {
          init: function (swiper) {
            //Swiper初始化了
            setTimeout(() => {
              let { swiperList } = _this;
              swiperList.facilitySwiper.$el[0].onmouseenter = function () {
                swiperList.facilitySwiper.autoplay.stop();
              };
              swiperList.facilitySwiper.$el[0].onmouseleave = function () {
                swiperList.facilitySwiper.autoplay.start();
              };
            }, 1000);
          },
        },
      };
    },
  },
  watch: {
    msbzGetApi(newData, oldData) {
      if (newData) {
        this.getNewFacilityList();
      }
    },
  },
  data() {
    return {
      bgColorIndex: -1,
      facilityList: [],
      chooseIconIndex: "",
      numberStyle: {
        fontSize: 24,
        width: 16,
      },
    };
  },
  mounted() {
    if (this.msbzGetApi) {
      this.getNewFacilityList();
    }
  },

  methods: {
    ...mapMutations(["changePrimitiveColorIdArr"]),
    changeBg(index) {
      let _this = this;
      _this.bgColorIndex = index;
    },
    getNewFacilityList() {
      let _this = this;
      let { msbzData, facilityList } = this;
      let num = 4;
      msbzData.livelihood.forEach((item, index) => {
        if (index % num == 0) {
          _this.facilityList.push(
            msbzData.livelihood.slice(index, index + num)
          );
        }
      });
    },
    changeMonomerColor(tagType, rowIndex, colIndex) {
      let _this = this;
      let { chooseIconIndex } = this;

      let color = [0.1, 0.46, 0.96, 0.5];

      let nowIndex = `${rowIndex}_${colIndex}`;

      this.chooseIconIndex = chooseIconIndex == nowIndex ? "" : nowIndex;

      // if (this.primitiveColorIdArr.length > 0) {
      //   this.clearPrimitiveColor().then((res) => {
      //     _this.getResidentMonomerList(tagType).then((idArr) => {
      //       if (idArr.length > 0) {
      //         idArr.forEach((item) => {
      //           window.primitiveLayer.changePrimitiveColor(item, color);
      //         });
      //       }
      //     });
      //   });
      // } else {
      //   _this.getResidentMonomerList(tagType).then((idArr) => {
      //     if (idArr.length > 0) {
      //       idArr.forEach((item) => {
      //         window.primitiveLayer.changePrimitiveColor(item, color);
      //       });
      //     }
      //   });
      // }

      //隐藏其他所有的模型
      window.cesiumViewer.entities.removeAll();
      _this.getResidentMonomerList(tagType).then((idArr) => {
        if (idArr.length > 0) {
          idArr.forEach((item) => {
            item.coordinates = JSON.parse(item.coordinates);
            item.build_coordinates = JSON.parse(item.buildCoordinates);
            item.id = item.monomerId;
            window.primitiveLayer.addEntity(item);
            let centerPoint = window.primitiveLayer.getCenterpoint(item);
            item.centerLat = centerPoint.latitude;
            item.centerLon = centerPoint.longitude;
            //console.log(item);
            //先画线
            let linePositions = [];
            linePositions.push(new Cesium.Cartesian3.fromDegrees(item.centerLon, item.centerLat, item.maxHeight));
            linePositions.push(new Cesium.Cartesian3.fromDegrees(item.centerLon, item.centerLat,  item.maxHeight + 20));
            window.cesiumViewer.entities.add({
              polyline: {
                positions: linePositions,
                width: 2,
                material: Cesium.Color.fromCssColorString('#ff0000'),//纯色
                // material:new Cesium.ColorMaterialProperty(new Cesium.CallbackProperty(function () {
                //   if(flog){
                //     x=x-0.05;
                //     if(x<=0){
                //       flog=false;
                //     }
                //   }else{
                //     x=x+0.05;
                //     if(x>=1){
                //       flog=true;
                //     }
                //   }
                //   return Cesium.Color.RED.withAlpha(x);
                // },false))
              }
            });
            //打图标
            window.primitiveLayer.createBillboard({
              id: item.buildingId,
              build_id: item.buildingId,
              lon: item.centerLon,
              lat: item.centerLat,
              info: tagType,
              tagtype:tagType,
              type: "crowd", //人群
              name:'',
              height: item.maxHeight + 20,
              text: "",
              font: "500 20px Microsoft YaHei",
              color: "#ffffff",
              show_bg: true,
              bgColor: "#2492FF",
              offset_x: 20,
              offset_y: -50,
              image: "/images/crowd/crowd_" + tagType + ".png",
              img_width: 50,
              img_height: 50,
            });


          });
        }
      });
    },

    getResidentMonomerList(tagType) {
      let _this = this;

      return new Promise((resolve, reject) => {
        _this
          .$getApi(api.getResidentMonomerList, {
            tagType: tagType,
          })
          .then((res) => {
            if (res.code === "1") {
              let Arr = [];
              res.data.monomerList.map((item) => {
                if (item.areaId) {
                  Arr.push(item);
                }
              });

              resolve(Arr);
            }
          });
      });
    },
    clearPrimitiveColor() {
      let _this = this;

      return new Promise((resolve, reject) => {
        _this.primitiveColorIdArr.forEach((item, index) => {
          let nowPrimitive = window.primitiveLayer.getPrimitiveById(item);
          let attributes =
            nowPrimitive._primitive.getGeometryInstanceAttributes(
              nowPrimitive._primitiveOptions.geometryInstances[0].id
            ); //获取某个实例的属性集
          window.primitiveLayer.changePrimitiveColor(item, [0, 0, 0, 0]);

          if (index == _this.primitiveColorIdArr.length - 1) {
            _this.changePrimitiveColorIdArr();
            resolve(_this.primitiveColorIdArr);
          }
        });
      });
    },
  },
};
