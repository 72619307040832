
import { mapGetters, mapActions } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    components: {
        TitleText,
    },
    computed: {
        ...mapGetters("hldj", ["hldjData", "hldjGetApi"]),
    },
    watch: {
        hldjGetApi(newData, oldData) {
            if (newData) {
                this.getCmpData();
            }
        },
    },
    data() {
        return {
            cmpData: {},
            partyLessonRate: 0,
            partyLessonList: [],
        };
    },
    mounted() {
        if (this.hldjGetApi) {
            this.getCmpData();
        }
    },
    methods: {
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        getCmpData() {
            let { hldjData } = this;
            this.partyLessonRate = hldjData.party_lesson_rate;
            this.partyLessonList = hldjData.partyLessionList;
            this.drawLine();
        },
        showFn() {
            let _this = this;
            _this.changeWinShowAction(true);
            _this.changeWinShowTypeAction("goods_point");
        },
        drawLine() {
            let { partyLessonList } = this;
            let xData = [];
            let yData = [];
            partyLessonList.map((item) => {
                xData.push(item.key);
                yData.push(item.value);
            });
            // 基于准备好的dom，初始化echarts实例
            let chart = this.$echarts.init(
                document.getElementById("lessonsystem")
            );
            // 绘制图表
            var option = {
                grid: {
                    top: "5%",
                    left: "3%",
                    right: "3%",
                    bottom: "5%",
                    width: "auto",
                    containLabel: true,
                },
                xAxis: {
                    data: xData,
                    axisTick: {
                        //x轴刻度线
                        show: false,
                    },
                    splitLine: {
                        show: false, //隐藏或显示
                    },
                    axisLabel: {
                        textStyle: {
                            color: "rgba(255, 255, 255, 1)",
                        },
                        clickable: true,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(255, 255, 255, 0.5)",
                        },
                    },
                },
                yAxis: {
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(255, 255, 255, 0.5)",
                        },
                    },
                    type: "value",
                    axisLabel: {
                        textStyle: {
                            color: "rgba(255, 255, 255, 1)",
                        },
                    },
                    splitLine: {
                        show: false, //隐藏或显示
                    },
                },
                series: [
                    {
                        // name: '销量',
                        type: "bar",
                        barWidth: 15,
                        itemStyle: {
                            normal: {
                                color: this.$echarts.graphic.LinearGradient(
                                    0,
                                    1,
                                    0,
                                    0,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(216, 0, 0, 0)", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(255, 235, 0, 1)", // 100% 处的颜色
                                        },
                                    ],
                                    false
                                ),
                            },
                        },
                        data: yData,
                    },
                ],
            };
            chart.setOption(option);
            chart.getZr().on("click", (params) => {
                console.log(params);
            });
        },
    },
};
