
import { mapState, mapActions } from "vuex";
import PersonTaskDia from "../point/taskDia.vue";
import ExpertDetail from "../communitySpecialist/detail.vue";
export default {
    name: "windialog",
    computed: {
        ...mapState(["winShow", "slideShowType","expertShow"]),
    },

    components: {
        PersonTaskDia: PersonTaskDia,
        ExpertDetail: ExpertDetail,
    },
    watch: {
        winShow(newStart, oldStart) {
            console.log(newStart);
        },
        expertShow(newStart, oldStart) {
            console.log(newStart);
        },
    },

    data() {
        return {};
    },

    mounted() {
        //     this.node = document.querySelector('.modal-container')
        // this.winWidth = window.screen.width * 0.4;
        // this.winHeight = window.screen.height * 0.4;
        //    this.top= (document.body.clientHeight - element.offsetHeight)/2;
        //    this.left=  (document.body.clientWidth - element.offsetWidth)/2
    },
    methods: {
        ...mapActions(["changeWinShowAction","changeExpertShowAction"]),
        hideModal() {
            let _this = this;
            _this.changeWinShowAction(false);
            _this.changeExpertShowAction(false);
        },
    },
};
