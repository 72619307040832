
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Modal from "@/components/publicDillog/index.vue";
import TitleText from "@/components/titleText.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        Modal,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {
            show: false,
            left: "",
            top: "",
            leftstatu: 0,
            topstatu: 0,
            leftlineheight: 0,
            toplineheight: 0,
            lineleft: "",
            linetop: "",
            toplineleft: "",
            toplinetop: "",
            diaValue: "",
            diaContent: "",

            posIndex: -1,
            activityList: [],
        };
    },
    computed: {
        ...mapGetters("config", ["configInfo"]),
        ...mapGetters("pajk", ["pajkData", "pajkGetApi"]),
        swiperOptionsList() {
            let _this = this;

            return {
                volunteer: {
                    autoplay: {
                        disableOnInteraction: false,
                        delay: 2000,
                    }, //可选选项，自动滑动
                    slidesPerView: 3.5,
                    // width: 5 * fontBaseSize * scaleNum,
                    // height: ((windowHeight / 3) - (1.5 * fontBaseSize))
                    observer: true,
                },
            };
        },
    },
    watch: {
        pajkGetApi(newObj, oldObj) {
            let _this = this;
            if (newObj) {
                _this.changeList();
            }
        },
    },
    mounted() {
        if (this.pajkGetApi) {
            this.changeList();
        }
    },
    methods: {
        changeList() {
            this.activityList = this.pajkData.activityList;
        },
        changeStyle(item, index, e) {
            let { posIndex } = this;

            this.posIndex = index == posIndex ? -1 : index;
            this.diaContent = item.key;
            this.diaValue = item.value;
            this.show = true;
            this.leftstatu = 45;
            this.topstatu = -45;
            this.leftlineheight = 280;
            this.toplineheight = 270;
            this.left = e.pageX - 330 + "px";
            this.top = e.pageY + "px";
            this.lineleft = e.pageX - 106 + "px";
            this.linetop = e.pageY - 42 + "px";
            this.toplineleft = e.pageX - 106 + "px";
            this.toplinetop = e.pageY - 234 + "px";
        },
        hideModal() {
            this.show = false;
        },
    },
};
