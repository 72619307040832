
import { mapGetters, mapActions, mapMutations } from "vuex";
import api from "@/api/index";

export default {
    name: "bottomRoom",
    computed: {
        ...mapGetters("publicInfo", [
            "slideTypeList",
            "slideShowType",
            "slideTypeIndex",
            "slideAnimationTime",
        ]),
        ...mapGetters("cesiumInfo", ["primitiveColorIdArr"]),
    },
    data() {
        return {
            bottomRoomShow: false,
            bottomRoomHide: true,
        };
    },
    watch: {
        slideShowType(newType, oldType) {
            this.bottomRoomShow = newType != "tool";
            this.bottomRoomHide = false;
        },
        slideTypeIndex(newIndex, oldIndex) {
            let { slideTypeList } = this;
            if (newIndex > -1) {
                this.changeSlideTypeAction(slideTypeList[newIndex].type);
            }
        },
    },
    mounted() {
        let _this = this;
        let { slideAnimationTime } = this;
        setTimeout(() => {
            _this.bottomRoomShow = true;
            _this.bottomRoomHide = false;
        }, slideAnimationTime);
    },
    methods: {
        ...mapMutations("publicInfo", ["changeSlideTypeIndex"]),
        ...mapMutations("cesiumInfo", ["changePrimitiveColorIdArr"]),
        ...mapActions("publicInfo", ["changeSlideTypeAction"]),
        changeNavIndex(index) {
            let _this = this;
            let { slideTypeList } = this;

            this.changeSlideTypeAction(slideTypeList[index].type);
            this.changeSlideTypeIndex(index);

            switch (slideTypeList[index].type) {
                case "party":
                    this.showPartyManPrimitive();
                    break;
                default:
                    this.clearPrimitiveColor();
                    break;
            }
        },
        showPartyManPrimitive() {
            let _this = this;
            let { primitiveColorIdArr } = this;
            let color = [];

            this.$getApi(api.getResidentMonomerList, {
                tagType: 6,
                page: 1,
                size: 1000,
            }).then((res) => {
                if (1 == res.code) {
                    let idArr = [];
                    res.data.monomerList.forEach((item) => {
                        if (item.areaId) {
                            idArr.push(item.areaId);
                        }
                    });

                    if (primitiveColorIdArr.length > 0) {
                        color = [0, 0, 0, 0];
                        this.clearPrimitiveColor().then((res) => {
                            idArr.forEach((item) => {
                                window.primitiveLayer.changePrimitiveColor(
                                    item,
                                    color
                                );
                            });
                        });
                    } else {
                        color = [1, 0.1, 0.1, 0.5];
                        idArr.forEach((item) => {
                            window.primitiveLayer.changePrimitiveColor(
                                item,
                                color
                            );
                        });
                    }
                }
            });
        },
        clearPrimitiveColor() {
            let _this = this;
            let { primitiveColorIdArr } = this;
            console.log(primitiveColorIdArr);
            return new Promise((resolve, reject) => {
                primitiveColorIdArr.forEach((item, index) => {
                    let nowPrimitive =
                        window.primitiveLayer.getPrimitiveById(item);
                    let attributes =
                        nowPrimitive._primitive.getGeometryInstanceAttributes(
                            nowPrimitive._primitiveOptions.geometryInstances[0]
                                .id
                        ); //获取某个实例的属性集
                    window.primitiveLayer.changePrimitiveColor(
                        item,
                        [0, 0, 0, 0]
                    );

                    if (index == primitiveColorIdArr.length - 1) {
                        _this.changePrimitiveColorIdArr();
                        resolve(primitiveColorIdArr);
                    }
                });
            });
        },
    },
};
