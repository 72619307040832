
import { mapGetters } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    computed: {
        ...mapGetters("publicInfo", ["eChartsLoad"]),
        ...mapGetters("street", ["streetGetApi", "streetData"]),
    },
    components: {
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    watch: {
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { streetGetApi } = this;

            if (streetGetApi && newStaue) {
                setTimeout(() => {
                    _this.drawEchart();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("personTask")
                );

                eChart.dispose();
            }
        },
        streetGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;

            if (eChartsLoad && newData) {
                setTimeout(() => {
                    _this.drawEchart();
                    // _this.drawEchartRight();
                }, 500);
            }
        },
    },
    data() {
        return {};
    },
    mounted() {
        if (this.streetGetApi) {
            this.drawEchart();
        }
    },
    methods: {
        drawEchart() {
            // 基于准备好的dom，初始化echarts实例
            let { streetData } = this;
            let yData = streetData.seven_day_task;
            let xData = streetData.seven_day;

            let myChart = this.$echarts.init(
                document.getElementById("personTask")
            );
            // 绘制图表
            myChart.setOption({
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "line",
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.5)",
                        },
                    },
                },
                legend: {
                    show: true,
                    right: 20,
                    top: 20,
                    data: [
                        {
                            name: "时间",
                            icon: "rect",
                        },
                    ],
                },
                grid: {
                    top: "5%",
                    bottom: "20%",
                    left: "5%",
                    right: "5%",
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: xData,
                    axisLabel: {
                        textStyle: {
                            color: "rgba(255, 255, 255, 0.8)",
                            fontSize: 11,
                            width: "40px",
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.5)",
                            type: "dashed",
                            dashOffset: 0,
                        },
                    },
                    axisTick: {
                        show: true,
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.5)",
                            type: "dashed",
                            dashOffset: 0,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "rgba(255, 255, 255, 0.8)",
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: ["rgba(255,255,255,0.1)"],
                            type: "dashed",
                            dashOffset: 0,
                        },
                    },
                    minInterval: 1,
                },
                series: [
                    {
                        data: yData,
                        type: "line",
                        showSymbol: false,
                        lineStyle: {
                            color: "#2AF3FF",
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(42, 243, 255, 0.4)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(42, 243, 255,0)",
                                    },
                                ]
                            ),
                        },
                        smooth: true,
                    },
                ],
            });
        },
    },
};
