
import { mapGetters, mapActions } from "vuex";
import TitleText from "@/components/titleText.vue";
import Number from "@/components/number/index.vue";

export default {
    name: "componentDemo",
    components: {
        TitleText,
        Number,
    },
    computed: {
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
    },
    watch: {},
    data() {
        return {
            show: false,
            numberStyle: {
                fontSize: 50,
                height: 50,
                width: 30,
                fontFamily: "DigitalReadoutThickUpright",
                color: "#FFFFFF",
            },
        };
    },
    mounted() {
    },
    methods: {
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        showWinDialogFn(type) {
            let _this = this;
            _this.changeWinShowTypeAction(type);
            _this.changeWinShowAction(true);
        },
        showWin() {
            let _this = this;
            _this.showWinDialogFn("taskType_1");
        },
        hideModal() {
            this.show = false;
        },
    },
};
