
import { mapState } from "vuex";
export default {
  props: {
    number: {
      type: Number,
      default: () => 0,
    },
    /**
     * numStyle格式说明:
     *
     * 数字的高度优先级: height > fontSize
     * 所有以 px 为单位的属性只需要填写数字
     *
     */
    numStyle: {
      type: Object,
      default: () => {
        return {
          fontSize: 14,
          color: "#fff",
        };
      },
    },
    numSplit: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    ...mapState(["slideDomCssTransitionStart"]),
  },
  data() {
    return {
      numToStrList: [],
    };
  },
  watch: {
    slideDomCssTransitionStart(newState, oldState) {
      let _this = this;
      if (newState) {
        _this.changeNumber();
      }
    },
    number(newNum, oldNum) {
      if (newNum != oldNum) {
        this.changeNumber();
      }
    },
  },
  mounted() {
    let _this = this;

    this.changeNumber();
  },
  methods: {
    changeNumber() {
      let { number } = this;
      let _this = this;
      this.numToStrList =
        "number" == typeof number
          ? number.toString().split("")
          : number.split("");
    },
  },
};
