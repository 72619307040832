
import { mapGetters, mapActions } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    components: {
        TitleText,
    },
    computed: {
        ...mapGetters("publicInfo", ["eChartsLoad"]),
        ...mapGetters("pajk", ["pajkData", "pajkGetApi"]),
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    watch: {
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { pajkGetApi } = this;

            let eChart = this.$echarts.init(
                document.getElementById("safeReport")
            );

            if (pajkGetApi && newStaue) {
                _this.drawEchart();
            } else {
                eChart.dispose();
            }
        },
        pajkGetApi(newObj, oldObj) {
            let _this = this;
            let { eChartsLoad } = this;
            if (eChartsLoad && newObj) {
                this.cmpData = this.pajkData;
                setTimeout(() => {
                    _this.drawEchart();
                }, 500);
            }
        },
    },
    data() {
        return {
            cmpData: {},
        };
    },
    mounted() {
        let _this = this;
        if (this.pajkGetApi) {
            this.cmpData = this.pajkData;

            setTimeout(() => {
                _this.drawEchart();
            }, 500);
        }
    },
    methods: {
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        showFn() {
            let _this = this;
            _this.changeWinShowAction(true);
            _this.changeWinShowTypeAction("safe_report");
        },
        drawEchart() {
            // 基于准备好的dom，初始化echarts实例
            let { pajkData } = this;
            let xData = [];
            let yData = [];
            pajkData.safe_report.map((item) => {
                xData.push(item.month);
                yData.push(item.ct);
            });
            let myChart = this.$echarts.init(
                document.getElementById("safeReport")
            );
            // 绘制图表
            myChart.setOption({
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "line",
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.5)",
                        },
                    },
                },
                legend: {
                    show: true,
                    right: 20,
                    top: 5,
                    textStyle: {
                        color: "#FFF",
                    },
                    itemStyle: {
                        color: "inherit",
                    },
                    data: ["次数"],
                },
                grid: {
                    top: "10%",
                    bottom: "10%",
                    left: "5%",
                    right: "5%",
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: xData,
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.6)",
                        },
                    },
                    axisTick: {
                        show: true,
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.6)",
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    minInterval: 1,
                    splitLine: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: "次数",
                        data: yData,
                        type: "line",
                        showSymbol: false,
                        lineStyle: {
                            color: "#2AF3FF",
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(42, 243, 255, 0.2)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(42, 243, 255, 0)",
                                    },
                                ]
                            ),
                        },
                        smooth: true,
                    },
                ],
            });

            myChart.on("click", (params) => {
                console.log(params);
            });
        },
    },
};
