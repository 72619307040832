
import { mapGetters } from "vuex";
import TitleText from "@/components/titleText.vue";
export default {
    name: "componentDemo",
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    components: {
        TitleText,
    },
    data() {
        return {
            statsList: [
                {
                    content: "未使用功分",
                    percent: "34.12%",
                    point: 4544,
                },
                {
                    content: "未使用功分",
                    percent: "34.12%",
                    point: 4544,
                },
                {
                    content: "未使用功分",
                    percent: "34.12%",
                    point: 4544,
                },
                {
                    content: "未使用功分",
                    percent: "34.12%",
                    point: 4544,
                },
                {
                    content: "未使用功分",
                    percent: "34.12%",
                    point: 4544,
                },
                {
                    content: "未使用功分",
                    percent: "34.12%",
                    point: 4544,
                },
            ],
            cmpData: {},
        };
    },
    mounted() {
        let _this = this;
        if (this.gfzzGetApi && this.eChartsLoad) {
            this.cmpData = this.gfzzData;
            setTimeout(() => {
                _this.drawLineLeft();
                _this.drawLineRight();
            }, 500);
        }
    },
    computed: {
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
        ...mapGetters("publicInfo", ["eChartsLoad"]),
    },
    watch: {
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { gfzzGetApi } = this;

            if (gfzzGetApi && newStaue) {
                this.cmpData = this.gfzzData;
                setTimeout(() => {
                    _this.drawLineLeft();
                    _this.drawLineRight();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("pointStatsLeft")
                );
                let eChartBg = this.$echarts.init(
                    document.getElementById("pointStatsLeftBg")
                );
                let eChart1 = this.$echarts.init(
                    document.getElementById("pointStatsRight")
                );
                eChart.dispose();
                eChart1.dispose();
                eChartBg.dispose();
            }
        },
        gfzzGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;

            if (newData && eChartsLoad) {
                this.cmpData = this.gfzzData;
                setTimeout(() => {
                    _this.drawLineLeft();
                    _this.drawLineRight();
                }, 500);
            }
        },
    },
    methods: {
        drawLineLeft() {
            let { gfzzGetApi, cmpData } = this;
            let pointStatsLeft = this.$echarts.init(
                document.getElementById("pointStatsLeft")
            );
            // 绘制图表
            pointStatsLeft.setOption({
                legend: {
                    bottom: "5%",
                    width: "100%",
                    left: "center",
                    textStyle: {
                        color: "#fff",
                        fontSize: "12",
                    },
                },
                color: ["#42A6AF", "#1877F5"],
                // tooltip: {
                //     // show: true,
                //     trigger: "item",
                //     // formatter: "{b}: {c}人",
                // },
                series: [
                    {
                        type: "pie",
                        center: ["50%", "35%"],
                        radius: ["50%", "60%"],
                        selectedMode: "single",
                        avoidLabelOverlap: false,
                        itemStyle: {},
                        label: {
                            // position: "outer",
                            show: false,
                            position: "center",
                            // alignTo: "center",
                            formatter: "{b}:\n{c}",
                            color: "#fff",
                            // textBorderColor: "rgba(0,0,0,0)",
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(255, 255, 255, 0.3)",
                            },
                            label: {
                                show: true,
                                fontSize: "16",
                                fontWeight: "bold",
                                textBorderColor: "rgba(0,0,0,0)",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: cmpData.point_info_used * 1 || 0,
                                name: "已兑换功分",
                                itemStyle: {
                                    color: "#1877F5",
                                },
                            },
                            {
                                value: cmpData.point_info_not_exchange * 1 || 0,
                                name: "未兑换功分",
                                itemStyle: {
                                    color: "#2061D3",
                                },
                            },
                            {
                                value: cmpData.point_info_unused * 1 || 0,
                                name: "未使用功分",
                                itemStyle: {
                                    color: "#46CADC",
                                },
                            },
                            {
                                value: cmpData.point_info_recovery * 1 || 0,
                                name: "已回收功分",
                                itemStyle: {
                                    color: "#42A6AF",
                                },
                            },
                        ],
                    },
                ],
            });

            let echartBg = this.$echarts.init(
                document.getElementById("pointStatsLeftBg")
            );

            echartBg.setOption({
                angleAxis: {
                    startAngle: 360,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    minorTick: {
                        show: false,
                    },
                    min: 0,
                    max: 1,
                },
                grid: {
                    left: 10,
                    top: 10,
                    right: 0,
                    bottom: 0,
                },
                radiusAxis: {
                    type: "category",
                    data: [""],
                    axisLabel: {
                        //    interval:0
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                },
                polar: {
                    center: ["50%", "35%"],
                    radius: ["64%", "70%"],
                },
                series: [
                    {
                        type: "bar",
                        data: [1],
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                1,
                                0,
                                0,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(66, 166, 175, 0.5)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(24, 119, 245, 0)",
                                    },
                                ]
                            ),
                        },
                        label: {
                            fontSize: 10,
                        },
                        barWidth: 8,
                        coordinateSystem: "polar",
                        // emphasis: {
                        //     focus: 'series'
                        // }
                    },
                ],
            });
        },
        drawLineRight() {
            let _this = this;
            // 基于准备好的dom，初始化echarts实例
            let { cmpData } = this;
            let pointStatsRight = this.$echarts.init(
                document.getElementById("pointStatsRight")
            );
            // 绘制图表
            pointStatsRight.setOption({
                tooltip: {
                    trigger: "item",
                },
                backgroundColor: "rgba(0,0,0,0)",
                radar: [
                    {
                        indicator: [
                            { text: "知民" },
                            { text: "为民" },
                            { text: "安民" },
                            { text: "乐民" },
                            { text: "亲民" },
                        ],

                        startAngle: 90,
                        splitNumber: 3,
                        name: {
                            formatter: "{value}",
                            textStyle: {
                                color: "#fff",
                            },
                        },
                        center: ["50%", "50%"], // 位置
                        radius: 70, //大小
                        splitArea: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#1877F5",
                                type: "dashed",
                                width: 0.5,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: "#1877F5",
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "雷达图",
                        symbol: "none",
                        lineStyle: {
                            show: false,
                            color: "#83C75F",
                        },
                        itemStyle: {
                            color: "#83C75F",
                        },
                        areaStyle: {
                            color: new _this.$echarts.graphic.RadialGradient(
                                0.1,
                                0.6,
                                1,
                                [
                                    {
                                        color: "rgba(131, 199, 95, 0.6)",
                                        offset: 0,
                                    },
                                    {
                                        color: "rgba(66, 166, 175, 0.4)",
                                        offset: 1,
                                    },
                                ]
                            ),
                        },
                        type: "radar",
                        data: [
                            {
                                // value: [
                                //     cmpData.point_dimension[0].value * 1 || 0,
                                //     cmpData.point_dimension[1].value * 1 || 0,
                                //     cmpData.point_dimension[2].value * 1 || 0,
                                //     cmpData.point_dimension[3].value * 1 || 0,
                                //     cmpData.point_dimension[4].value * 1 || 0,
                                // ],
                                value: cmpData.point_dimension.map((item) => {
                                    return item.value * 1 || 0;
                                }),
                                name: "五维功分",
                            },
                        ],
                    },
                ],
            });
        },
    },
};
