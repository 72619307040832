
import { mapGetters, mapActions, mapMutations } from "vuex";
import api from "@/api/index";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    components: {
        TitleText,
    },
    computed: {
        ...mapGetters("csgl", ["csglData", "csglGetApi"]),
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    watch: {
        csglGetApi(newData, oldData) {
            if (newData) {
                this.drawEChart();
            }
        },
    },
    data() {
        return {
            show: false,
            cmpData: {
                charging_pile_count: 376,
            },
        };
    },
    mounted() {
        if (this.csglGetApi) {
            this.drawEChart();
        }
    },
    methods: {
        ...mapMutations("cesiumInfo", ["changeCesiumMarkerList"]),
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        drawEChart() {
            let _this = this;
            let { cmpData } = this;
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("garbageClassificationChart")
            );
            // 绘制图表
            myChart.setOption(
                {
                    grid: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: "100%",
                        height: "100%",
                    },
                    series: [
                        {
                            type: "gauge",
                            center: ["50%", "50%"],
                            radius: "100%",
                            startAngle: 90,
                            endAngle: 450,
                            max: 1,
                            pointer: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 15,
                                    color: [
                                        [
                                            1,
                                            new _this.$echarts.graphic.LinearGradient(
                                                0,
                                                0,
                                                1,
                                                0,
                                                [
                                                    {
                                                        offset: 0,
                                                        color: "rgb(0, 255, 255)",
                                                    },
                                                    {
                                                        offset: 0.4,
                                                        color: "rgb(0, 255, 255)",
                                                    },
                                                    {
                                                        offset: 0.8,
                                                        color: "rgb(24, 119, 245)",
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: "rgb(66, 166, 175)",
                                                    },
                                                ]
                                            ),
                                        ],
                                    ],
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: false,
                            },
                            anchor: {
                                show: false,
                            },
                            title: {
                                show: false,
                            },
                            detail: {
                                show: true,
                                valueAnimation: true,
                                fontSize: 70,
                                fontFamily: "DigitalReadoutThickUpright",
                                color: "#FFF",
                                offsetCenter: [0, -20],
                            },
                            data: [
                                {
                                    value: cmpData.charging_pile_count,
                                },
                            ],
                        },
                    ],
                },
                true
            );
        },
        showWinDialogFn(type) {
            let _this = this;
            _this.changeWinShowTypeAction(type);
            _this.changeWinShowAction(true);
        },
        showWin() {
            let _this = this;
            _this.showWinDialogFn("taskType_1");
        },
        hideModal() {
            this.show = false;
        },
        showIcon(item) {
            let _this = this;
            this.$emit("strikeRefsWithAreaCameraFly");
            let { dotIconList } = this;
            this.$getApi(api.getDotList, {
                type: "CHARGING_PILE",
                page: 1,
                size: 1000,
            }).then((res) => {
                if (1 == res.code) {
                    _this.changeCesiumMarkerList(
                        res.data.dotList.map((item) => {
                            return {
                                id: item.id,
                                info: {
                                    type: "CHARGING_PILE",
                                    id: item.id,
                                },
                                position: {
                                    lon: item.lon,
                                    lat: item.lat,
                                    height: item.height,
                                },
                                text: "",
                                icon: {
                                    src: "https://nb-test.oss-cn-beijing.aliyuncs.com/image/8fc5607f-f487-4a0d-ba3f-fe7d0c5c35d0.png",
                                    width: 45,
                                    height: 45,
                                },
                            };
                        })
                    );
                }
            });
        },
    },
};
