
import { mapGetters, mapActions, mapMutations } from "vuex";
import Number from "@/components/number/index.vue";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    components: {
        Number,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    computed: {
        ...mapGetters("publicInfo", [
            "slideDomCssTransitionStart",
            "eChartsLoad",
        ]),
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
    },
    watch: {
        slideDomCssTransitionStart(newStart, oldStart) {
            let _this = this;
            if (newStart) {
                _this.getContentList();
            }
        },
        gfzzGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;

            if (newData && !oldData && eChartsLoad) {
                setTimeout(() => {
                    _this.drawChart();
                    _this.getContentList();
                }, 500);
            }
        },
        // eChartsLoad(newStaue, oldStaue) {
        //     let _this = this;

        //     let { gfzzGetApi } = this;

        //     if (gfzzGetApi && newStaue && newStaue != oldStaue) {
        //         setTimeout(() => {
        //             _this.drawChart();
        //         }, 500);
        //     } else {
        //         let eChart = this.$echarts.init(
        //             document.getElementById("peopleAppeal")
        //         );

        //         eChart.dispose();
        //     }
        // },
    },
    data() {
        return {
            titleList: [],
            numberStyle: {
                fontSize: 24,
                width: 16,
            },
            contentList: [],
            cmpData: {
                cas_rate: 0,
            },
        };
    },
    mounted() {
        let { gfzzData, gfzzGetApi } = this;
        if (gfzzGetApi) {
            this.cmpData = {
                cas_rate: gfzzData.cas_rate,
            };

            this.drawChart();
        }
    },
    methods: {
        ...mapMutations("cesiumInfo", ["changeCesiumMarkerList"]),
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        showCeisumMarker(item, index) {
            //   console.log(item);
            let _this = this;
            // if (index == 0) {
            //     this.showCesiumMarker()
            // }
            if (item.type == "已受理") {
                _this.changeWinShowAction(true);
                _this.changeWinShowTypeAction("cas_1");
            } else if (item.type == "办理中") {
                _this.changeWinShowAction(true);
                _this.changeWinShowTypeAction("cas_2");
            } else {
                _this.changeWinShowAction(true);
                _this.changeWinShowTypeAction("cas_3");
            }
        },
        showCesiumMarker() {
            let iconObj = {
                src: "/images/complain.png",
                width: 45,
                height: 45,
            };
            let list = [
                {
                    id: "complain_01",
                    info: {
                        type: "complain",
                    },
                    position: {
                        lon: 118.7679,
                        lat: 32.1079,
                        height: 50,
                    },
                    text: "",
                    icon: iconObj,
                },
                {
                    id: "complain_02",
                    info: {
                        type: "complain",
                    },
                    position: {
                        lon: 118.7671,
                        lat: 32.107,
                        height: 50,
                    },
                    text: "",
                    icon: iconObj,
                },
            ];

            this.changeCesiumMarkerList(list);
        },
        getContentList() {
            this.contentList = this.gfzzData.cas_count;
            console.log(this.gfzzData.cas_count);
        },
        drawChart() {
            let _this = this;
            let { gfzzData } = this;
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("peopleAppeal")
            );
            // 绘制图表
            myChart.setOption({
                legend: {
                    data: ["已受理", "已办结"],
                    right: 0,
                    textStyle: {
                        color: "#FFF"
                    }
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{b}: {c}个",
                },
                grid: {
                    left: "0%",
                    right: "4%",
                    bottom: "5%",
                    top: "10%",
                    width: "auto",
                    containLabel: true,
                },
                xAxis: {
                    data: gfzzData.cas_1.map((item) => item.key),
                    axisTick: {
                        //x轴刻度线
                        show: false,
                    },

                    splitLine: {
                        //网格线
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(255, 255, 255, 0.5)", //设置网格线类型 dotted：虚线   solid:实线
                        },
                        show: false, //隐藏或显示
                    },
                    axisLabel: {
                        interval: 0, //代表显示所有x轴标签显示
                        textStyle: {
                            color: "rgba(255, 255, 255, 0.8)",
                            fontSize: 12,
                            width: "40px",
                        },
                        formatter: (val) => {
                            let strArr = val.split("");
                            let str = "";

                            strArr.forEach((item, index) => {
                                str += item;
                                if (index % 3 == 0 && index > 0) {
                                    str += "\n";
                                }
                            });
                            return str;
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(255, 255, 255, 0.5)",
                        },
                    },
                },
                yAxis: {
                    // data: ["0", "200","400","600","800"],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(255, 255, 255, 0.5)",
                        },
                    },

                    type: "value",
                    // name : '%',
                    axisLabel: {
                        // formatter: '{value}',
                        textStyle: {
                            color: "rgba(255, 255, 255, 0.8)",
                        },
                    },

                    splitLine: {
                        //网格线
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(255, 255, 255, 0.5)", //设置网格线类型 dotted：虚线   solid:实线
                        },
                        show: false, //隐藏或显示
                    },
                },

                series: [
                    {
                        name: "已受理",
                        type: "bar",
                        stack: "total",
                        barWidth: 12,
                        barGap: "80%",
                        label: {
                            normal: {
                                show: false,
                                textStyle: {
                                    color: "#fff",
                                },
                            },
                        },
                        itemStyle: {
                            color: "#16FB89",
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: gfzzData.cas_1.map((item) => {
                            return item.value;
                        }),
                    },
                    {
                        name: "已办结",
                        type: "bar",
                        stack: "total",
                        label: {
                            normal: {
                                show: false,

                                textStyle: {
                                    color: "#fff",
                                },
                            },
                        },
                        itemStyle: {
                            color: "#2AF3FF",
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: gfzzData.cas_3.map((item) => {
                            return item.value;
                        }),
                    },
                ],
            });
        },
    },
};
