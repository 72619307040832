
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("publicInfo", [
      "loadingPageHide",
      "slideTypeIndex",
      "slideShowType",
    ]),
    ...mapState(["chooseMonomerType"]),
  },
  data() {
    return {
      showSearchRoom: false,
      searchValue: "",
    };
  },
  watch: {
    showSearchRoom(newType, oldType) {
      if (newType) {
        this.$refs.findPersonSearch.focus();
      }
    },
  },
  mounted() {},
  methods: {
    ...mapActions("publicInfo", ["changeSlideTypeAction"]),
    ...mapActions([
      "changeFindPersonValueAction",
      "changeChooseMonomerTypeAction",
    ]),
    chooseMonomerClickType(type) {
      this.changeChooseMonomerTypeAction(type);
    },
    searchBuild() {
      let _this = this;
      let { searchValue } = this;

      if (searchValue) {
        this.changeFindPersonValueAction(searchValue);
        this.changeSlideTypeAction("monomer_house_search");
      } else {
        console.log("请输入数据");
      }
    },
  },
};
