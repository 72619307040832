
import Organization from "../components/organization.vue";
import OrganizationTwo from "../components/organizationTwo.vue";
import PartyPointOne from "../components/partyPointOne.vue";
import PartyMen from "../components/partyMen.vue";
import Activities from "../components/activity.vue";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import api from "@/api/index.js";

export default {
    name: "slideRoom",
    components: {
        Swiper,
        SwiperSlide,
        Organization,
        OrganizationTwo,
        PartyPointOne,
        PartyMen,
        Activities,
    },
    props: {
        banners: {
            type: Array,
            default: () => [],
        },
    },
    directives: {
        swiper: directive,
    },
    computed: {
        ...mapGetters("publicInfo", [
            "slideShowType",
            "slideRoomShow",
            "slideTypeIndex",
            "slideAnimationTime",
        ]),
        ...mapGetters("hldj", ["hldjData", "hldjGetApi"]),
        swiperList() {
            let _this = this;
            let { slideType, slideShowType } = this;
            let swiperListTemp = {};
            switch (slideShowType) {
                case "party":
                    swiperListTemp = {
                        partySwiper1: _this.$refs.partySwiper1.$swiper,
                        partySwiper4: _this.$refs.partySwiper4.$swiper,
                    };
                    break;
            }

            return swiperListTemp;
        },
        swiperOptionsList() {
            let _this = this;
            let optionsList = {};
            let { slideShowType } = this;
            switch (slideShowType) {
                case "party":
                    optionsList = {
                        partySwiper1: {
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 2500,
                            },
                            observer: true,
                            direction: "horizontal",
                            on: {
                                init: function (swiper) {
                                    //Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.partySwiper1.$el[0].onmouseenter =
                                            function () {
                                                swiperList.partySwiper1.autoplay.stop();
                                            };
                                        swiperList.partySwiper1.$el[0].onmouseleave =
                                            function () {
                                                swiperList.partySwiper1.autoplay.start();
                                            };
                                    }, 1200);
                                },
                            },
                        },
                        partySwiper4: {
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 8000,
                            },
                            direction: "horizontal",
                            observer: true,
                            on: {
                                init: function (swiper) {
                                    //Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.partySwiper4.$el[0].onmouseenter =
                                            function () {
                                                swiperList.partySwiper4.autoplay.stop();
                                            };
                                        swiperList.partySwiper4.$el[0].onmouseleave =
                                            function () {
                                                swiperList.partySwiper4.autoplay.start();
                                            };
                                    }, 1300);
                                },
                            },
                        },
                    };
                    break;
            }
            return optionsList;
        },
    },
    watch: {
        slideShowType(newType, oldType) {
            let _this = this;
            let { hldjGetApi } = this;

            if (newType == "party") {
                if (!hldjGetApi) {
                    _this.$getApi(api.hldj).then((res) => {
                        if (1 == res.code) {
                            _this.changeHLDJData(res.data);
                            _this.changeHLDJGetApi(true);
                        }
                    });
                }
            }
        },
    },
    data() {
        return {
            slideType: "left",
        };
    },
    mounted() {},
    methods: {
        ...mapMutations("hldj", ["changeHLDJGetApi", "changeHLDJData"]),
    },
};
