
import SafeReport from "../components/safeReport.vue";
import SafetyEducation from "../components/safetyEducation.vue";
import SafetyAccident from "../components/safeAccident.vue";

import { mapGetters } from "vuex";

export default {
    name: "slideRoom",
    components: {
        SafeReport,
        SafetyEducation,
        SafetyAccident,
    },
    computed: {
        ...mapGetters("publicInfo", ["slideShowType", "slideRoomShow"]),
    },
    watch: {},
    data() {
        return {
            slideType: "right",
        };
    },
    mounted() {},
    methods: {},
};
