
import { mapGetters, mapActions } from "vuex";
import TitleText from "@/components/titleText.vue";
import Number from "@/components/number/index.vue";

export default {
    name: "componentDemo",
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    components: {
        TitleText,
        Number,
    },
    computed: {
        ...mapGetters("msbz", ["msbzData", "msbzGetApi"]),
    },
    watch: {
        msbzGetApi(newData, oldData) {
            if (newData) {
                this.getPageInfo();
            }
        },
    },
    data() {
        return {
            contentList: [
                {
                    title: "总预约办事数",
                    number: 0,
                },
                {
                    title: "当月预约办事数",
                    number: 0,
                },
            ],
            numberStyle: {
                fontSize: 40,
                height: 40,
                width: 24,
                fontFamily: "DigitalReadoutThickUpright",
                color: "#FFFFFF",
            },
        };
    },
    mounted() {
        if (this.msbzGetApi) {
            this.getPageInfo();
        }
    },
    methods: {
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        showWin() {
            let _this = this;
            _this.changeWinShowAction(true);
            _this.changeWinShowTypeAction("curMonthAppoint");
        },
        getPageInfo() {
            let { msbzData } = this;

            this.contentList[0].number = parseInt(msbzData.totalAppoint);
            this.contentList[1].number = parseInt(msbzData.curMonthAppoint);
        },
    },
};
