
import { mapGetters, mapMutations } from "vuex";
import TitleText from "@/components/titleText.vue";
import api from "@/api";

export default {
  name: "componentDemo",
  props: {
    title: {
      type: String,
      default: () => {
        return "组件标题";
      },
    },
    slideType: {
      type: String,
      default: () => "left",
    },
  },
  components: {
    TitleText,
  },
  data() {
    return {
      safePeople: [
        {
          tagType: 10,
          icon: "iconlaoren-shi",
        },
        {
          tagType: 31,
          icon: "iconshewen1",
        },
        {
          tagType: 32,
          icon: "iconshedu1",
        },
        {
          tagType: 34,
          icon: "iconfanzui1",
        },
        {
          tagType: 35,
          icon: "iconzhaoshijingshen",
        },
        {
          tagType: 36,
          icon: "iconzaitao1",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("cesiumInfo", ["primitiveColorIdArr"]),
    ...mapGetters("pajk", ["pajkData", "pajkGetApi"]),
  },
  watch: {
    pajkGetApi(newObj, oldObj) {
      if (newObj) {
        this.getSafePeople();
      }
    },
  },
  mounted() {
    if (this.pajkGetApi) {
      this.getSafePeople();
    }
  },
  methods: {
    ...mapMutations("cesiumInfo", ["changePrimitiveColorIdArr"]),
    getSafePeople() {
      let { pajkData, safePeople } = this;

      let newList = [];
      safePeople.forEach((item) => {
        newList.push({
          icon: item.icon,
          ...pajkData.safe_people.filter((person) => {
            return item.tagType == person.tagType;
          })[0],
        });
      });

      this.safePeople = newList;
    },
    changeMonomerColor(tagType) {
      let _this = this;

      window.cesiumViewer.entities.removeAll();
      _this.getResidentMonomerList(tagType).then((idArr) => {
        if (idArr.length > 0) {
          idArr.forEach((item) => {
            item.coordinates = JSON.parse(item.coordinates);
            item.build_coordinates = JSON.parse(item.buildCoordinates);
            item.id = item.monomerId;
            window.primitiveLayer.addEntity(item);
            let centerPoint = window.primitiveLayer.getCenterpoint(item);
            item.centerLat = centerPoint.latitude;
            item.centerLon = centerPoint.longitude;
            console.log(item);
            window.primitiveLayer.createBillboard({
              id: item.buildingId,
              lon: item.centerLon,
              lat: item.centerLat,
              height: item.maxHeight + 10,
              text: "",
              font: "500 20px Microsoft YaHei",
              color: "#ffffff",
              show_bg: true,
              bgColor: "#2492FF",
              offset_x: 20,
              offset_y: -50,
              image: "/images/pic_" + tagType + ".png",
              img_width: 35,
              img_height: 35,
              fly_to: true,
            });
            // window.primitiveLayer.changePrimitiveColor(item.monomerId, color);
          });
        }
      });
    },

    getResidentMonomerList(tagType) {
      let _this = this;

      return new Promise((resolve, reject) => {
        _this
          .$getApi(api.getResidentMonomerList, {
            tagType: tagType,
          })
          .then((res) => {
            if (res.code === "1") {
              let Arr = [];
              res.data.monomerList.map((item) => {
                if (item.areaId) {
                  Arr.push(item);
                }
              });

              resolve(Arr);
            }
          });
      });
    },
    // chooseMen(clickIndex) {
    //     let { safePeople, primitiveColorIdArr } = this;
    //
    //     if (primitiveColorIdArr.length > 0) {
    //         this.clearPrimitiveColor().then((res) => {
    //             safePeople[clickIndex].areaIds.forEach((item, index) => {
    //                 if (index == 0) {
    //                     window.primitiveLayer.flyTo(item);
    //                 }
    //                 window.primitiveLayer.changePrimitiveColor(item);
    //             });
    //         });
    //     } else {
    //         safePeople[clickIndex].areaIds.forEach((item, index) => {
    //             if (index == 0) {
    //                 window.primitiveLayer.flyTo(item);
    //             }
    //             window.primitiveLayer.changePrimitiveColor(item);
    //         });
    //     }
    // },
    // eChartJiaAnPersonDistribute() {
    //     let _this = this;
    //     let { pajkGetApi, pajkData } = this;
    //     if (pajkGetApi) {
    //         axios.get("/path.json").then((result) => {
    //             let pathSymbols = result.data.data;
    //             console.log(pajkData.safe_people);
    //             let options = {
    //                 tooltip: {
    //                     trigger: "axis",
    //                     axisPointer: {
    //                         type: "none",
    //                     },
    //                     formatter: function (params) {
    //                         return params[0].name + ": " + params[0].value;
    //                     },
    //                 },
    //                 xAxis: {
    //                     data: pajkData.safe_people.map((item) => {
    //                         return item.key;
    //                     }),
    //                     axisTick: {
    //                         show: false,
    //                     },
    //                     axisLine: {
    //                         show: false,
    //                     },
    //                     axisLabel: {
    //                         color: "#fff",
    //                         fontSize: 10,
    //                         formatter: function (params, index) {
    //                             let text = "";
    //                             if (params.length > 4) {
    //                                 for (
    //                                     let i = 0;
    //                                     i < params.length;
    //                                     i++
    //                                 ) {
    //                                     if (i % 4 == 0) {
    //                                         text =
    //                                             text +
    //                                             (i > 3 ? "\n" : "") +
    //                                             params.substring(i, i + 4);
    //                                     }
    //                                 }
    //                             } else {
    //                                 text = params;
    //                             }
    //                             return text;
    //                         },
    //                     },
    //                 },
    //                 yAxis: {
    //                     show: true,
    //                     type: "value",
    //                     splitNumber: 5,
    //                     splitLine: {
    //                         show: false,
    //                     },
    //                     axisTick: {
    //                         show: false,
    //                     },
    //                     axisLine: {
    //                         show: false,
    //                     },
    //                     axisLabel: {
    //                         show: false,
    //                     },
    //                 },
    //                 // color: ["rgb(188, 255, 0)"],
    //                 grid: {
    //                     // 坐标轴的基础属性
    //                     left: "0%",
    //                     top: 40,
    //                     right: "5%",
    //                     bottom: "20%",
    //                 },
    //                 series: [
    //                     {
    //                         name: "hill",
    //                         type: "pictorialBar",
    //                         barCategoryGap: "-30%",
    //                         // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
    //                         symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
    //                         itemStyle: {
    //                             color: {
    //                                 type: "linear",
    //                                 x: 0,
    //                                 y: 0,
    //                                 x2: 0,
    //                                 y2: 1,
    //                                 colorStops: [
    //                                     {
    //                                         offset: 0,
    //                                         color: "rgba(24, 119, 245, 1)", // 0% 处的颜色
    //                                     },
    //                                     {
    //                                         offset: 1,
    //                                         color: "rgba(66, 166, 175, 1)", // 100% 处的颜色
    //                                     },
    //                                 ],
    //                                 global: false, // 缺省为 false
    //                             },
    //                             opacity: 0.7,
    //                         },
    //                         emphasis: {
    //                             itemStyle: {
    //                                 opacity: 1,
    //                             },
    //                         },
    //                         data: pajkData.safe_people.map(
    //                             (item, index) => {
    //                                 return item.value;
    //                             }
    //                         ),
    //                         z: 10,
    //                     },
    //                     {
    //                         name: "glyph",
    //                         type: "pictorialBar",
    //                         barGap: "-100%",
    //                         symbolPosition: "end",
    //                         symbolSize: 50,
    //                         symbolOffset: [0, "-90%"], // 控制图标在画布中的位置
    //                         itemStyle: {
    //                             color: {
    //                                 type: "linear",
    //                                 x: 0,
    //                                 y: 0,
    //                                 x2: 0,
    //                                 y2: 1,
    //                                 colorStops: [
    //                                     {
    //                                         offset: 0,
    //                                         color: "rgba(66, 166, 175, 1)", // 0% 处的颜色
    //                                     },
    //                                     {
    //                                         offset: 1,
    //                                         color: "rgba(66, 166, 175, 1)", // 100% 处的颜色
    //                                     },
    //                                 ],
    //                                 global: false, // 缺省为 false
    //                             },
    //                         },
    //                         data: pajkData.safe_people.map(
    //                             (item, index) => {
    //                                 let symbol;
    //                                 switch (index) {
    //                                 }
    //                                 return {
    //                                     value: item.value,
    //                                     symbol: pathSymbols[index].path,
    //                                     symbolSize: [20, 20],
    //                                 };
    //                             }
    //                         ),
    //                     },
    //                 ],
    //             };

    //             let mycharts = _this.$echarts.init(
    //                 document.getElementById("eChartJiaAnPersonDistribute")
    //             );

    //             mycharts.setOption(options);

    //             mycharts.on("click", (params) => {
    //                 console.log(params);
    //                 let clickIndex = params.dataIndex;
    //                 console.log(
    //                     "crowdDistribution",
    //                     _this.primitiveColorIdArr
    //                 );
    //                 if (_this.primitiveColorIdArr.length > 0) {
    //                     _this.clearPrimitiveColor().then((res) => {
    //                         pajkData.safe_people[
    //                             clickIndex
    //                         ].areaIds.forEach((item, index) => {
    //                             if (index == 0) {
    //                                 window.primitiveLayer.flyTo(item);
    //                             }

    //                             window.primitiveLayer.changePrimitiveColor(
    //                                 item
    //                             );
    //                         });
    //                     });
    //                 } else {
    //                     console.log(pajkData.safe_people[clickIndex]);
    //                     pajkData.safe_people[clickIndex].areaIds.forEach(
    //                         (item, index) => {
    //                             if (index == 0) {
    //                                 window.primitiveLayer.flyTo(item);
    //                             }
    //                             window.primitiveLayer.changePrimitiveColor(
    //                                 item
    //                             );
    //                         }
    //                     );
    //                 }
    //             });
    //         });
    //     }
    // },
    clearPrimitiveColor() {
      let _this = this;

      return new Promise((resolve, reject) => {
        _this.primitiveColorIdArr.forEach((item, index) => {
          let nowPrimitive = window.primitiveLayer.getPrimitiveById(item);
          let attributes =
            nowPrimitive._primitive.getGeometryInstanceAttributes(
              nowPrimitive._primitiveOptions.geometryInstances[0].id
            ); //获取某个实例的属性集
          window.primitiveLayer.changePrimitiveColor(item, [0, 0, 0, 0]);

          if (index == _this.primitiveColorIdArr.length - 1) {
            _this.changePrimitiveColorIdArr();
            resolve(_this.primitiveColorIdArr);
          }
        });
      });
    },
  },
};
