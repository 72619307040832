import {
  CallbackProperty,
  defined,
  destroyObject,
  BoundingSphere,
  Color,
  Cartographic,
  Math as CesiumMath,
  Entity,
  Event,
  EventHelper,
  VerticalOrigin,
  PinBuilder,
} from 'cesium';
import * as Cesium from 'cesium';
// for tree-shaking
// export { polygon as polygonByTurf } from '@turf/helpers';
// import areaByTurf from '@turf/area';
import * as turf from '@turf/turf'
import MouseEvent from '../widgets/MouseEvent';
import {
  mapActions
} from 'vuex';
import store from '../../../store';

const areaId = 'make-new-area';
const eventHelper = new EventHelper();
const changeEvent = new Event();

export default class ToolMakeArea extends MouseEvent {
  constructor(viewer) {
    super(viewer.scene);
    this.enable = false;
    this.viewer = viewer;

    this._polygonPositions = [];
    this._mousePosition = null;

    this._polygonCenter = null;
    this._polygonArea = 0;

    let polygon = this.createEmptyPolygon();
    let areaLabel = this.createPolygonAreaLabel();

    this.viewer.entityManager.add(polygon, areaId);
    this.viewer.entityManager.add(areaLabel, areaId);
  }

  /**
   * 获取多边形的点
   */
  get polygonPositions() {
    let position = this._polygonPositions;
    if (this._mousePosition) {
      position = position.concat(this._mousePosition);
    }
    if (position.length > 0) {
      position = position.concat(position[0]);
    }
    return position;
  }
  /**
   * 获取组成多边形点的数量
   */
  get positionsNumber() {
    return this.polygonPositions.length;
  }
  /**
   * 多边形顶点数量
   */
  get vertexNumber() {
    return this.positionsNumber - 1;
  }
  /**
   * 获取已经添加到地图上的点的数量
   */
  get addedVertexNumber() {
    return this._polygonPositions.length;
  }
  /**
   * 设置多边形中心
   */
  set polygonCenter(center) {
    this._polygonCenter = center;
  }
  /**
   * 获取多边形中心
   */
  get polygonCenter() {
    return this._polygonCenter;
  }
  /**
   * 设置多边形面积
   */
  set polygonArea(area) {
    this._polygonArea = area;
  }
  /**
   * 多边形面积
   */
  get polygonArea() {
    return this._polygonArea;
  }

  /**
   * 设置当前鼠标点
   */
  set mousePosition(mousePosition) {
    this._mousePosition = mousePosition;
    if (this.vertexNumber >= 3) {
      this.polygonCenter = this.getPolygonCenter(this.polygonPositions);
      this.polygonArea = this.computePolygonArea(this.polygonPositions);
    }
  }
  /**
   * 添加多边形组成点
   * @param {*} position
   */
  // addPosition(position) {
  //     this._polygonPositions.push(position);
  //     let vertex = createPoint(position);
  //     this.viewer.entityManager.add(vertex, areaId);
  //     if (this.vertexNumber >= 3) {
  //         this.polygonCenter = this.getPolygonCenter(this.polygonPositions);
  //         this.polygonArea = this.computePolygonArea(this.polygonPositions);
  //     }
  // }
  // =========================================================
  // 创建Entity
  // =========================================================
  /**
   * 创建多边形
   */
  createEmptyPolygon(
    options = {
      color: Color.fromCssColorString('white').withAlpha(0.6),
    }
  ) {
    let that = this;
    return new Entity({
      id: areaId,
      polygon: {
        hierarchy: new CallbackProperty(() => {
          return {
            positions: that.polygonPositions,
          };
        }, false),
        material: options.color,
        // outline: true,
        // outlineColor: Color.YELLOW,
        // outlineWidth: 4,
        // perPositionHeight: true,
        height: 10,
        closeTop: false,
        closeBottom: false,
      },
      polyline: {
        positions: new CallbackProperty(() => {
          return that.polygonPositions;
        }, false),
        material: Color.YELLOW,
        clampToGround: true,
        width: 2,
      },
    });
  }
  /**
   * 创建多边形面积显示Label
   */
  createPolygonAreaLabel() {
    let that = this;
    let position = new CallbackProperty(() => {
      return that.polygonCenter;
    }, false);
    let text = new CallbackProperty(() => {
      return that.polygonAreaText;
    }, false);
    let show = new CallbackProperty(() => {
      return that.vertexNumber >= 3;
    }, false);
    return that.createLabel(position, text, show);
  }

  // ==========================================
  // 鼠标事件
  // ==========================================
  onLeftClick({
    position
  }) {
    console.log(store);
    let cartesian = this.scene.pickPosition(position);
    console.log(cartesian);

    if (defined(cartesian)) {
      // this._polygonPositions.push(cartesian);
      this.addPosition(cartesian);

      if (this.addedVertexNumber > 2) {
        changeEvent.raiseEvent(true);
      }
    }
  }

  /**
   *
   * @param {*} positionList
   */
  mapCreateArea(positionList) {
    let polygon = positionList.map((position) => {
      let {
        longitude,
        latitude
      } = position;
      return [CesiumMath.toDegrees(longitude), CesiumMath.toDegrees(latitude)];
    });
    // return areaByTurf(polygonByTurf([polygon]));
    return turf.area(turf.polygon([polygon]))
  }

  /**
   * 添加多边形顶点
   */
  addPosition(position) {
    this._polygonPositions.push(position);

    let newPoint = this.createPoint(position);
    this.viewer.entityManager.add(newPoint, areaId);

    if (this.positionsNumber >= 3) {
      console.log('开始绘制');
      this.polygonCenter = this.getPolygonCenter(this.polygonPositions);
      this.polygonArea = this.computePolygonArea(this.polygonPositions);
    }
  }

  onMouseMove(event) {
    let {
      endPosition
    } = event;
    let cartesian = this.scene.pickPosition(endPosition);
    if (defined(cartesian)) {
      if (this.positionsNumber > 0) {
        this.mousePosition = cartesian;
      }
    }
  }
  onRightClick(event) {
    let _this = this;
    if (this.addedVertexNumber >= 3) {
      super.removeLeftClick();
      super.removeMouseMove();
      super.removeRightClick();
      this.mousePosition = null;

      console.log(this._polygonPositions);
      let centerCartesian = this.getPolygonCenter(this.polygonPositions);
      console.log(centerCartesian);

      store.state.cesiumClickType = _this.areaOptions.mainType;
      store.state.cesiumMakeNewArea = {
        type: _this.areaOptions.area.type,
        positionList: this._polygonPositions.map((item) => {
          return this.cartesianToCartographic(item);
        }),
        cartesianList: this._polygonPositions,
        centerPosition: this.cartesianToCartographic(centerCartesian),
        centerCartesian: centerCartesian,
      };
      store.state.publicInfo.toolSlideShowType = 2;
    }
  }

  addChangeEvent(listener, scope) {
    eventHelper.add(changeEvent, listener, scope);
  }
  /**
   * 保存测量数据
   */
  saveMeasure() {
    let id = createGuid();
    let createTime = new Date().toISOString();
    let {
      polygonPositions,
      polygonArea,
      polygonCenter
    } = this;
    let data = {
      id,
      title: '',
      positions: polygonPositions,
      createTime,
      area: polygonArea,
      center: polygonCenter,
    };
    return CACHE.put(id, data).then(() => {
      return data;
    });
  }
  destroy() {
    super.destroy();
    this.viewer.entityManager.removeByGroupId(areaId);
  }

  /**
   * 创建点
   * @param {Cartesian3[]} position
   */
  createPoint(position) {
    return new Entity({
      position,
      point: {
        pixelSize: 5,
        outlineWidth: 1,
        outlineColor: Color.YELLOW.withAlpha(0.6),
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
      },
    });
  }

  /**
   * 计算多边形面积
   * @param {Cartesians3[]} positions
   */
  computePolygonArea(positions) {
    let polygon = positions.map((cartesian) => {
      let {
        longitude,
        latitude
      } = Cartographic.fromCartesian(cartesian);
      return [CesiumMath.toDegrees(longitude), CesiumMath.toDegrees(latitude)];
    });
    // return areaByTurf(polygonByTurf([polygon]));
    return turf.area(turf.polygon([polygon]))
  }

  /**
   * 获取多边形中心点
   * @param {Cartesian3[]} positions
   */
  getPolygonCenter(positions) {
    let boundingSphere = BoundingSphere.fromPoints(
      positions,
      new BoundingSphere()
    );
    return boundingSphere.center;
  }

  /**
   * 创建多边形面积显示Label
   * @param {PositionProperty | Cartesian3} position
   * @param {Property | string} text
   * @param {Property | boolean} show
   */
  createLabel(position, text, show) {
    return new Entity({
      position,
      label: {
        text,
        font: '12px sans-serif',
        showBackground: true,
        backgroundColor: Color.BLACK,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        show,
      },
    });
  }

  /**
   * 笛卡尔坐标转经纬度坐标
   */
  cartesianToCartographic(cartesian) {
    var ellipsoid = this.viewer.scene.globe.ellipsoid;
    var cartesian3 = new Cesium.Cartesian3(
      cartesian.x,
      cartesian.y,
      cartesian.z
    );
    var cartographic = ellipsoid.cartesianToCartographic(cartesian3);
    var lat = Cesium.Math.toDegrees(cartographic.latitude);
    var lng = Cesium.Math.toDegrees(cartographic.longitude);
    var alt = cartographic.height;
    // console.log(lat + ',' + lng + ',' + alt)
    return {
      latitude: lat,
      longitude: lng,
      height: alt,
    };
  }

  canClick(options) {
    this._polygonPositions = [];
    this._mousePosition = null;
    this._polygonCenter = null;
    this.areaOptions = options;
    this._polygonArea = 0;
    this.enable = true;
  }

  cancelClick() {
    this._polygonPositions = [];
    this._mousePosition = null;
    this._polygonCenter = null;
    this._polygonArea = 0;
    this.enable = false;
    console.log(this.enable);
  }

  changeColor(color) {
    // this.destroy()

    console.log(this.polygonPositions);
    let entityObj = this.viewer.entityManager.getById(areaId);
    entityObj.polygon.material = Color.fromCssColorString(color).withAlpha(0.6);
  }
}