
import { CollBox, Ball } from "@/assets/js/public/collision";
import publicFunc from "@/assets/js/public/index";
import utils from "@/assets/js/util";

import TitleText from "@/components/titleText.vue";

import { mapGetters } from "vuex";
export default {
    name: "componentDemo",
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    components: {
        TitleText,
    },
    computed: {
        ...mapGetters("msbz", ["msbzData", "msbzGetApi"]),
    },
    watch: {
        msbzGetApi(newData, oldData) {
            let { ballList, msbzData } = this;
            if (newData) {
                // this.ballList = msbzData.focus_count
                this.ballContainer();
            }
        },
    },
    data() {
        return {
            ballList: [],
        };
    },
    mounted() {
        let _this = this;
        if (this.msbzGetApi) {
            this.ballContainer();
        }
    },
    methods: {
        ballContainer() {
            // 民意直通车生成碰撞球
            let _this = this;
            let { msbzData, msbzGetApi } = this;
            msbzData.focus_count.forEach((item) => {
                _this.ballList.push(item.key);
            });
            var says = _this.ballList;
            var oC = document.getElementById("hotContainer");
            var mxwidth = oC.offsetWidth;
            var mxheight = oC.offsetHeight;

            var oB = new CollBox("hotContainer");
            oB.ballRun();

            says.forEach((item, index) => {
                var b = publicFunc.rand(5, 10);
                var x = publicFunc.rand(b, mxwidth - b);
                var y = publicFunc.rand(b, mxheight - b);
                var ball = new Ball({
                    b: b,
                    x: x,
                    y: y,
                    sx: publicFunc.rand(1, 2),
                    sy: publicFunc.rand(1, 2),
                    opa: 1,
                    fontSize: publicFunc.rand(10, 16),
                    callBack: function (n) {
                        this.setOpa(publicFunc.rand(30, 100) / 100);
                    },
                });

                let color = utils.getRandomColor()
                ball.setHTML(`
                    <div style="color: ${color};">${item}</div>
                `);

                oB.addBall(ball);
            });
        },
    },
};
