
import { mapState, mapActions, mapGetters } from "vuex";
import { Slider } from "view-design";

export default {
    name: "toolTopMonomer",
    computed: {
        ...mapGetters("publicInfo", [
            "slideShowType",
            "toolSlideShowType",
            "slideRoomShow",
        ]),
        ...mapState(["cesiumClickType", "cesiumMakeNewMonomer"]),
    },
    data() {
        return {
            height: [0, 100],
            marks: {
                0: "0",
                50: "50",
                100: "100",
                150: "150",
                200: "200",
            },
        };
    },
    watch: {
        toolSlideShowType(newType, oldType) {
            console.log(this.slideShowType);
            console.log(this.toolSlideShowType);
            console.log(this.cesiumClickType);
        },
    },
    components: {
        Slider,
    },
    mounted() {
        let { slideShowType, toolSlideShowType, cesiumClickType } = this;
        console.log(slideShowType, toolSlideShowType, cesiumClickType);
    },
    methods: {
        reDraw() {
            let { cesiumClickType, cesiumMakeNewMonomer } = this;
            window.toolMakeMonomer.reDraw({
                mainType: cesiumClickType,
                monomer: {
                    type: cesiumMakeNewMonomer.type,
                },
            });
        },
        handleHeightChange([height, extrudedHeight]) {
            // 单体高度变化
            window.toolMakeMonomer.height = height;
            window.toolMakeMonomer.extrudedHeight = extrudedHeight;
        },
    },
};
