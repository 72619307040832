
import { mapGetters } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    computed: {
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
        ...mapGetters("publicInfo", ["eChartsLoad"]),
    },
    components: {
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    watch: {
        gfzzGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;

            if (newData && eChartsLoad) {
                this.cmpData = this.gfzzData;
                setTimeout(() => {
                    _this.drawEchart();
                }, 500);
            }
        },
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { gfzzData, gfzzGetApi } = this;

            if (gfzzGetApi && newStaue) {
                this.cmpData = this.gfzzData;
                setTimeout(() => {
                    _this.drawEchart();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("taskReduce")
                );

                eChart.dispose();
            }
        },
    },
    data() {
        return {
            titleList: [],
            cmpData: {},
        };
    },
    mounted() {
        let _this = this;
        if (this.gfzzGetApi && this.eChartsLoad) {
            this.cmpData = this.gfzzData;
            setTimeout(() => {
                _this.drawEchart();
            }, 500);
        }
    },
    methods: {
        drawEchart() {
            let { cmpData } = this;
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("taskReduce")
            );
            // 绘制图表
            myChart.setOption({
                tooltip: {
                    trigger: "axis",
                    formatter: "{b}: {c}辆",
                    axisPointer: {
                        type: "line",
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.5)",
                        },
                    },
                },
                legend: {
                    show: true,
                    right: 20,
                    top: 20,
                    data: [
                        {
                            name: "时间",
                            icon: "rect",
                        },
                    ],
                },
                grid: {
                    top: "3%",
                    bottom: "2%",
                    left: "10%",
                    right: "4%",
                    width: "auto",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: cmpData.pile_list.map((item) => {
                        return item.day;
                    }),
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.5)",
                            type: "dashed",
                            dashOffset: 0,
                        },
                    },
                    axisTick: {
                        show: true,
                    },
                    axisLabel: {
                        interval: 0, //代表显示所有x轴标签显示
                        rotate: 45,
                        textStyle: {
                            color: "rgba(255, 255, 255, 0.8)",
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.3)",
                            type: "dashed",
                            dashOffset: 0,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "rgba(255, 255, 255, 0.8)",
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: ["rgba(255,255,255,0.3)"],
                            type: "dashed",
                            dashOffset: 0,
                        },
                    },
                },
                series: [
                    {
                        data: cmpData.pile_list.map((item) => {
                            return item.value;
                        }),
                        type: "line",
                        showSymbol: false,
                        lineStyle: {
                            color: "#1877F5",
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(24,119,245, 0.4)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(24,119,245,0)",
                                    },
                                ]
                            ),
                        },
                        smooth: true,
                    },
                ],
            });
        },
    },
};
