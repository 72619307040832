import { render, staticRenderFns } from "./livelyInfos.vue?vue&type=template&id=b191410a&scoped=true&"
import script from "./livelyInfos.vue?vue&type=script&lang=js&"
export * from "./livelyInfos.vue?vue&type=script&lang=js&"
import style0 from "./livelyInfos.vue?vue&type=style&index=0&id=b191410a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b191410a",
  null
  
)

export default component.exports