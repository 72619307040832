
import AllPoint from "../components/all.vue";
import PointStats from "../components/stats.vue";
import RedemptionRecords from "../components/redemptionRecord.vue";
import PointRanking from "../components/rank.vue";
import PointTask from "../components/task.vue";
import TaskReduce from "../components/everyDayTask.vue";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import api from "@/api/index.js";

export default {
    name: "slideRoom",
    components: {
        Swiper,
        SwiperSlide,
        AllPoint,
        PointStats,
        RedemptionRecords,
        PointRanking,
        PointTask,
        TaskReduce,
    },
    props: {
        banners: {
            type: Array,
            default: () => [],
        },
    },
    directives: {
        swiper: directive,
    },
    computed: {
        ...mapGetters("publicInfo", [
            "slideShowType",
            "slideRoomShow",
            "slideTypeIndex",
            "slideAnimationTime",
        ]),
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
        swiperList() {
            let _this = this;
            let { slideType, slideShowType } = this;
            let swiperListTemp = {};
            switch (slideShowType) {
                case "point":
                    swiperListTemp = {
                        allPoint: _this.$refs.allPoint.$swiper,
                        pointSwiper2: _this.$refs.pointSwiper2.$swiper,
                        pointSwiper3: _this.$refs.pointSwiper3.$swiper,
                    };
                    break;
            }

            return swiperListTemp;
        },
        swiperOptionsList() {
            let _this = this;
            let optionsList = {};
            let { slideShowType } = this;
            switch (slideShowType) {
                case "point":
                    optionsList = {
                        allPoint: {
                            autoplay: {
                                disableOnInteraction: true,
                                delay: 4000,
                            }, //可选选项，自动滑动
                            stopOnLastSlide: false,
                            disableOnInteraction: false,
                            observer: true,
                            on: {
                                init: function (swiper) {
                                    // Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.allPoint.$el[0].onmouseenter =
                                            function () {
                                                swiperList.allPoint.autoplay.stop();
                                            };
                                        swiperList.allPoint.$el[0].onmouseleave =
                                            function () {
                                                swiperList.allPoint.autoplay.start();
                                            };
                                    }, 900);
                                },
                            },
                        },
                        pointSwiper2: {
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 4500,
                            },
                            observer: true,
                            on: {
                                init: function (swiper) {
                                    //Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.pointSwiper2.$el[0].onmouseenter =
                                            function () {
                                                swiperList.pointSwiper2.autoplay.stop();
                                            };
                                        swiperList.pointSwiper2.$el[0].onmouseleave =
                                            function () {
                                                swiperList.pointSwiper2.autoplay.start();
                                            };
                                    }, 1000);
                                },
                            },
                        },
                        pointSwiper3: {
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 5000,
                            },
                            observer: true,
                            on: {
                                init: function (swiper) {
                                    //Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.pointSwiper3.$el[0].onmouseenter =
                                            function () {
                                                swiperList.pointSwiper3.autoplay.stop();
                                            };
                                        swiperList.pointSwiper3.$el[0].onmouseleave =
                                            function () {
                                                swiperList.pointSwiper3.autoplay.start();
                                            };
                                    }, 1100);
                                },
                            },
                        },
                    };
                    break;
            }
            return optionsList;
        },
    },
    watch: {
        slideShowType(newType, oldType) {
            let _this = this;
            let { gfzzGetApi } = this;

            if (newType == "point") {
                if (!gfzzGetApi) {
                    _this.$getApi(api.gfzz).then((res) => {
                        if (1 == res.code) {
                            _this.changeGFZZData(res.data);
                            _this.changeGFZZGetApi(true);
                        }
                    });
                }
            }
        },
    },
    data() {
        return {
            slideType: "left",
        };
    },
    mounted() {},
    methods: {
        ...mapMutations("gfzz", ["changeGFZZGetApi", "changeGFZZData"]),
    },
};
