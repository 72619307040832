
import { mapGetters } from "vuex";
import Number from "@/components/number/index";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    components: {
        TitleText,
        Number,
    },
    computed: {
        ...mapGetters("msbz", ["msbzData", "msbzGetApi"]),
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    watch: {
        msbzGetApi(newData, oldData) {
            if (newData) {
                this.getCmpData();
            }
        },
    },
    data() {
        return {
            iconList: [
                {
                    icon: "iconjiezhong",
                },
                {
                    icon: "iconjiankong",
                },
                // {
                //     icon: "iconjingwaigeli",
                // },
                // {
                //     icon: "iconmijie",
                // },
            ],
            numberStyle: {
                fontSize: 32,
                height: 32,
                width: 26,
                color: "#2AF3FF",
                fontFamily: "DigitalReadoutThickUpright",
            },
        };
    },
    mounted() {
        if (this.msbzGetApi) {
            this.getCmpData();
        }
    },
    methods: {
        getCmpData() {
            let { msbzData, iconList } = this;
            let newList = [];

            msbzData.diagnosis.forEach((item, index) => {
                newList.push({
                    ...iconList[index],
                    title: item.key,
                    value: parseInt(item.value),
                });
            });

            this.iconList = newList;
        },
    },
};
