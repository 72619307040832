import * as Cesium from "cesium";


export default {
    // 经纬度转笛卡尔坐标
    cartographicToCartesian(position) {
        var ellipsoid = window.cesiumViewer.scene.globe.ellipsoid;

        var cartographic = Cesium.Cartographic.fromDegrees(
            position.longitude,
            position.latitude,
            position.height
        );
        var cartesian3 = ellipsoid.cartographicToCartesian(cartographic);
        // console.log(cartesian3);
        return cartesian3;
    },

    // 笛卡尔坐标转经纬度
    cartesianToCartographic(cartesian) {
        var ellipsoid = window.cesiumViewer.scene.globe.ellipsoid;
        var cartesian3 = new Cesium.Cartesian3(
            cartesian.x,
            cartesian.y,
            cartesian.z
        );
        var cartographic = ellipsoid.cartesianToCartographic(cartesian3);
        var lat = Cesium.Math.toDegrees(cartographic.latitude);
        var lng = Cesium.Math.toDegrees(cartographic.longitude);
        var alt = cartographic.height;
        // console.log(lat + ',' + lng + ',' + alt)
        return {
            latitude: lat,
            longitude: lng,
            height: alt,
        };
    },

    // 点击获取坐标点
    getClickPointCartographic(event) {
        console.log(event)
        var earthPosition =
            window.cesiumViewer.camera.pickEllipsoid(
                event.position,
                window.cesiumViewer.scene.globe.ellipsoid
            );
        var cartographic = Cesium.Cartographic.fromCartesian(
            earthPosition,
            window.cesiumViewer.scene.globe.ellipsoid,
            new Cesium.Cartographic()
        );
        var lat = Cesium.Math.toDegrees(cartographic.latitude);
        var lng = Cesium.Math.toDegrees(cartographic.longitude);
        var height = cartographic.height;
        
        console.log(
            "[Lng=>" + lng + ",Lat=>" + lat + ",H=>" + height + "]"
        );

        return {
            lng,
            lat,
            height
        }
    }
}