
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import api from "@/api/index.js";

export default {
    name: "buildCrowd",
    computed: {
        ...mapGetters("publicInfo", ["slideShowType"]),
      ...mapGetters("publicInfo", ["buildingId", "tagType"]),
    },
    data() {
        return {
          crowdList: {},
        };
    },
    watch: {
        slideShowType(newType, oldType) {
            if (this.slideShowType == "crowd") {
                this.getCrowdlist();
            }
        },
      buildingId(newType, oldType) {
          this.getCrowdlist();
      },
    },
    mounted() {
        if (this.slideShowType == "crowd") {
            this.getCrowdlist();
        }
    },
    methods: {
        ...mapActions("publicInfo", ["changeSlideTypeAction"]),
      getCrowdlist() {
        console.log('请求数据');
            let _this = this;
            _this.$getApi(api.getCrowdList, {
              buildingId: _this.buildingId,
              tagType:_this.tagType
            }).then((res) => {
                console.log(res);
                if (1 == res.code) {
                    _this.crowdList = res.data.monomerList;
                }
            });
        },
        closeWindow() {
            this.changeSlideTypeAction("lively");
        },
    },
};
