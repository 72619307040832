
import { mapGetters } from "vuex";
import Modal from "@/components/publicDillog/index.vue";
import TitleText from "@/components/titleText.vue";

export default {
    name: "forPeople",
    components: {
        Modal,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {
            show: false,
            left: "",
            top: "",
            hldjcolor: "",
            leftstatu: 0,
            topstatu: 0,
            leftlineheight: 0,
            toplineheight: 0,
            lineleft: "",
            linetop: "",
            toplineleft: "",
            toplinetop: "",
            diaValue: "",
            diaContent: "",
            pointList: [
                {
                    x: 3,
                    y: 85,
                    title: "上好一堂履新课",
                    titlePosition: "right",
                    line: {
                        width: 70,
                        x: 8,
                        y: -23,
                        rotate: -61,
                    },
                },
                {
                    x: 10,
                    y: 65,
                    title: "抢抓一个开门红",
                    titlePosition: "right",
                    line: {
                        width: 85,
                        x: 35,
                        y: 0,
                        rotate: -33,
                    },
                },
                {
                    x: 25,
                    y: 50,
                    title: "列出一张任务单",
                    titlePosition: "left",
                    line: {
                        width: 100,
                        x: 35,
                        y: 0,
                        rotate: -28,
                    },
                },
                {
                    x: 44,
                    y: 35,
                    title: "学好一身新本领",
                    titlePosition: "left",
                    line: {
                        width: 110,
                        x: 54,
                        y: 100,
                        rotate: 23,
                    },
                },
                {
                    x: 68,
                    y: 50,
                    title: "开展一轮传帮带",
                    titlePosition: "bottom",
                    line: {
                        width: 100,
                        x: 30,
                        y: -35,
                        rotate: -43,
                    },
                },
                {
                    x: 85,
                    y: 26,
                    title: "做实一线服务日",
                    titlePosition: "left",
                    line: {
                        width: 120,
                        x: -200,
                        y: -40,
                        rotate: 40,
                    },
                },
                {
                    x: 65,
                    y: 1,
                    title: "打造一张红名片",
                    titlePosition: "left",
                    line: {
                        width: 100,
                        x: 6.5,
                        y: 75,
                        rotate: 30,
                    },
                },
            ],
            pointIndex: -1,
        };
    },
    computed: {
        ...mapGetters("hldj", ["hldjData", "hldjGetApi"]),
    },
    mounted() {},
    methods: {
        clickPoint(e, index) {
            let { hldjData } = this;
            this.diaContent = hldjData.sevenList[index].content;
            this.diaValue = hldjData.sevenList[index].value;

            this.show = true;
            this.leftstatu = -74;
            this.topstatu = -20;
            this.hldjcolor = "#FFD500";
            this.leftlineheight = 210;
            this.toplineheight = 460;
            this.left = e.pageX - 300 + "px";
            this.top = e.pageY - 240 + "px";
            this.lineleft = e.pageX - 106 + "px";
            this.linetop = e.pageY - 140 + "px";
            this.toplineleft = e.pageX - 85 + "px";
            this.toplinetop = e.pageY - 450 + "px";

            this.pointIndex = index;
        },
        hideModal() {
            this.show = false;
            this.pointIndex = -1;
        },
    },
};
