import { render, staticRenderFns } from "./taskDia.vue?vue&type=template&id=d5a9bbfe&scoped=true&"
import script from "./taskDia.vue?vue&type=script&lang=js&"
export * from "./taskDia.vue?vue&type=script&lang=js&"
import style0 from "./taskDia.vue?vue&type=style&index=0&id=d5a9bbfe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5a9bbfe",
  null
  
)

export default component.exports