
import { mapGetters, mapActions } from "vuex";
import Number from "@/components/number/index.vue";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    components: {
        Number,
        TitleText,
    },
    computed: {
        ...mapGetters("hldj", ["hldjData", "hldjGetApi"]),
    },
    watch: {
        hldjGetApi(newData, oldData) {
            if (newData) {
                this.practicalCount = this.hldjData.practical_count;
            }
        },
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },

    data() {
        return {
            pageData: {},
            practicalCount: [],
            practicalIndex: -1,
        };
    },
    mounted() {
        if (this.hldjGetApi) {
            this.practicalCount = this.hldjData.practical_count;
        }
    },
    methods: {
        ...mapActions(["changeWinShowAction", "changeWinShowTypeAction"]),
        showFn() {
            let _this = this;
            _this.changeWinShowAction(true);
            _this.changeWinShowTypeAction("practical_count");
        },
    },
};
