
import { mapGetters } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",

    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    computed: {
        ...mapGetters("publicInfo", ["eChartsLoad"]),
        ...mapGetters("street", ["streetGetApi", "streetData"]),
    },
    components: {
        TitleText,
    },
    watch: {
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { streetGetApi } = this;

            if (streetGetApi && newStaue) {
                setTimeout(() => {
                    _this.drawLine();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("personTaskOne")
                );

                eChart.dispose();
            }
        },
        streetGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;

            if (eChartsLoad && newData) {
                _this.pageData = _this.streetData;
                setTimeout(() => {
                    _this.drawLine();
                    // _this.drawEchartRight();
                }, 500);
            }
        },
    },
    data() {
        return {
            pageData: {},
        };
    },
    mounted() {
        if (this.streetGetApi) {
            this.pageData = this.streetData;
            setTimeout(() => {
                this.drawLine();
                // _this.drawEchartRight();
            }, 500);
        }
    },
    methods: {
        drawLine() {
            let { streetGetApi, pageData, streetData } = this;
            let xData = [];
            let yData = [];
            streetData.today_task.forEach((item) => {
                xData.push(item.ORG_NAME);
                yData.push(item.ct);
            });
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("personTaskOne")
            );
            // 绘制图表
            myChart.setOption({
                tooltip: {
                    trigger: "item",
                    formatter: "{b}: {c}人",
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "20%",
                    top: "5%",
                    width: "auto",
                    containLabel: true,
                },
                xAxis: {
                    data: xData,
                    axisTick: {
                        //x轴刻度线
                        show: false,
                    },

                    splitLine: {
                        //网格线
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(255, 255, 255, 0.5)", //设置网格线类型 dotted：虚线   solid:实线
                        },
                        show: false, //隐藏或显示
                    },
                    axisLabel: {
                        interval: 0, //代表显示所有x轴标签显示
                        textStyle: {
                            color: "rgba(255, 255, 255, 0.8)",
                            fontSize: 8,
                            width: "40px",
                        },
                        formatter: (val) => {
                            let strArr = val.split("");
                            let str = "";

                            strArr.forEach((item, index) => {
                                str += item;
                                if (index % 3 == 0 && index > 0) {
                                    str += "\n";
                                }
                            });
                            return str;
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(255, 255, 255, 0.5)",
                        },
                    },
                },
                yAxis: {
                    // data: ["0", "200","400","600","800"],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(255, 255, 255, 0.5)",
                        },
                    },

                    type: "value",
                    // name : '%',
                    axisLabel: {
                        // formatter: '{value}',
                        textStyle: {
                            color: "rgba(255, 255, 255, 0.8)",
                        },
                    },

                    splitLine: {
                        //网格线
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(255, 255, 255, 0.1)", //设置网格线类型 dotted：虚线   solid:实线
                        },
                        show: true, //隐藏或显示
                    },
                },
                series: [
                    {
                        name: "Direct",
                        type: "bar",
                        stack: "total",
                        barWidth: 12,
                        barGap: "80%",
                        label: {
                            normal: {
                                show: false,
                                textStyle: {
                                    color: "#fff",
                                },
                            },
                        },
                        itemStyle: {
                            color: "#2AF3FF",
                        },

                        data: yData,
                    },
                ],
            });
        },
    },
};
