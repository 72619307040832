
import { mapGetters } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    components: {
        TitleText,
    },
    data() {
        return {
            cmpData: {},
        };
    },
    computed: {
        ...mapGetters("publicInfo", ["eChartsLoad"]),
        ...mapGetters("hldj", ["hldjData", "hldjGetApi"]),
    },
    watch: {
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { hldjGetApi } = this;
            if (hldjGetApi && newStaue) {
                this.cmpData = this.hldjGetApi;
                setTimeout(() => {
                    _this.drawLineRight();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("lessonsystemone")
                );

                eChart.dispose();
            }
        },
        hldjGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;

            if (newData && eChartsLoad) {
                this.cmpData = this.hldjGetApi;

                setTimeout(() => {
                    _this.drawLineRight();
                }, 500);
            }
        },
    },
    mounted() {
        let _this = this;
        if (this.hldjGetApi && this.eChartsLoad) {
            this.cmpData = this.hldjGetApi;
            setTimeout(() => {
                _this.drawLineRight();
            }, 500);
        }
    },
    methods: {
        drawLineRight() {
            let _this = this;
            let { hldjGetApi, hldjData } = this;
            // 基于准备好的dom，初始化echarts实例
            let appointment_right = this.$echarts.init(
                document.getElementById("lessonsystemone")
            );
            // 绘制图表
            appointment_right.setOption(
                {
                    grid: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: "100%",
                        height: "100%",
                    },
                    series: [
                        {
                            type: "gauge",
                            center: ["50%", "50%"],
                            radius: "80%",
                            startAngle: 210,
                            endAngle: -150,
                            progress: {
                                show: true,
                                width: 3,
                                itemStyle: {
                                    color: new _this.$echarts.graphic.LinearGradient(
                                        0,
                                        1,
                                        0,
                                        0,
                                        [
                                            { offset: 0, color: "#9c2829" },
                                            { offset: 1, color: "#de8f07" },
                                        ]
                                    ),
                                },
                            },
                            pointer: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 3,
                                    color: [[1, "rgba(0,0,0,0.1)"]],
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: false,
                                length: 15,
                                lineStyle: {
                                    width: 2,
                                    color: "#999",
                                },
                            },
                            axisLabel: {
                                show: false,
                                distance: 25,
                                color: "#999",
                                fontSize: 20,
                            },
                            anchor: {
                                show: false,
                                showAbove: true,
                                size: 25,
                                itemStyle: {
                                    borderWidth: 10,
                                },
                            },
                            title: {
                                show: false,
                            },
                            detail: {
                                show: true,
                                valueAnimation: true,
                                color: "#fff",
                                fontSize: 24,
                                offsetCenter: [0, 0],
                                formatter: (value) => {
                                    return value + "%";
                                },
                            },
                            data: [
                                {
                                    value: hldjGetApi
                                        ? parseInt(hldjData.party_lesson_rate) *
                                          1
                                        : 0,
                                },
                            ],
                        },
                    ],
                },
                true
            );
        },
    },
};
