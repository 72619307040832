
import WorkersNum from "../components/workerNum.vue";
import HouseholdNum from "../components/householdNum.vue";
import ResidentPeople from "../components/residentPeople.vue";
import CommunityCourtyard from "../components/communityCourtyard.vue";
import HousingSuits from "../components/housingSuits.vue";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import api from "@/api/index.js";

export default {
    name: "slideRoom",
    components: {
        WorkersNum,
        HouseholdNum,
        ResidentPeople,
        CommunityCourtyard,
        HousingSuits,
        Swiper,
        SwiperSlide,
    },
    props: {
        banners: {
            type: Array,
            default: () => [],
        },
    },
    directives: {
        swiper: directive,
    },
    computed: {
        ...mapGetters("publicInfo", [
            "slideShowType",
            "slideRoomShow",
            "slideTypeIndex",
            "slideAnimationTime",
        ]),
        ...mapGetters("street", ["streetData", "streetGetApi"]),
        swiperList() {
            let _this = this;
            let { slideType, slideShowType } = this;
            let swiperListTemp = {};
            switch (slideShowType) {
                case "street":
                    if (slideType == "left") {
                        swiperListTemp = {
                            streetSwiper1: _this.$refs.streetSwiper1.$swiper,
                            streetSwiper2: _this.$refs.streetSwiper2.$swiper,
                        };
                    }

                    if (slideType == "right") {
                        swiperListTemp = {
                            streetSwiper3: _this.$refs.streetSwiper3.$swiper,
                            streetSwiper4: _this.$refs.streetSwiper4.$swiper,
                            streetSwiper5: _this.$refs.streetSwiper5.$swiper,
                        };
                    }
                    break;
            }

            return swiperListTemp;
        },
        swiperOptionsList() {
            let _this = this;
            let optionsList = {};
            let { slideShowType } = this;
            switch (slideShowType) {
                case "street":
                    optionsList = {
                        streetSwiper1: {
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 4500,
                            },
                            observer: true,
                            on: {
                                init: function (swiper) {
                                    //Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.streetSwiper1.$el[0].onmouseenter =
                                            function () {
                                                swiperList.streetSwiper1.autoplay.stop();
                                            };
                                        swiperList.streetSwiper1.$el[0].onmouseleave =
                                            function () {
                                                swiperList.streetSwiper1.autoplay.start();
                                            };
                                    }, 1000);
                                },
                            },
                        },
                        streetSwiper2: {
                            autoplay: {
                                disableOnInteraction: true,
                                delay: 4000,
                            }, //可选选项，自动滑动
                            stopOnLastSlide: false,
                            disableOnInteraction: false,
                            direction: "vertical",
                            observer: true,
                            on: {
                                init: function (swiper) {
                                    //Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.streetSwiper2.$el[0].onmouseenter =
                                            function () {
                                                swiperList.streetSwiper2.autoplay.stop();
                                            };
                                        swiperList.streetSwiper2.$el[0].onmouseleave =
                                            function () {
                                                swiperList.streetSwiper2.autoplay.start();
                                            };
                                    }, 900);
                                },
                            },
                        },
                        streetSwiper3: {
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 4500,
                            },
                            observer: true,
                            on: {
                                init: function (swiper) {
                                    //Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.streetSwiper3.$el[0].onmouseenter =
                                            function () {
                                                swiperList.streetSwiper3.autoplay.stop();
                                            };
                                        swiperList.streetSwiper3.$el[0].onmouseleave =
                                            function () {
                                                swiperList.streetSwiper3.autoplay.start();
                                            };
                                    }, 800);
                                },
                            },
                        },
                        streetSwiper4: {
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 4500,
                            },
                            stopOnLastSlide: false,
                            disableOnInteraction: false,
                            direction: "vertical",
                            observer: true,
                            on: {
                                init: function (swiper) {
                                    //Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.streetSwiper4.$el[0].onmouseenter =
                                            function () {
                                                swiperList.streetSwiper4.autoplay.stop();
                                            };
                                        swiperList.streetSwiper4.$el[0].onmouseleave =
                                            function () {
                                                swiperList.streetSwiper4.autoplay.start();
                                            };
                                    }, 1000);
                                },
                            },
                        },
                        streetSwiper5: {
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 5000,
                            },
                            observer: true,
                            on: {
                                init: function (swiper) {
                                    //Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.streetSwiper5.$el[0].onmouseenter =
                                            function () {
                                                swiperList.streetSwiper5.autoplay.stop();
                                            };
                                        swiperList.streetSwiper5.$el[0].onmouseleave =
                                            function () {
                                                swiperList.streetSwiper5.autoplay.start();
                                            };
                                    }, 1100);
                                },
                            },
                        },
                    };
                    break;
            }
            return optionsList;
        },
    },
    watch: {
        slideShowType(newType, oldType) {
            let _this = this;

            let { streetGetApi } = this;

            if (newType == "street") {
                if (!streetGetApi) {
                    _this.$getApi(api.street).then((res) => {
                        if (1 == res.code) {
                            _this.changeSTREETData(res.data);
                            _this.changeSTREETGetApi(true);
                        }
                    });
                }
            }
        },
    },
    data() {
        return {
            slideType: "left",
        };
    },
    mounted() {
        let _this = this;
    },
    methods: {
        ...mapMutations("street", ["changeSTREETGetApi", "changeSTREETData"]),
        // getStreet() {
        //     let _this = this;
        //     _this.$getApi(api.street).then((res) => {
        //         if (1 == res.code) {
        //             _this.changeSTREETDataAction(res.data);
        //             _this.changeSTREETGetApiAction(true);
        //         }
        //     });
        // },
        strikeRefsWithAreaCameraFly() {
            this.$emit("strikeRefsWithAreaCameraFlyTo");
        },
    },
};
