
import CommunitySpecialist from "../components/communitySpecialist.vue";
import CommunityTransition from "../components/communityTransition.vue";
import CommunityHelp from "../components/communityHelp.vue";
import Volunteer from "../components/volunteer.vue";

import { mapGetters } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
    name: "slideRoom",
    components: {
        CommunitySpecialist,
        CommunityTransition,
        CommunityHelp,
        Volunteer,

        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive,
    },
    computed: {
        ...mapGetters("publicInfo", [
            "slideShowType",
            "slideRoomShow",
            "slideTypeIndex",
        ]),
    },
    watch: {},
    data() {
        return {
            slideType: "right",
        };
    },
    mounted() {},
    methods: {},
};
