
import SafelyInfo from "../components/safelyInfo.vue";
import CrowdDistribution from "../components/crowdDistribution.vue";
import SafeDefender from "../components/safeDefender.vue";

import { mapGetters, mapMutations } from "vuex";
import api from "@/api/index.js";

export default {
    name: "slideRoom",
    components: {
        SafelyInfo,
        CrowdDistribution,
        SafeDefender,
    },
    props: {
        banners: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapGetters("publicInfo", ["slideShowType", "slideRoomShow"]),
        ...mapGetters("pajk", ["pajkData", "pajkGetApi"]),
    },
    watch: {
        slideShowType(newType, oldType) {
            let _this = this;
            let { pajkGetApi } = this;

            if (newType == "safe") {
                if (!pajkGetApi) {
                    _this.$getApi(api.pajk).then((res) => {
                        if (1 == res.code) {
                            _this.changePAJKData(res.data);
                            _this.changePAJKGetApi(true);
                        }
                    });
                }
            }
        },
    },
    data() {
        return {
            slideType: "left",
        };
    },
    mounted() {},
    methods: {
        ...mapMutations("pajk", ["changePAJKGetApi", "changePAJKData"]),
    },
};
