
import Left from "./slideRoom/left.vue";
import Right from "./slideRoom/right.vue";

export default {
    components: {
      Left,
      Right
    }
};
