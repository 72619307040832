
import { mapGetters, mapActions } from "vuex";
import Number from "@/components/number/index.vue";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    computed: {
        ...mapGetters("publicInfo", [
            "slideDomCssTransitionStart",
            "eChartsLoad",
        ]),
        ...mapGetters("hldj", ["hldjData", "hldjGetApi"]),
    },

    components: {
        Number,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {
            contentList: [
                {
                    title: "党员总数",
                    number: 999,
                },
            ],
            partyNumber: 0,
            partyAge: [],
            numberStyle: {
                fontSize: 36,
                height: 46,
                width: 24,
                fontFamily: "ZhenyanGB-Regular",
            },
        };
    },
    watch: {
        hldjGetApi(newData, oldData) {
            if (newData) {
                this.getCmpData();
                this.drawEchart();
            }
        },
    },
    mounted() {
        if (this.hldjGetApi) {
            this.getCmpData();
            this.drawEchart();
        }
    },
    methods: {
        ...mapActions("cesiumInfo", ["changeCesiumMarkerList"]),
        getCmpData() {
            this.partyNumber = this.hldjData.party_number;
            this.partyAge = this.hldjData.party_age;
        },
        drawEchart() {
            let _this = this;
            let { hldjData, partyAge } = this;
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("partyPoint")
            );

            myChart.setOption({
                backgroundColor: "rgba(0,0,0,0)",
                radar: [
                    {
                        indicator: partyAge.map((item) => {
                            return {
                                text: item.key,
                            };
                        }),
                        startAngle: 90,
                        splitNumber: 3,
                        name: {
                            formatter: "{value}",
                            textStyle: {
                                color: "#fff",
                            },
                        },
                        center: ["50%", "50%"], // 位置
                        radius: 110, //大小
                        splitArea: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#cd0504",
                                type: "dashed",
                                width: 0.5,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: "#FF6048",
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "雷达图",
                        type: "radar",
                        backgroundColor: "#fff",
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.25)",
                        },
                        itemStyle: {
                            color: "#FFE32A",
                        },
                        data: [
                            {
                                value: partyAge.map((item) => {
                                    return +item.value;
                                }),
                                areaStyle: {
                                    color: "rgba(255, 227, 42, 0.7)",
                                },
                            },
                        ],
                    },
                ],
            });
        },
    },
};
