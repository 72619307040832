
import DiseasePrevention from "../components/diseasePrevention.vue";
import ClassificalRank from "../components/classificalRank.vue";
import NoConfirmBuildReport from "../components/noConfirmBuildReport.vue";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import api from "@/api/index.js";

export default {
    name: "slideRoom",
    components: {
        Swiper,
        SwiperSlide,
        DiseasePrevention,
        ClassificalRank,
        NoConfirmBuildReport,
    },
    props: {
        banners: {
            type: Array,
            default: () => [],
        },
    },
    directives: {
        swiper: directive,
    },
    computed: {
        ...mapGetters("publicInfo", [
            "slideShowType",
            "slideRoomShow",
            "slideTypeIndex",
            "slideAnimationTime",
        ]),
        ...mapGetters("csgl", ["csglData", "csglGetApi"]),
    },
    watch: {
        slideShowType(newType, oldType) {
            let _this = this;
            let { gfzzGetApi } = this;

            if (newType == "city") {
                if (!gfzzGetApi) {
                    _this.$getApi(api.csgl).then((res) => {
                        if (1 == res.code) {
                            _this.changeCSGLData(res.data);
                            _this.changeCSGLGetApi(true);
                        }
                    });
                }
            }
        },
    },
    data() {
        return {
            slideType: "left",
        };
    },
    mounted() {},
    methods: {
        ...mapMutations("csgl", ["changeCSGLGetApi", "changeCSGLData"]),
    },
};
