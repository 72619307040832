
import Modal from "@/components/publicDillog/index.vue";
import TitleText from "@/components/titleText.vue";

import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
        Modal,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {
            show: false,
            left: "",
            top: "",
            hldjcolor: "",
            leftstatu: 0,
            topstatu: 0,
            leftlineheight: 0,
            toplineheight: 0,
            lineleft: "",
            linetop: "",
            toplineleft: "",
            toplinetop: "",
            diaValue: "",
            diaContent: "",
            posIndex: -1,
            partyMemberDemeanor: [],
        };
    },

    computed: {
        ...mapGetters("hldj", ["hldjData", "hldjGetApi"]),
        swiperOptionsList() {
            let _this = this;

            return {
                partymen: {
                    autoplay: {
                        disableOnInteraction: false,
                        delay: 2000,
                    }, //可选选项，自动滑动
                    slidesPerView: 3.5,
                    // width: 5 * fontBaseSize * scaleNum,
                    // height: ((windowHeight / 3) - (1.5 * fontBaseSize))
                    observer: true,
                },
            };
        },
    },
    watch: {
        hldjGetApi(newData, oldData) {
            let _this = this;

            if (newData && !oldData) {
                setTimeout(() => {
                    this.changeList();
                }, 500);
            }
        },
    },
    mounted() {
        if (this.hldjGetApi) {
            this.changeList();
        }
    },
    methods: {
        changeList() {
            this.partyMemberDemeanor = this.hldjData.partyMemberDemeanor;
        },
        changeStyle(item, index, e) {
            let { posIndex } = this;

            this.posIndex = index == posIndex ? -1 : index;
            this.diaContent = item.content;
            this.diaValue = item.photo;
            this.show = true;
            this.hldjcolor = "#fc0000";
            this.leftstatu = 60;
            this.topstatu = 18;
            this.left = e.pageX + 280 + "px";
            this.top = e.pageY - 284 + "px";
            this.lineleft = e.pageX + 164 + "px";
            this.linetop = e.pageY - 304 + "px";
            this.toplineleft = e.pageX + 64 + "px";
            this.toplinetop = e.pageY - 494 + "px";
        },
        hideModal() {
            this.show = false;
        },
    },
};
