
import { mapGetters, mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Modal from "@/components/publicDillog/index.vue";
import TitleText from "@/components/titleText.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        Modal,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {
            show: false,
            left: "",
            top: "",
            leftstatu: 0,
            topstatu: 0,
            leftlineheight: 0,
            toplineheight: 0,
            lineleft: "",
            linetop: "",
            toplineleft: "",
            toplinetop: "",
            diaValue: "",
            diaContent: "",
            posIndex: -1,
            activityList: [],
        };
    },
    computed: {
        ...mapGetters("pajk", ["pajkGetApi", "pajkData"]),
        ...mapGetters("config", ["configInfo"]),
        swiperOptionsList() {
            let _this = this;

            return {
                volunteer: {
                    // autoplay: {
                    //     disableOnInteraction: false,
                    //     delay: 2000,
                    // }, //可选选项，自动滑动
                    slidesPerView: 2,
                    // width: 5 * fontBaseSize * scaleNum,
                    // height: ((windowHeight / 3) - (1.5 * fontBaseSize))
                    observer: true,
                    direction: "vertical",
                },
            };
        },
    },
    watch: {
        pajkGetApi(newObj, oldObj) {
            let _this = this;
            if (newObj & !oldObj) {
                setTimeout(() => {
                    _this.changeList();
                }, 500);
            }
        },
    },
    mounted() {
        if (this.pajkGetApi) {
            this.changeList();
        }
    },
    methods: {
        changeList() {
            let list = [];

            this.pajkData.titleList.forEach((item, index) => {
                if (index % 4 == 0) {
                    list.push(this.pajkData.titleList.slice(index, index + 4));
                }
            });
            this.activityList = list;
            // console.log(List);
            // console.log(this.hldjData.partyActivity);
        },
        changeStyle(item, index, e) {
            let { posIndex } = this;

            this.posIndex = index == posIndex ? -1 : index;
            this.diaContent = item.key;
            this.diaValue = item.value;
            this.show = true;
            this.leftstatu = 60;
            this.topstatu = 18;
            this.left = e.pageX + 280 + "px";
            this.top = e.pageY - 284 + "px";
            this.lineleft = e.pageX + 164 + "px";
            this.linetop = e.pageY - 304 + "px";
            this.toplineleft = e.pageX + 64 + "px";
            this.toplinetop = e.pageY - 494 + "px";
        },
        hideModal() {
            this.show = false;
        },
    },
};
