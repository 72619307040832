
import LessonSystem from "../components/lessonSystem.vue";
import LessonSystemTwo from "../components/lessonSystemTwo.vue";
import ForPeople from "../components/forPeople.vue";
import ThePeople from "../components/thePeople.vue";

import { mapState, mapGetters } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
    name: "slideRoom",
    components: {
        Swiper,
        SwiperSlide,
        LessonSystem,
        LessonSystemTwo,
        ForPeople,
        ThePeople,
    },
    directives: {
        swiper: directive,
    },
    computed: {
        ...mapGetters("publicInfo", [
            "slideShowType",
            "slideRoomShow",
            "slideTypeIndex",
        ]),
        swiperList() {
            let _this = this;
            let { slideType, slideShowType } = this;
            let swiperListTemp = {};
            switch (slideShowType) {
                case "party":
                    swiperListTemp = {
                        partySwiper2: _this.$refs.partySwiper2.$swiper,
                        partySwiper3: _this.$refs.partySwiper3.$swiper,
                    };
                    break;
            }

            return swiperListTemp;
        },
        swiperOptionsList() {
            let _this = this;
            let optionsList = {};
            let { slideShowType } = this;
            switch (slideShowType) {
                case "party":
                    optionsList = {
                        partySwiper2: {
                            autoplay: {
                                disableOnInteraction: false,
                                delay: 3000,
                            },
                            direction: "vertical",
                            observer: true,
                            on: {
                                init: function (swiper) {
                                    //Swiper初始化了
                                    setTimeout(() => {
                                        let { swiperList } = _this;
                                        swiperList.partySwiper2.$el[0].onmouseenter =
                                            function () {
                                                swiperList.partySwiper2.autoplay.stop();
                                            };
                                        swiperList.partySwiper2.$el[0].onmouseleave =
                                            function () {
                                                swiperList.partySwiper2.autoplay.start();
                                            };
                                    }, 1300);
                                },
                            },
                        },
                    };
                    break;
            }
            return optionsList;
        },
    },
    watch: {},
    data() {
        return {
            slideType: "right",
        };
    },
    mounted() {},
    methods: {},
};
