
import { mapGetters } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    components: {
        TitleText,
    },
    computed: {
        ...mapGetters("pajk", ["pajkData", "pajkGetApi"]),
    },
    watch: {
        pajkGetApi(newData, oldData) {
            if (newData) {
                this.warningList = this.pajkData.warningList;
            }
        },
    },
    data() {
        return {
            warningList: [],
        };
    },
    mounted() {
        if (this.pajkGetApi) {
            this.warningList = this.pajkData.warningList;
        }
    },
    methods: {},
};
