
import { mapGetters } from "vuex";

import MonomerBuild from "./components/build.vue";
import MonomerUnit from "./components/unit.vue";
import MonomerHouse from "./components/house.vue";

export default {
    components: {
        MonomerBuild,
        MonomerUnit,
        MonomerHouse,
    },
    computed: {
        ...mapGetters("publicInfo", ["slideShowType", "slideRoomShow"]),
    },
    data() {
        return {
            slideType: "right",
        };
    },
};
