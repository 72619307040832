export default {
    /**
     * 设置CSS(暂时不用)。
     *
     * @author Helsing
     * @date 2019/11/12
     * @param {Element|HTMLElement|String} srcNodeRef 元素ID、元素或数组。
     * @param {String} property 属性。
     * @param {String} value 值。
     */
    setCss: function (srcNodeRef, property, value) {
        if (srcNodeRef) {
            if (srcNodeRef instanceof Array && srcNodeRef.length > 0) {
                for (let i = 0; i < srcNodeRef.length; i++) {
                    srcNodeRef[i].style.setProperty(property, value);
                }
            } else if (typeof (srcNodeRef) === "string") {
                if (srcNodeRef.indexOf("#") < 0 && srcNodeRef.indexOf(".") < 0 && srcNodeRef.indexOf(" ") < 0) {
                    const element = document.getElementById(srcNodeRef);
                    element && (element.style.setProperty(property, value));
                } else {
                    const elements = document.querySelectorAll(srcNodeRef);
                    for (let i = 0; i < elements.length; i++) {
                        elements[i].style.setProperty(property, value);
                    }
                }
            } else if (srcNodeRef instanceof HTMLElement) {
                srcNodeRef.style.setProperty(property, value);
            }
        }
    },
    // getPageXY:function($event){

    // },

    getCss: function (srcNodeRef, property) {
        console.log(srcNodeRef)
        if (srcNodeRef) {
            return srcNodeRef.offsetHeight
        }
        // if (srcNodeRef) {
        //     let value = ""
        //     if (srcNodeRef instanceof Array && srcNodeRef.length > 0) {
        //         for (let i = 0; i < srcNodeRef.length; i++) {
        //             value = srcNodeRef[i].style.getProperty(property);
        //         }
        //     } else if (typeof (srcNodeRef) === "string") {
        //         if (srcNodeRef.indexOf("#") < 0 && srcNodeRef.indexOf(".") < 0 && srcNodeRef.indexOf(" ") < 0) {
        //             const element = document.getElementById(srcNodeRef);
        //             value = element && (element.style.getProperty(property));
        //         } else {
        //             const elements = document.querySelectorAll(srcNodeRef);
        //             for (let i = 0; i < elements.length; i++) {
        //                 value = elements[i].style.getProperty(property);
        //             }
        //         }
        //     } else if (srcNodeRef instanceof HTMLElement) {
        //         value = srcNodeRef.style.getProperty(property);
        //     }
        // }
    },

    /**
     * 设置元素的值(暂时不用)。
     *
     * @author Helsing
     * @date 2019/11/12
     * @param {String|HTMLElement|Array} srcNodeRef 元素ID、元素或数组。
     * @param {String} value 值。
     */
    setInnerText: function (srcNodeRef, value) {
        if (srcNodeRef) {
            if (srcNodeRef instanceof Array && srcNodeRef.length > 0) {
                const that = this;
                for (let i = 0; i < srcNodeRef.length; i++) {
                    let element = srcNodeRef[i];
                    if (that.isElement(element)) {
                        element.innerText = value;
                    }
                }
            } else if (typeof (srcNodeRef) === "string") {
                if (srcNodeRef.indexOf("#") < 0 && srcNodeRef.indexOf(".") < 0 && srcNodeRef.indexOf(" ") < 0) {
                    let element = document.getElementById(srcNodeRef);
                    element && (element.innerText = value);
                } else {
                    const elements = document.querySelectorAll(srcNodeRef);
                    for (let i = 0; i < elements.length; i++) {
                        elements[i].innerText = value;
                    }
                }
            } else {
                if (this.isElement(srcNodeRef)) {
                    srcNodeRef.innerText = value;
                }
            }
        }
    },

    /**
     * 设置元素的值(暂时不用)。
     *
     * @param {String|HTMLElement|Array} srcNodeRef 元素ID、元素或数组。
     * @param {String} value 值。
     */
    setInnerHtml: function (srcNodeRef, value) {
        if (srcNodeRef) {
            srcNodeRef.innerHTML = value;
            // if (srcNodeRef instanceof Array && srcNodeRef.length > 0) {
            //     const that = this;
            //     for (let i = 0; i < srcNodeRef.length; i++) {
            //         let element = srcNodeRef[i];
            //         if (that.isElement(element)) {
            //             element.innerText = value;
            //         }
            //     }
            // } else if (typeof (srcNodeRef) === "string") {
            //     if (srcNodeRef.indexOf("#") < 0 && srcNodeRef.indexOf(".") < 0 && srcNodeRef.indexOf(" ") < 0) {
            //         let element = document.getElementById(srcNodeRef);
            //         element && (element.innerText = value);
            //     } else {
            //         const elements = document.querySelectorAll(srcNodeRef);
            //         for (let i = 0; i < elements.length; i++) {
            //             elements[i].innerText = value;
            //         }
            //     }
            // } else {
            //     if (this.isElement(srcNodeRef)) {
            //         srcNodeRef.innerText = value;
            //     }
            // }
        }
    },

    /**
     * 判断对象是否为元素。
     *
     * @param {Object} obj 对象。
     * @returns {Boolean} 是或否。
     */
    isElement: function (obj) {
        return (typeof HTMLElement === 'object') ?
            (obj instanceof HTMLElement) :
            !!(obj && typeof obj === 'object' && (obj.nodeType === 1 || obj.nodeType === 9) && typeof obj.nodeName === 'string');
    },

    /**
     * 获取全球唯一ID。
     *
     * @param {Boolean} removeMinus 是否去除“-”号。
     * @returns {String} GUID。
     */
    getGuid: function (removeMinus) {
        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        if (removeMinus) {
            uuid = uuid.replace(/-/g, "");
        }
        return uuid;
    },

    /**
     * 获取随机颜色
     */
    getRandomColor() {
        let colorArr = [
            "#18A8F6",
            "#FFE32F",
            "#D9FF2F",
            "#90FF2F",
            "#3AFF2F",
            "#45FF70",
            "#23DE96",
            "#3AFFD4",
            "#23D9CD",
            "#2AF3FF",
            "#18E0F6",
            "#18C6F6",
            "#FFF"
        ]

        let randomIndex = parseInt(Math.random() * colorArr.length);

        return colorArr[randomIndex]
    },

    /**
     * 获取随机字符串
     * @param {Number} length: 生成字符串的长度
     * @returns {String} 返回随机字符串
     */
    createNonceStr(length = 16) {
        let chars = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "x",
            "y",
            "z",
        ];
        let randomStr = "";
        for (let i = 0; i < length; i++) {
            //这里是几位就要在这里不改变
            let id = parseInt(Math.random() * chars.length);
            randomStr += chars[id];
        }
        return randomStr
    },

    /**
     * 整理单元内住户的数组关系
     * @param {Array} originList 原始数组
     * @param {Array} nameList 提取住户号名称的数组
     */
    allListToGetHouseList: function (originList, nameList, sortType = "unshift") {
        let equalTime = 0,
            nowLastNum = -1

        nameList.forEach((item, index) => {
            let arr = item.split("")
            let lastNum = arr[arr.length - 1]

            if (index == 0) {
                nowLastNum = lastNum
            }

            if (nowLastNum == lastNum) {
                equalTime++
            }
        })


        let newList = []
        for (let i = 0; i < (nameList.length / equalTime); i++) {
            newList.push([])
        }

        originList.forEach((item, index) => {
            item.check = false
            if (sortType == 'unshift') {
                newList[index % (nameList.length / equalTime)].unshift(item)
            } else {
                newList[index % (nameList.length / equalTime)].push(item)

            }
        })

        return newList
    }
}