
import { mapGetters } from "vuex";
import Number from "@/components/number/index";
import TitleText from "@/components/titleText.vue";

export default {
    name: "pointRanking",
    components: {
        Number,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
    },
    computed: {
        ...mapGetters("publicInfo", ["eChartsLoad"]),
        ...mapGetters("hldj", ["hldjData", "hldjGetApi"]),
    },
    watch: {
        hldjGetApi(newData, oldData) {
            if (newData) {
                this.getCmpData();
                this.drawChart();
            }
        },
    },
    data() {
        return {
            colorList: ["#d40300", "#ffd523", "#ff6048", "#f4ff66", "#ff9c48"],
            partyPointLayer: [],
            partyPointRank: [],
        };
    },
    mounted() {
        if (this.hldjGetApi) {
            this.getCmpData();
            this.drawChart();
        }
    },
    methods: {
        getCmpData() {
            let { hldjData } = this;
            this.partyPointLayer = hldjData.party_point_layer;
            this.partyPointRank = hldjData.party_point_rank;
        },
        drawChart() {
            // 基于准备好的dom，初始化echarts实例
            let { partyPointLayer, colorList } = this;

            let myChart = this.$echarts.init(
                document.getElementById("partyPointOne")
            );

            myChart.setOption({
                tooltip: {
                    show: false,
                    trigger: "item",
                },
                series: [
                    {
                        type: "pie",
                        center: ["50%", "50%"],
                        radius: ["65%", "80%"],
                        selectedMode: "single",
                        avoidLabelOverlap: false,
                        itemStyle: {},
                        label: {
                            alignTo: "edge",
                            // formatter: "{b}: \n{c}个",
                            color: "#fff",
                            textBorderColor: "rgba(0,0,0,0)",
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(255, 255, 255, 0.3)",
                            },
                            label: {
                                show: true,
                                fontSize: "12",
                                textBorderColor: "rgba(0,0,0,0)",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: partyPointLayer.map((item, index) => {
                            return {
                                value: +item.value,
                                name: item.key,
                                itemStyle: {
                                    color: colorList[index],
                                },
                                label: {
                                    color: "#fff",
                                    shadowColor: colorList[index],
                                    shadowBlur: 10
                                },
                            };
                        }),
                    },
                ],
            });
        },
    },
};
