
import LivelyInfo from "../components/livelyInfos.vue";
import LivelyFacility from "../components/livelyFacility.vue";
import ResourceInfo from "../components/resourceInfo.vue";
import DiseasePreventionOne from "../components/diseasePreventionOne.vue";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import api from "@/api/index.js";

export default {
  name: "slideRoom",
  components: {
    Swiper,
    SwiperSlide,
    LivelyInfo,
    LivelyFacility,
    ResourceInfo,
    DiseasePreventionOne,
  },
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
  },
  directives: {
    swiper: directive,
  },
  computed: {
    ...mapGetters("publicInfo", [
      "slideShowType",
      "slideRoomShow",
      "slideTypeIndex",
      "slideAnimationTime",
    ]),
    ...mapGetters("msbz", ["msbzData", "msbzGetApi"]),
    swiperList() {
      let _this = this;
      let { slideType, slideShowType } = this;
      let swiperListTemp = {};
      switch (slideShowType) {
        case "lively":
          swiperListTemp = {
            livelySwiper1: _this.$refs.livelySwiper1.$swiper,
          };
          break;
      }

      return swiperListTemp;
    },
    swiperOptionsList() {
      let _this = this;
      let optionsList = {};
      let { slideShowType } = this;
      switch (slideShowType) {
        case "lively":
          optionsList = {
            livelySwiper1: {
              autoplay: {
                disableOnInteraction: false,
                delay: 10000,
              },
              //autoplay: false,
              observer: true,
              direction: "horizontal",
              on: {
                init: function (swiper) {
                  console.log(swiper);
                  //Swiper初始化了
                  setTimeout(() => {
                    let { swiperList } = _this;
                    swiperList.livelySwiper1.$el[0].onmouseenter = function () {
                      swiperList.livelySwiper1.autoplay.stop();
                    };
                    swiperList.livelySwiper1.$el[0].onmouseleave = function () {
                      swiperList.livelySwiper1.autoplay.start();
                    };
                  }, 1200);
                },
              },
            },
          };
          break;
      }
      return optionsList;
    },
  },
  watch: {
    slideShowType(newType, oldType) {
      let _this = this;
      let { msbzGetApi } = this;

      if (newType == "lively") {
        if (!msbzGetApi) {
          _this.$getApi(api.msbz).then((res) => {
            if (1 == res.code) {
              _this.changeMSBZData(res.data);
              _this.changeMSBZGetApi(true);
            }
          });
        }
      }
    },
  },
  data() {
    return {
      slideType: "left",
    };
  },
  mounted() {},
  methods: {
    ...mapMutations("msbz", ["changeMSBZGetApi", "changeMSBZData"]),
    strikeRefsWithAreaCameraFly() {
      this.$emit("strikeRefsWithAreaCameraFlyTo");
    },
  },
};
