
import { mapState, mapGetters, mapActions } from "vuex";
import api from "@/api/index.js";
import MonomerTabWindow from "./tab.vue";

export default {
    name: "monomerUnit",
    computed: {
        ...mapGetters("publicInfo", ["slideShowType"]),
        ...mapGetters("cesiumInfo", [
            "nowChooseMonomerInfo",
            "nowChooseMonomerInfoObj",
        ]),
    },
    components: {
        MonomerTabWindow,
    },
    data() {
        return {
            cellInfo: {},
        };
    },
    mounted() {
        if (this.slideShowType == "monomer_unit") {
            this.getCellInfo();
        }
    },
    methods: {
        ...mapActions("publicInfo", ["changeSlideTypeAction"]),
        ...mapActions(["changeNowChooseMonomerInfoObjAction"]),
        getCellInfo() {
            let _this = this;
            let { nowChooseMonomerInfo, nowChooseMonomerInfoObj } = this;

            this.$getApi(api.getCellInfo, {
                cellId: nowChooseMonomerInfoObj.id || nowChooseMonomerInfo.id,
            }).then((res) => {
                console.log(res);
                if (1 == res.code) {
                    _this.cellInfo = res.data;
                }
            });
        },
        goHouseInfo(item) {
            let { cellInfo } = this;

            window.primitiveLayer.changePrimitiveColor(
                cellInfo.monomer.data.id,
                [0, 0, 0, 0]
            );
            this.changeSlideTypeAction("monomer_house");
            this.changeNowChooseMonomerInfoObjAction({
                id: item.houseId,
            });
        },
        closeWindow() {
            let { cellInfo } = this;

            window.primitiveLayer.changePrimitiveColor(
                cellInfo.monomer.data.id,
                [0, 0, 0, 0]
            );
            this.changeSlideTypeAction("point");
        },
    },
};
