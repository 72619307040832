import {
    ToolMakePoint,
    ToolMakeArea,
    ToolMakeMonomer,
    ToolMakeMonomerLayer
} from "./index";

import EntityManager from "./EntityManager";

import InfoTool from "../../assets/js/cesium/infoTool/infoTool.js";


export default class CesiumInit {
    constructor(viewer) {
        // 注册entity管理对象
        window.cesiumViewer.entityManager = new EntityManager(viewer);

        window.toolMakePoint = new ToolMakePoint(viewer); // 注册点绘制方法
        window.toolMakeArea = new ToolMakeArea(viewer); // 注册区域绘制方法
        window.toolMakeMonomer = new ToolMakeMonomer(viewer); // 注册单体绘制方法
        window.primitiveLayer = new ToolMakeMonomerLayer(viewer);
        window.toolMakeMonomerLayer = new ToolMakeMonomerLayer(viewer);

        window.cesiumInfoTool = new InfoTool(viewer); // 注册cesium地图信息弹窗事件
    }
}