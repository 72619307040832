
import { mapGetters } from "vuex";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    computed: {
        ...mapGetters("publicInfo", ["eChartsLoad"]),
        ...mapGetters("street", ["streetGetApi", "streetData"]),
    },
    components: {
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    watch: {
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { streetGetApi } = this;

            if (streetGetApi && newStaue) {
                setTimeout(() => {
                    _this.drawEchart();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("registeredUsers")
                );

                eChart.dispose();
            }
        },
        streetGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;

            if (eChartsLoad && newData) {
                _this.pageData = _this.streetData;
                this.streetData.street_regist_count.forEach((item) => {
                    _this.regCountAll += parseInt(item.ct);
                });

                this.streetData.monthly_visit.forEach((item) => {
                    _this.visitCountAll += parseInt(item.value);
                });
                setTimeout(() => {
                    _this.drawEchart();
                    // _this.drawEchartRight();
                }, 500);
            }
        },
    },
    data() {
        return {
            pageData: {},
            regCountAll: 0,
            visitCountAll: 0,
        };
    },
    mounted() {
        let _this = this;

        if (this.streetGetApi) {
            this.pageData = this.streetData;

            this.streetData.street_regist_count.forEach((item) => {
                _this.regCountAll += parseInt(item.ct);
            });

            this.streetData.monthly_visit.forEach((item) => {
                _this.visitCountAll += parseInt(item.value);
            });

            this.drawEchart();
        }
    },
    methods: {
        drawEchart() {
            let { streetGetApi, pageData, streetData } = this;
            let xData = [];
            let yData = [];
            let yData1 = [];
            streetData.street_regist_count.forEach((item) => {
                xData.push(item.month);
                yData.push(item.ct);
            });
            streetData.monthly_visit.forEach((item) => {
                //   xData.push(item.month)
                item.value = item.value * 1;
                yData1.push(item.value);
            });
            let registeredUsers = this.$echarts.init(
                document.getElementById("registeredUsers")
            );
            registeredUsers.setOption({
                tooltip: {
                    trigger: "item",
                    formatter: "{b}: {c}人",
                },
                grid: {
                    left: "0%",
                    right: "0%",
                    bottom: "20%",
                    top: "5%",
                    width: "auto",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: xData,
                        axisLabel: {
                            interval: 0, //代表显示所有x轴标签显示
                            textStyle: {
                                color: "rgba(255, 255, 255, 0.8)",
                                fontSize: 10,
                                width: "40px",
                            },
                            formatter: (val) => {
                                let strArr = val.split("");
                                let str = "";

                                strArr.forEach((item, index) => {
                                    str += item;
                                    if (index % 3 == 0 && index > 0) {
                                        str += "\n";
                                    }
                                });
                                return str;
                            },
                        },
                        //  axisTick: {		//x轴刻度线
                        //     show:false
                        // },
                        axisTick: {
                            alignWithLabel: true,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        min: 0,
                        axisLabel: {
                            formatter: "{value} 人",
                            textStyle: {
                                color: "rgba(255, 255, 255, 0.8)",
                            },
                        },
                        min: 0,
                        max: 1200,
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.1)", //设置网格线类型 dotted：虚线   solid:实线
                            },
                        },
                    },
                    {
                        type: "value",
                        min: 0,
                        axisLabel: {
                            formatter: "{value} 人",
                            textStyle: {
                                color: "rgba(255, 255, 255, 0.8)",
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: ["rgba(255,255,255,0.1)"],
                                type: "dashed",
                                dashOffset: 0,
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: "line",
                        // showSymbol: false,
                        symbol: "circle",
                        data: yData1,
                        lineStyle: {
                            color: "#1877f5",
                        },
                        itemStyle: {
                            color: "rgba(255, 255, 255, 0.7)",
                        },
                        // areaStyle: {
                        //     opacity: 0.8,
                        //     color: new this.$echarts.graphic.LinearGradient(
                        //         0,
                        //         0,
                        //         0,
                        //         1,
                        //         [
                        //             {
                        //                 offset: 0,
                        //                 color: "rgba(24,119,245, 0.4)",
                        //             },
                        //             {
                        //                 offset: 1,
                        //                 color: "rgba(24,119,245,0)",
                        //             },
                        //         ]
                        //     ),
                        // },
                        smooth: true,
                    },
                    {
                        type: "bar",
                        barWidth: 12,
                        barGap: "80%",
                        label: {
                            normal: {
                                show: false,
                                textStyle: {
                                    color: "#fff",
                                },
                            },
                        },
                        itemStyle: {
                            color: "#2AF3FF",
                        },
                        data: yData,
                        yAxisIndex: 1,
                    },
                ],
            });
        },
    },
};
