
import * as Cesium from "cesium";
import { mapGetters, mapActions, mapMutations } from "vuex";
import api from "@/api/index";
import TitleText from "@/components/titleText.vue";

export default {
    name: "propertyManage",
    computed: {
        ...mapGetters("publicInfo", ["eChartsLoad"]),
        ...mapGetters("csgl", ["csglData", "csglGetApi"]),
    },
    components: {
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {
            numberStyle: {
                fontSize: 24,
                width: 16,
            },
            pageData: {},
            dotIconList: [],
            propertyCompanyIndex: -1,
        };
    },
    watch: {
        csglGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;
            if (newData && eChartsLoad) {
                _this.pageData = _this.csglData;
                setTimeout(() => {
                    _this.drawEChart();
                }, 500);
            }
        },

        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { csglGetApi } = this;

            if (csglGetApi && newStaue) {
                setTimeout(() => {
                    _this.drawEChart();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("propertyEChart")
                );
                eChart.dispose();
            }
        },
    },
    mounted() {
        if (this.csglGetApi) {
            this.pageData = this.csglData;
            this.drawEChart();
        }
    },
    methods: {
        ...mapMutations("cesiumInfo", ["changeCesiumMarkerList"]),
        iconFlyTo(item, index) {
            let { dotIconList, propertyCompanyIndex } = this;

            this.propertyCompanyIndex == index
                ? (this.propertyCompanyIndex = -1)
                : (this.propertyCompanyIndex = index);

            if (dotIconList.length > 0 && index > -1) {
                window.cesiumViewer.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(
                        item.lon,
                        item.lat,
                        80
                    ),
                });
            } else {
                this.showIcon().then((list) => {
                    if (list.length > 0) {
                        window.cesiumViewer.camera.flyTo({
                            destination: Cesium.Cartesian3.fromDegrees(
                                list[index].position.lon,
                                list[index].position.lat,
                                80
                            ),
                        });
                    }
                });
            }
        },
        showIcon(item) {
            let _this = this;
            this.$emit("strikeRefsWithAreaCameraFly");
            let { dotIconList } = this;
            return new Promise((resolve, reject) => {
                _this
                    .$getApi(api.getDotList, {
                        type: "PROPERTY_COMPANY",
                        page: 1,
                        size: 1000,
                    })
                    .then((res) => {
                        if (1 == res.code) {
                            let iconList = res.data.dotList.map((item) => {
                                return {
                                    id: item.id,
                                    info: item,
                                    position: {
                                        lon: item.lon,
                                        lat: item.lat,
                                        height: item.height,
                                    },
                                    text: "",
                                    icon: {
                                        src: "https://nb-test.oss-cn-beijing.aliyuncs.com/image/47cbd601-0dc0-4a8e-bba1-ece8bc959309.png",
                                        width: 45,
                                        height: 45,
                                    },
                                };
                            });
                            _this.dotIconList = iconList;
                            _this.changeCesiumMarkerList(iconList);
                            resolve(iconList);
                        }
                    });
            });
        },
        drawEChart() {
            let _this = this;
            let { pageData } = this;
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("propertyEChart")
            );
            // 绘制图表
            myChart.setOption(
                {
                    grid: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: "100%",
                        height: "100%",
                    },
                    series: [
                        {
                            type: "gauge",
                            center: ["50%", "50%"],
                            radius: "100%",
                            startAngle: -90,
                            max: 1,
                            endAngle: 270,
                            progress: {
                                show: true,
                                width: 15,
                                itemStyle: {
                                    color: new _this.$echarts.graphic.LinearGradient(
                                        0,
                                        0,
                                        0,
                                        1,
                                        [
                                            {
                                                offset: 0,
                                                color: "rgb(0, 255, 255)",
                                            },
                                            {
                                                offset: 0.4,
                                                color: "rgb(0, 255, 255)",
                                            },
                                            {
                                                offset: 0.8,
                                                color: "rgb(24, 119, 245)",
                                            },
                                            {
                                                offset: 1,
                                                color: "rgb(66, 166, 175)",
                                            },
                                        ]
                                    ),
                                },
                            },
                            pointer: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 15,
                                    color: [[1, "rgba(0,0,0,0.1)"]],
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: false,
                            },
                            anchor: {
                                show: false,
                            },
                            title: {
                                show: false,
                            },
                            detail: {
                                show: true,
                                valueAnimation: true,
                                color: "#00c2ff",
                                fontSize: 40,
                                fontFamily: "DigitalReadoutThickUpright",
                                offsetCenter: [0, 0],
                            },
                            data: [
                                {
                                    value: pageData.propertyCompany
                                        ? pageData.propertyCompany.length * 1
                                        : 1,
                                },
                            ],
                        },
                    ],
                },
                true
            );
        },
    },
};
