
import { mapGetters } from "vuex";
import Number from "@/components/number/index";
import TitleText from "@/components/titleText.vue";
export default {
    name: "pointRanking",
    components: {
        Number,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
    },
    computed: {
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
        ...mapGetters("config", ["configInfo"]),
    },
    watch: {
        gfzzGetApi(newData, oldData) {
            if (newData) {
                console.log(this.gfzzData);
                this.workPointRankList = this.gfzzData.workPointRankList;
                this.honorPointRankList = this.gfzzData.honorPointRankList;
            }
        },
    },
    data() {
        return {
            workPointRankList: [],
            honorPointRankList: [],
            numberStyle: {
                fontSize: 10,
                color: "#1877F5",
                // width: 10,
                // letterSpacing:0,
                height: 20,
            },
        };
    },

    mounted() {
        if (this.gfzzGetApi) {
            this.workPointRankList = this.gfzzData.workPointRankList;
            this.honorPointRankList = this.gfzzData.honorPointRankList;
        }
    },
    methods: {},
};
