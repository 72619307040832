
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import * as Cesium from "cesium";
import api from "@/api/index";

export default {
    computed: {
        ...mapGetters("publicInfo", ["slideShowType", "toolSlideShowType"]),
    },
    watch: {
        toolSlideShowType(newIndex, oldIndex) {
            if (newIndex == 1 && oldIndex == 2) {
                this.itemIndex = -1;
            }
        },
    },
    data() {
        return {
            itemIndex: -1,
            toolIndex: -1,
            toolList: [
                {
                    title: "单体化",
                    type: "makeMonomer",
                    list: [],
                },
                {
                    title: "标记点",
                    type: "makePoint",
                    list: [],
                },
                {
                    title: "区域划分",
                    type: "makeArea",
                    list: [],
                },
            ],
        };
    },
    mounted() {
        let _this = this;
        this.getDotIcon();
    },
    methods: {
        ...mapMutations("cesiumInfo", ["changeCesiumMarkerList"]),
        ...mapMutations("publicInfo", ["changeToolSlideShowType"]),
        ...mapActions([
            "changeCesiumClickTypeAction",
            "changeToolDotClickGroupTypeAction",
        ]),
        getDotIcon() {
            let _this = this;

            this.$getApi(api.getDotIcon).then((res) => {
                if (1 == res.code) {
                    _this.toolList[0].list = res.data.monomerIconList;
                    _this.toolList[1].list = res.data.dotIconList;
                    _this.toolList[2].list = res.data.areaIconList;
                }
            });
        },
        deleteBtnClick(mainIndex) {
            let { toolList } = this;

            this.deleteCesiumDataType = "";
            switch (mainIndex) {
                case 0:
                    this.changeCesiumClickTypeAction({
                        mainType: "deleteMonomer",
                        makeNew: false,
                    });
                    break;
                case 1:
                    this.changeCesiumClickTypeAction({
                        mainType: "deletePoint",
                        makeNew: false,
                    });
                    break;
                case 2:
                    this.changeCesiumClickTypeAction({
                        mainType: "deleteArea",
                        makeNew: false,
                    });
                    break;
            }
            this.changeToolSlideShowType(2);
        },
        /**
         * item: 当前选择item
         * idx: 所选类型所在的下标
         * mainIndex: 所选类型所在一级下标
         */
        itemClick(item, idx, mainIndex) {
            let _this = this;

            let { toolList } = this;

            this.toolIndex = mainIndex;
            this.itemIndex = idx;
            // this.cesiumViewer.entities.removeAll();
            window.cesiumViewer.entities.removeAll();

            // 点击初始化
            window.toolMakeArea.cancelClick();
            window.toolMakePoint.cancelClick();
            window.toolMakeMonomer.cancelClick();

            let markerStyle = {
                width: 50,
                height: 50,
            };

            switch (toolList[mainIndex].type) {
                case "makeMonomer":
                    window.toolMakeMonomer.canClick({
                        mainType: toolList[mainIndex].type,
                        monomer: {
                            type: toolList[mainIndex].list[idx].type,
                        },
                    });
                    this.buildMonomer();
                    break;
                case "makePoint":
                    console.log(item);
                    this.changeToolDotClickGroupTypeAction(item.groupType);
                    window.toolMakePoint.canClick({
                        clickNum: 1,
                        mainType: toolList[mainIndex].type,
                        point: {
                            type: toolList[mainIndex].list[idx].type,
                            icon: toolList[mainIndex].list[idx].image || "",
                            iconWidth: markerStyle.width,
                            iconHeight: markerStyle.height,
                        },
                    });

                    this.$getApi(api.getDotList, {
                        type: item.type,
                        page: 1,
                        size: 1000,
                    }).then((res) => {
                        console.log(res);
                        if (1 == res.code) {
                            _this.changeCesiumMarkerList(
                                res.data.dotList.map((item) => {
                                    return {
                                        id: item.id,
                                        info: {
                                            type: item.type,
                                        },
                                        position: {
                                            lon: item.lon,
                                            lat: item.lat,
                                            height: item.height,
                                        },
                                        text: "",
                                        icon: {
                                            src: toolList[mainIndex].list[idx]
                                                .image,
                                            width: 30,
                                            height: 30,
                                        },
                                    };
                                })
                            );
                        }
                    });
                    break;
                case "makeArea":
                    window.toolMakeArea.canClick({
                        mainType: toolList[mainIndex].type,
                        area: {
                            type: toolList[mainIndex].list[idx].type,
                        },
                    });
                    break;
            }
        },
        buildMonomer() {
            let _this = this;

            let collection = new Cesium.PrimitiveCollection();
            window.cesiumViewer.scene.primitives.add(collection);
            // _this.$viewer.scene.primitives.add(collection)

            // this.$getApi()
        },
    },
};
