
import { mapGetters, mapActions } from "vuex";

export default {
  name: "loading",
  computed: {
    ...mapGetters("config", ["pageTitle"]),
    ...mapGetters("publicInfo", [
      "slideShowType",
      "slideRoomShow",
      "loadingPageHide",
      "loadingPageHideTimer",
    ]),
  },
  watch: {
    loadingPageHide(newStatus, oldStatus) {
      let _this = this;
      if (newStatus) {
        if (this.changeTitleTimer) {
          clearTimeout(_this.changeTitleTimer);
          this.changeTitleTimer = null;
          this.changeSlideTypeAction("street");
        }

        this.titleImage = 3;

        setTimeout(() => {
          _this.changeSlideRoomShowAction(true);
        }, _this.loadingPageHideTimer);
      }
    },
  },
  data() {
    return {
      show: false,
      titleImage: 1,
      changeTime: 6000,
      lastPageType: "",
      nowDate: "",
      nowTime: "",
    };
  },
  mounted() {
    this.changeTitleImage();
    this.getNowDate_Time();
  },
  methods: {
    ...mapActions("publicInfo", [
      "changeSlideRoomShowAction",
      "changeSlideTypeAction",
    ]),
    getNowDate_Time() {
      let _this = this;

      if (this.getDateTimer) {
        clearInterval(_this.getDateTimer);
        this.getDateTimer = null;
      }

      this.getDateTimer = setInterval(() => {
        let newDate = new Date();

        let year = newDate.getFullYear(),
          month =
            (newDate.getMonth() + 1).toString().length < 2
              ? "0" + (newDate.getMonth() + 1)
              : newDate.getMonth() + 1,
          day =
            newDate.getDate().toString().length < 2
              ? "0" + newDate.getDate()
              : newDate.getDate(),
          hour =
            newDate.getHours().toString().length < 2
              ? "0" + newDate.getHours()
              : newDate.getHours(),
          min =
            newDate.getMinutes().toString().length < 2
              ? "0" + newDate.getMinutes()
              : newDate.getMinutes(),
          sec =
            newDate.getSeconds().toString().length < 2
              ? "0" + newDate.getSeconds()
              : newDate.getSeconds();

        _this.nowDate = `${year}/${month}/${day}`;
        _this.nowTime = `${hour}:${min}:${sec}`;
      }, 1000);
    },
    changeTitleImage() {
      let _this = this;

      if (this.changeTitleTimer) {
        clearTimeout(_this.changeTitleTimer);
        this.changeTitleTimer = null;
      }

      this.changeTitleTimer = setTimeout(() => {
        _this.titleImage = 2;
        console.log(_this.titleImage);
      }, _this.changeTime);
    },
    goTool() {
      let _this = this;
      if (this.slideShowType == "tool") {
        this.changeSlideTypeAction(_this.lastPageType);
      } else {
        this.lastPageType = this.slideShowType;
        this.changeSlideTypeAction("tool");
        this.changeSlideRoomShowAction(false);
      }
    },
  },
};
