import InfoTool from "./infoTool"

export default {
    closeTip(id) {
        console.log(InfoTool)
    },
    getHelpInfoTip(info) {
        let imageList = ""
        info.helpPic && info.helpPic.split(",").forEach(item => {
            imageList += `<img src="${item}" />`
        })

        let html = `
        <div
            class="model model-show"
            style="z-index: 1000"
        >
            <div class="tip-title">
                <div class="text">${info.title}</div>
                <div class="close" onClick="infoToolClose('${info.id}')">
                    关闭
                </div>
            </div>

            <div class="tip-info">
                <div class="pub-user">
                    <img src=${info.pubUserPortrait} />
                    <div class="username">${info.pubUserName}</div>
                </div>
                <div class="text">${info.pubTime}</div>
                <div class="text">${info.contact}</div>
                <div class="text">${info.location}</div>
            </div>

            <div class="tip-content">
                <div class="title">求助详情</div>
                <div class="text">${info.helpContent}</div>
                <div class="image-room">` +
            imageList +
            `</div>
            </div>
        </div>
        `
        return html
    },
    getVideoTip(info) {
        console.log(info)
        // <div class="content" id="dplayer" ref="dplayer"></div>
        let html = `
        <div
            class="model model-show"
            style="z-index: 1000"
        >
            <div class="flex-title">
                <div class="text">实时监控</div>
                <div class="close" onClick="infoToolClose('${info.id}')">关闭</div>
            </div>

            <div class="content">
                <video
                    id="videoElement"
                    controls
                    autoplay
                    muted
                    style="width: 100%; height: 100%;"
                />
            </div>
        </div>
        `

        return html
    },
    getSmartWaterTip(info) {
        let imageList = ""
        info.fileUrl && info.fileUrl.split(",").forEach(item => {
            imageList += `<img src="${item}" />`
        })

        let html = `
        <div
            class="model model-show"
            style="z-index: 1000"
        >
            <div class="tip-title">
                <div class="text">${info.title}</div>
                <div class="close" onClick="infoToolClose('${info.id}')">
                    关闭
                </div>
            </div>

            <div class="tip-info">
                <div class="text">${info.time}</div>
                <div class="text">${info.usage}</div>
            </div>

            <div class="tip-content">
                <div class="title">水表详情</div>
                <div class="text">${info.content}</div>
                <div class="image-room">` +
            imageList +
            `</div>
            </div>
        </div>
        `
        return html
    },
    getFireTip(info) {
        let imageList = ""
        info.pic && info.pic.split(",").forEach(item => {
            imageList += `<img src="${item}" />`
        })

        let html = `
        <div
            class="model model-show"
            style="z-index: 1000"
        >
            <div class="tip-title">
                <div class="text">${info.title}</div>
                <div class="close" onClick="infoToolClose('${info.id}')">
                    关闭
                </div>
            </div>

            <div class="tip-info">
                <div class="text">${info.time}</div>
            </div>

            <div class="tip-content">
                <div class="title">烟火预警</div>
                <div class="text">${info.content}</div>
                <div class="image-room">` +
            imageList +
            `</div>
            </div>

            <div class="btn-room">
                <div class="btn" onClick="infoToolErrorReport('${info.id}')">误报</div>
                <div class="btn" onClick="infoToolSolve('${info.id}')">已处理</div>
            </div>
        </div>
        `
        return html
    },
    getGarbageTip(info) {
        let html = `
        <div
            class="model model-show garbage-room"
            style="z-index: 1000"
        >
            <div class="tip-title">
                <div class="text">垃圾亭房</div>
                <div class="close-btn" onClick="infoToolClose('${info.id}')"></div>
            </div>

            <div class="tip-content-bg">
                <div class="item all-point">
                    <div class="content">
                        <div
                            class="lively_facility_item"
                        >
                            <div class="item_bg_shadow"></div>
                            <div class="item_bg"></div>
                            <div class="item_top"></div>
                            <div class="item_top_text">
                                <span>21221分</span>
                            </div>
                            <div class="item_top_shadow"></div>
                            <div class="item_bottom">总奖励功分</div>
                        </div>
                    </div>
                </div>
                <div class="cut-line"></div>
                <div class="item now-in">
                    <div class="content">
                        <div class="img"></div>
                        <div>李文文</div>
                    </div>
                    <div class="bottom">当前值班人员</div>
                </div>
            </div>

            <div class="tip-content">
                <div class="content-title">功分奖励记录</div>
                <div class="content-list">
                    <div class="item">
                        <div class="item-title">
                            张廷玉获得3功分
                        </div>
                        <div class="item-time">
                            11-10 10:23
                        </div>
                    </div>

                    <div class="item">
                        <div class="item-title">
                            张廷玉获得3功分
                        </div>
                        <div class="item-time">
                            11-10 10:23
                        </div>
                    </div>

                    <div class="item">
                        <div class="item-title">
                            张廷玉获得3功分
                        </div>
                        <div class="item-time">
                            11-10 10:23
                        </div>
                    </div>

                    <div class="item">
                        <div class="item-title">
                            张廷玉获得3功分
                        </div>
                        <div class="item-time">
                            11-10 10:23
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `

        return html
    },
    getPropertyCompanyTip(info) {
        let html = ""
        html = `
            <div
                class="model model-show"
                style="z-index: 1000"
            >
                <div class="tip-title">
                    <div class="text">${info.title}</div>
                    <div class="close" onClick="infoToolClose('${info.id}')">
                        关闭
                    </div>
                </div>

                <div class="tip-content">
                    <div class="text">${info.content}</div>
                </div>
            </div>
        `

        return html
    },
    getSharedParkingTip(info) {
        let html = ""
        html = `
            <div
                class="model model-show"
                style="z-index: 1000"
            >
                <div class="tip-title">
                    <div class="text">${info.title}</div>
                    <div class="close" onClick="infoToolClose('${info.id}')">
                        关闭
                    </div>
                </div>

                <div class="tip-content">
                    <div class="text">总数: ${info.content}</div>
                    <div class="image-room-full">
                        <img src="${info.pic}" />
                    </div>
                </div>
            </div>
        `

        return html
    }
}