/**
 * 公共方法
 * 
 * 代码规范要求 (根据需求不断补充)
 * 1. 使用小驼峰形式命名方法名
 * 2. 所有公共方法均要填写注释
 *      a. 功能描述
 *      b. 传参说明
 */

export default {
  /**
   * 防抖函数
   * 
   * @param {fn} 回调函数
   * @param {wait} 等待时间, 以毫秒为单位
   */
  debounce(fn, wait) {
    var timer = null;
    return function () {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(fn, wait);
    }
  },

  /**
   * 获取随机数字
   * 
   * @param {min} 最小值
   * @param {max} 最大值
   */
  rand(min, max) {
    return min + parseInt((max - min) * Math.random());
  },

  /**
   * 根据时间戳获取日期
   * 
   * @param {param} 时间戳 
   */
  formatDate(param) {
    let date = new Date(param);
    let Y = date.getFullYear() + '-';
    let M = date.getMonth() + 1 < 10 ? ('0' + (date.getMonth() + 1) + '-') : date.getMonth() + 1 + '-';
    let D = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
    return Y + M + D;
  },


  /**
   * 根据时间戳获取时间
   * 
   * @param {param} 时间戳 
   */
  formatTime(param) {
    let date = new Date(param);
    let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
    let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return h + m + s;
  },
}
