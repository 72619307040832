
import { mapState, mapGetters } from "vuex";
import Number from "@/components/number/index";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    components: {
        Number,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    computed: {
        ...mapGetters("publicInfo", ["slideDomCssTransitionStart"]),
        ...mapGetters("msbz", ["msbzData", "msbzGetApi"]),
        ...mapState(["refreshNumber"]),
    },
    watch: {
        slideDomCssTransitionStart(newStart, oldStart) {
            let _this = this;
            if (newStart) {
                _this.getContentList();
            }
        },
        msbzGetApi(newStatue, oldStatus) {
            if (newStatue) {
                this.getContentList();
            }
        },
        refreshNumber(newNumber, oldNumber) {
            if (newNumber > 0) {
                this.getContentList();
            }
        },
    },
    data() {
        return {
            contentList: [
                {
                    title: "自动收发柜",
                    number: 999,
                },
                {
                    title: "人脸识别一体机",
                    number: 999,
                },
            ],
            numberStyle: {
                fontSize: 48,
                height: 48,
                width: 30,
                fontFamily: "DigitalReadoutThickUpright",
            },
        };
    },
    mounted() {
        if (this.msbzGetApi) {
            this.getContentList();
        }
    },
    methods: {
        getContentList() {
            let { msbzData } = this;
            this.contentList[0].number = msbzData.auto_cabinet;
            this.contentList[1].number = msbzData.face_recognition;
        },
    },
};
