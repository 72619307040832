
import { mapGetters } from "vuex";

export default {
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
    },
    computed: {
        ...mapGetters("publicInfo", ["slideShowType"]),
        titleList() {
            return this.title.split(",");
        },
    },
};
