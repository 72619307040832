
import { mapGetters } from "vuex";
import Number from "@/components/number/index";
import TitleText from "@/components/titleText.vue";
import store from "@/store";
import { isNumber } from "@turf/turf";

export default {
  name: "componentDemo",
  components: {
    Number,
    TitleText,
  },
  computed: {
    ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
    ...mapGetters("publicInfo", ["slideDomCssTransitionStart"]),
  },
  props: {
    title: {
      type: String,
      default: () => {
        return "组件标题";
      },
    },
    slideType: {
      type: String,
      default: () => "left",
    },
  },
  watch: {
    gfzzGetApi(newData, oldData) {
      let { gfzzData } = this;
      if (newData) {
        this.numberAll = parseInt(gfzzData.point_info_all);
        this.numberUser = parseInt(gfzzData.point_info_user);
        this.numberFamily = parseInt(gfzzData.point_info_family);
        this.numberTeam = parseInt(gfzzData.point_info_team);
      }
    },
  },
  data() {
    return {
      numberAll: 0,
      numberUser: 0,
      numberFamily: 0,
      numberTeam: 0,
      numberStyle: {
        fontSize: 50,
        color: "#2AF3FF",
        width: 30,
        height: 60,
        fontFamily: "DigitalReadoutThickUpright",
      },
      numberMinStyle: {
        fontSize: 24,
        color: "#fff",
        width: 16,
        height: 36,
      },
    };
  },
  mounted() {
    this.numberAll = this.f_num(store.state.gfzz.gfzzData.point_info_all);
    this.numberUser = this.f_num(store.state.gfzz.gfzzData.point_info_user);
    this.numberFamily = this.f_num(store.state.gfzz.gfzzData.point_info_family);
    this.numberTeam = this.f_num(store.state.gfzz.gfzzData.point_info_team);
  },
  methods: {
    isNumber,
    f_num(num) {
      if (parseInt(num) >= 0) {
        return parseInt(num);
      } else {
        return 0;
      }
    },
  },
};
