import MouseEvent from '../widgets/MouseEvent.js'


let pinId = 'cesium-3dtils-classification-pin';
const layerId = 'cesium-3dtils-classification-layer';

import {
    CallbackProperty,
    defined,
    destroyObject,
    Color,
    Cartographic,
    Math as CesiumMath,
    Entity,
    Event,
    EventHelper,
    VerticalOrigin,
    PinBuilder,
} from 'cesium';
import * as Cesium from 'cesium'
import store from "../../../store";



const pinBuilder = new PinBuilder();
export default class ToolMakePoint extends MouseEvent {

    constructor(viewer) {

        super(viewer.scene, false)
        this.viewer = viewer
        this.enable = false
        this.pointArr = []
        /**
         * 多边形顶点
         */
        this._polygonVertexPositions = [];

        /**
         * 当前鼠标位置
         */
        this._currMousePosition = null;
        this.height = 0;
        this.extrudedHeight = 100;
        this._isStratified = false;
        this._clickNum = 0
        this.pointOptions = {
            mainType: "",
            point: {
                type: "",
                position: "",
                cartesian: ""
            }
        }
        viewer.scene.globe.depthTestAgainstTerrain = true;
        //  this._polygon = this.initPolygon();
    }

    cartesianToCartographic(cartesian) {
        var ellipsoid = this.viewer.scene.globe.ellipsoid;
        var cartesian3 = new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z);
        var cartographic = ellipsoid.cartesianToCartographic(cartesian3);
        var lat = Cesium.Math.toDegrees(cartographic.latitude);
        var lng = Cesium.Math.toDegrees(cartographic.longitude);
        var alt = cartographic.height;
        console.log(lat + ',' + lng + ',' + alt)
        return {
            longitude: lng,
            latitude: lat,
            height: alt
        }
    }

    onLeftClick({
        position
    }) {
        let _this = this
        let cartesian = this.scene.pickPosition(position);

        if (defined(cartesian) && store.state.publicInfo.slideShowType == "tool") {
            this._polygonVertexPositions.push(cartesian);
            this.addPin(cartesian, this._polygonVertexPositions.length, () => {
                console.log("打点了")

                store.state.cesiumClickType = _this.pointOptions.mainType
                store.state.cesiumMakeNewPoint = {
                    type: _this.pointOptions.point.type,
                    position: _this.cartesianToCartographic(cartesian),
                    cartesian: cartesian
                }
                store.state.publicInfo.toolSlideShowType = 2
            });

            // if (this._polygonVertexPositions.length == this._clickNum) {
            //     this.enable = false
            // }
        }
    }

    setEntityId(id) {
        this.pointArr.push(id)
    }

    getEntityById(id) {
        let {
            pointArr
        } = this
        return pointArr.some(item => {
            return item == id
        })
    }

    addPin(position, seq, callback) {
        let _this = this
        // this.viewer.entityManager.add(new Entity({
        //     position,
        //     billboard: {
        //         image: _this.pointOptions.point.icon || pinBuilder.fromText(seq.toString(), Color.fromCssColorString('black').withAlpha(0.6), 48).toDataURL(),
        //         width: _this.pointOptions.point.iconWidth,
        //         height: _this.pointOptions.point.iconHeight,
        //         verticalOrigin: VerticalOrigin.BOTTOM,
        //     },
        // }), pinId);

        _this.viewer.entities.removeById(pinId)
        console.log(_this.pointOptions.point.icon)
        this.pointArr.push(pinId)
        _this.viewer.entities.add({
            id: pinId,
            type: "marker",
            position,
            billboard: {
                image: _this.pointOptions.point.icon || pinBuilder.fromText(seq.toString(), Color.fromCssColorString('black').withAlpha(0.6), 48).toDataURL(),
                // image: pinBuilder.fromText(seq.toString(), Color.fromCssColorString('black').withAlpha(0.6), 48).toDataURL(),
                width: _this.pointOptions.point.iconWidth || 45,
                height: _this.pointOptions.point.iconHeight || 45,
                color: Cesium.Color.fromCssColorString(
                    "#ffffff"
                ).withAlpha(1),
                disableDepthTestDistance: 1000000000,
                heightReference: Cesium.HeightReference.NONE,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                classificationType: Cesium.ClassificationType.BOTH,
            },
        });
        callback()
    }

    /**
     * 
     * @param {*} options 设置参数
     */
    canClick(options) {
        this._polygonVertexPositions = [];
        this._currMousePosition = null;
        this._clickNum = options.clickNum
        this.pointOptions = options
        this.enable = true;
    }

    cancelClick() {
        this._polygonVertexPositions = [];
        this._currMousePosition = null;
        this.enable = false;
    }

    cancelInput() {
        this.viewer.entities.removeById(pinId)
    }

    savePoint() {
        pinId = pinId + this._polygonVertexPositions.length
    }
}