
import { mapActions, mapGetters } from "vuex";

export default {
    props: {
        info: {
            type: Object,
            default() {
                return {
                    build: {
                        areaName: "楼栋",
                    },
                    cell: {
                        areaName: "单元",
                    },
                    houseName: "户",
                };
            },
        },
    },
    computed: {
        ...mapGetters("publicInfo", ["slideShowType"]),
    },
    mounted() {
    },
    methods: {
        ...mapActions("publicInfo", ["changeSlideTypeAction"]),
        ...mapActions(["changeNowChooseMonomerInfoObjAction"]),
        goBuildInfo() {
            let { info, slideShowType } = this;
            console.log(slideShowType);
            if (slideShowType == "monomer_unit") {
                window.primitiveLayer.changePrimitiveColor(
                    info.monomer.data.id,
                    [0, 0, 0, 0]
                );
            }

            window.primitiveLayer.changePrimitiveColor(
                info.build.id,
                [1, 0, 1, 0.5]
            );
            this.changeSlideTypeAction("monomer_build");
            this.changeNowChooseMonomerInfoObjAction({
                id: info.build.id,
            });
        },
        goUnitInfo() {
            let { info, slideShowType } = this;
            console.log(slideShowType);
            if (slideShowType == "monomer_house") {
                window.primitiveLayer.changePrimitiveColor(
                    info.monomer.data.id,
                    [0, 0, 0, 0]
                );
            }

            window.primitiveLayer.changePrimitiveColor(info.cell.id);
            this.changeSlideTypeAction("monomer_unit");
            this.changeNowChooseMonomerInfoObjAction({
                id: info.cell.id,
            });
        },
        getCellInfo() {},
    },
};
