
import Number from "@/components/number/index";
import TitleText from "@/components/titleText.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
    name: "componentDemo",
    components: {
        Number,
        TitleText,
    },
    computed: {
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
        ...mapGetters("publicInfo", [
            "slideDomCssTransitionStart",
            "eChartsLoad",
        ]),
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    watch: {
        slideDomCssTransitionStart(newStart, oldStart) {
            if (newStart) {
                this.getNumberList();
            }
        },
        gfzzGetApi(newData, oldData) {
            let _this = this;

            if (newData) {
                this.cmpData = this.gfzzData;
                setTimeout(() => {
                    _this.drawGauge();
                    _this.getNumberList();
                }, 500);
            }
        },
        // eChartsLoad(newStaue, oldStaue) {
        //     let _this = this;
        //     let { gfzzGetApi } = this;

        //     if (gfzzGetApi && newStaue && newStaue != oldStaue) {
        //         setTimeout(() => {
        //             _this.drawGauge();

        //             // 数字重新加载
        //         }, 500);
        //     } else {
        //         let eChart_1 = this.$echarts.init(
        //             document.getElementById("communityHelp")
        //         );

        //         let eChart_2 = this.$echarts.init(
        //             document.getElementById("communityGoodness")
        //         );

        //         eChart_1.dispose();
        //         eChart_2.dispose();
        //     }
        // },
    },
    data() {
        return {
            cmpData: {},
            numberList: [
                {
                    title: "求助数量 / 个",
                    number: 99,
                    numberStyle: {
                        fontSize: 24,
                        color: "#FFE32A",
                        width: 18,
                        height: 24,
                        fontFamily: "DigitalReadoutThickUpright",
                    },
                },
                {
                    title: "帮助数量 / 个",
                    number: 999,
                    numberStyle: {
                        fontSize: 24,
                        color: "#16FB89",
                        width: 18,
                        height: 24,
                        fontFamily: "DigitalReadoutThickUpright",
                    },
                },
                {
                    title: "资源共享 / 个",
                    number: 999,
                    numberStyle: {
                        fontSize: 24,
                        color: "#1F90E1",
                        width: 18,
                        height: 24,
                        fontFamily: "DigitalReadoutThickUpright",
                    },
                },
            ],
        };
    },
    mounted() {
        let _this = this;

        if (this.gfzzGetApi) {
            this.cmpData = this.gfzzData;
            setTimeout(() => {
                _this.drawGauge();
                _this.getNumberList();
            }, 500);
        }
    },
    methods: {
        ...mapActions(["changeWinShowTypeAction", "changeWinShowAction"]),
        showWin(item, index) {
            let _this = this;

            if (item.title == "求助数量") {
                _this.changeWinShowAction(true);
                _this.changeWinShowTypeAction("good_help_seek");
            } else if (item.title == "帮助数量") {
                _this.changeWinShowAction(true);
                _this.changeWinShowTypeAction("good_help_do");
            } else {
                _this.changeWinShowAction(true);
                _this.changeWinShowTypeAction("good_res_share");
            }
        },
        getNumberList() {
            let { cmpData } = this;
            this.numberList[0].number = cmpData.good_help_seek || 0;
            this.numberList[1].number = cmpData.good_help_do || 0;
            this.numberList[2].number = cmpData.good_res_share || 0;
        },
        drawGauge() {
            let _this = this;
            let { cmpData } = this;
            // 基于准备好的dom，初始化echarts实例
            let helpChart = this.$echarts.init(
                document.getElementById("communityHelp")
            );

            let goodnessChart = this.$echarts.init(
                document.getElementById("communityGoodness")
            );

            let options = {
                grid: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                },
                series: [
                    {
                        type: "gauge",
                        center: ["50%", "90%"],
                        radius: "140%",
                        startAngle: 180,
                        endAngle: 0,
                        min: 0,
                        max: 100,
                        progress: {
                            show: true,
                            width: 3,
                            itemStyle: {
                                color: new _this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(42, 243, 255, 0.1)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(42, 243, 255, 1)",
                                        },
                                    ]
                                ),
                            },
                        },
                        pointer: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                width: 3,
                                color: [[1, "rgba(0,0,0,0.1)"]],
                            },
                        },
                        axisTick: {
                            distance: -20,
                            splitNumber: 5,
                            lineStyle: {
                                width: 2,
                                color: new _this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        { offset: 0, color: "#1877F5" },
                                        { offset: 1, color: "#42A6AF" },
                                    ]
                                ),
                            },
                        },
                        splitLine: {
                            show: false,
                            length: 15,
                            lineStyle: {
                                width: 2,
                                color: "#999",
                            },
                        },
                        axisLabel: {
                            show: false,
                            distance: 25,
                            color: "#999",
                            fontSize: 20,
                        },
                        anchor: {
                            show: false,
                            showAbove: true,
                            size: 25,
                            itemStyle: {
                                borderWidth: 10,
                            },
                        },
                        title: {
                            show: false,
                        },
                        detail: {
                            show: true,
                            valueAnimation: true,
                            color: "#fff",
                            fontSize: 24,
                            offsetCenter: [0, -10],
                            formatter: (value) => {
                                return value + "%";
                            },
                        },
                        data: [
                            {
                                value: parseFloat(cmpData.good_help_rate),
                            },
                        ],
                    },
                ],
            };
            // 绘制图表
            helpChart.setOption(options);

            let optionsGood = Object.assign(options, {});
            optionsGood.series[0].data[0].value = parseFloat(
                cmpData.good_good_rate
            );
            goodnessChart.setOption(optionsGood);
        },
    },
};
