
import Appointment from "../components/appointment.vue";
import PeopleAppeal from "../components/peopleAppeal.vue";
import HotFocus from "../components/hotFocus.vue";
import ActiveTotal from "../components/activeTotal.vue";

import { mapState, mapGetters } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
    name: "slideRoom",
    components: {
        Swiper,
        SwiperSlide,
        Appointment,
        PeopleAppeal,
        HotFocus,
        ActiveTotal,
    },
    directives: {
        swiper: directive,
    },
    computed: {
        ...mapGetters("publicInfo", [
            "slideShowType",
            "slideRoomShow",
            "slideTypeIndex",
            "slideAnimationTime",
        ]),
    },
    watch: {},
    data() {
        return {
            slideType: "right",
        };
    },
    mounted() {},
    methods: {},
};
