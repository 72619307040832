
import { mapState, mapMutations, mapGetters } from "vuex";
import { Slider } from "view-design";
import moment from "moment";
import ToolTopMonomer from "../tool/topMonomer.vue";

import api from "../../api/index";
import util from "../../assets/js/util";
import axios from "axios";
export default {
    components: {
        Slider,
        ToolTopMonomer,
    },
    computed: {
        ...mapGetters("publicInfo", ["slideShowType", "toolSlideShowType"]),
        ...mapState([
            "toolDotClickGroupType",
            "cesiumClickType",
            "cesiumMakeNewMonomer",
            "cesiumMakeNewPoint",
            "cesiumMakeNewArea",
        ]),
    },
    watch: {
        cesiumClickType(newType, oldType) {
            let _this = this;
            console.log(newType);
            switch (newType) {
                case "deleteMonomer":
                    _this.getVillageList();
                    break;
                case "deletePoint":
                    break;
                case "deleteArea":
                    this.getGridList("GRID");
                    break;
            }
        },
        cesiumMakeNewMonomer(newData, oldData) {
            if (this.cesiumClickType == "makeMonomer") {
                this.getVillageList();
                // this.getBuildList();
            }
        },
        cesiumMakeNewArea(newData, oldData) {
            console.log(newData);
            if (this.cesiumClickType == "makeArea") {
                // switch (newData.type) {
                //     case "COMMUNITY":
                //         console.log("获取社区列表");
                //         break;
                //     case "GRID":
                this.getGridList(newData.type);
                //         break;
                // }
            }
        },
    },
    data() {
        return {
            titleText: "",
            nameText: "",
            addressText: "",
            contentText: "",
            positionNo: "",
            // typeFirstClass: ["COMMUNITY_PARTY", "COMPANY_PARTY", "NON_PARTY", "PARTY_ACT", "PARTY_CENTER", "RED_STATION", "AGED_UNIVERSITY", "CULTURAL_CENTER", "BEADHOUSE", "KINDER", "TRUST", "HOME_STATION", "PUBLIC_PARKING", ""],
            // typeSecondClass: [],
            openType: 1, //1: 第一类数据输入; 2: 第二类数据输入
            imgs: [],
            addimg: [],
            addimgtijiao: "",
            imgListArr: [],
            areaList: [],
            areaNameIndex: 0,
            areaColorList: [
                {
                    text: "浅粉红",
                    color: "#FFB6C1",
                },
                {
                    text: "猩红",
                    color: "#DC143C",
                },
                {
                    text: "紫罗兰红",
                    color: "#DB7093",
                },
                {
                    text: "适中的紫罗兰红",
                    color: "#C71585",
                },
                {
                    text: "李子红",
                    color: "#DDA0DD",
                },
                {
                    text: "洋红",
                    color: "#FF00FF",
                },
                {
                    text: "深洋红",
                    color: "#8B008B",
                },
                {
                    text: "深紫罗兰",
                    color: "#9400D3",
                },
                {
                    text: "靛青",
                    color: "#4B0082",
                },
                {
                    text: "板岩暗蓝灰",
                    color: "#7B68EE",
                },
                {
                    text: "深岩暗蓝灰",
                    color: "#483D8B",
                },
                {
                    text: "适中蓝",
                    color: "#0000CD",
                },
                {
                    text: "海军蓝",
                    color: "#000080",
                },
                {
                    text: "皇家蓝",
                    color: "#4169E1",
                },
                {
                    text: "淡蓝",
                    color: "#87CEFA",
                },
                {
                    text: "苍绿宝石",
                    color: "#AFEEEE",
                },
                {
                    text: "深青",
                    color: "#008B8B",
                },
                {
                    text: "春天绿",
                    color: "#3CB371",
                },
                {
                    text: "橄榄",
                    color: "#808000",
                },
                {
                    text: "橙",
                    color: "#FFA500",
                },
            ],
            areaColorIndex: 0,
            villageList: [],
            villageIndex: 0,
            buildList: [],
            buildIndex: 0,
            unitList: [],
            unitIndex: 0,
            houseList: [],
            houseModelIndex: 0,
            houseIndexList: [],
        };
    },

    mounted() {},
    methods: {
        ...mapMutations("publicInfo", ["changeToolSlideShowType"]),
        handleHeightChange([height, extrudedHeight]) {
            // 单体高度变化
            window.toolMakeMonomer.height = height;
            window.toolMakeMonomer.extrudedHeight = extrudedHeight;
            // this.toolMakeMonomer.stratify();
            // cesium3DTilesClassification.height = height;
            // cesium3DTilesClassification.extrudedHeight = extrudedHeight;
        },
        saveClassified() {
            let _this = this;

            let {
                buildList,
                buildIndex,
                unitList,
                unitIndex,
                houseList,
                houseModelIndex,
                houseIndexList,
            } = this;

            let { toolMakeMonomer } = window;

            let coordinates = toolMakeMonomer.getElementsArray();
            let cartographic = coordinates.reduce((total, cur) => {
                let { longitude, latitude } =
                    toolMakeMonomer.cartesianToCartographic(cur);

                total.push(longitude);
                total.push(latitude);
                return total;
            }, []);
            // let id = createGuid();
            let name = "Unnamed";
            let time = moment().format("YYYY-MM-DD HH:mm:ss");
            let idInfo = {
                code: "test_1",
                coordinates: cartographic,
                name,
                time,
                height: toolMakeMonomer.height,
                extrudedHeight: toolMakeMonomer.extrudedHeight,
            };
            let obj = {
                id: idInfo,
                coordinates: cartographic,
                name,
                time,
                height: toolMakeMonomer.height,
                extrudedHeight: toolMakeMonomer.extrudedHeight,
            };
            // CACHE.put(id.code, obj);
            // this.classfiedObj.push(obj);

            window.toolMakeMonomerLayer.addPolygon(obj);
            toolMakeMonomer.close();

            let monomerList = [];
            switch (this.cesiumMakeNewMonomer.type) {
                case "MONOMER1":
                    monomerList = [
                        {
                            coordinates: cartographic,
                            baseHeight: toolMakeMonomer.height,
                            floorNum: 1,
                            floorHeight:
                                toolMakeMonomer.extrudedHeight -
                                toolMakeMonomer.height,
                            height: toolMakeMonomer.extrudedHeight,
                            data: {
                                id: buildList[buildIndex].id,
                                type: "build",
                                name: buildList[buildIndex].name,
                                position: {},
                            },
                        },
                    ];
                    break;
                case "MONOMER2":
                    monomerList = [
                        {
                            coordinates: cartographic,
                            baseHeight: toolMakeMonomer.height,
                            floorNum: 1,
                            floorHeight:
                                toolMakeMonomer.extrudedHeight -
                                toolMakeMonomer.height,
                            height: toolMakeMonomer.extrudedHeight,
                            data: {
                                id: unitList[unitIndex].id,
                                type: "unit",
                                name: unitList[unitIndex].name,
                                position: {},
                            },
                        },
                    ];
                    break;
                case "MONOMER3":
                    // let stratify = toolMakeMonomer.getElementsStratifyArray();
                    this.classifiedStratifyView().then((res) => {
                        let stratifyPostion =
                            toolMakeMonomer.getElementsStratifyArray();
                        console.log(stratifyPostion);
                        stratifyPostion.forEach((item, index) => {
                            console.log(item);
                            window.toolMakeMonomerLayer.addPolygon({
                                id: idInfo,
                                coordinates: cartographic,
                                name,
                                time,
                                height: toolMakeMonomer.height,
                                extrudedHeight: toolMakeMonomer.extrudedHeight,
                            });

                            monomerList.push({
                                coordinates: cartographic,
                                baseHeight: item.height,
                                height: item.extrudedHeight,
                                floorNum: 1,
                                floorHeight:
                                    toolMakeMonomer.extrudedHeight -
                                    toolMakeMonomer.height,
                                data: {
                                    id: houseList[houseModelIndex][
                                        houseIndexList[index]
                                    ].houseId,
                                    type: "house",
                                    name: houseList[houseModelIndex][
                                        houseIndexList[index]
                                    ].houseName,
                                    position: {},
                                },
                            });
                        });

                        this.$postJsonApi(api.monomerSave, {
                            monomerList,
                        }).then((res) => {
                            console.log(res);
                            _this.cancelClassified();
                        });
                    });

                    break;
            }

            if (this.cesiumMakeNewMonomer.type != "MONOMER3") {
                this.$postJsonApi(api.monomerSave, {
                    monomerList,
                }).then((res) => {
                    console.log(res);
                    _this.cancelClassified();
                });
            }
        },
        cancelClassified() {
            window.toolMakeMonomer.cancelClick();
            this.changeToolSlideShowType(1);
        },
        deleteClassified() {
            let { buildList, buildIndex } = this;
            console.log(buildList[buildIndex]);

            this.$getApi(api.monomerDelete, {
                areaId: buildList[buildIndex].id,
            }).then((res) => {
                if (1 == res.code) {
                    alert("删除成功");
                }
            });
        },
        getGridList(type) {
            // 获取网格列表
            let _this = this;

            let params = {};
            switch (type) {
                case "STREET":
                    break;
                case "COMMUNITY":
                    // params = {
                    //     orgId: "12cf6ee284d511e8aabc00163e06cded",
                    // };
                    break;
                case "GRID":
                    break;
                case "GARBAGE":
                    break;
            }

            this.$getApi(api.getAreaList, params).then((res) => {
                if (1 == res.code) {
                    _this.areaList = res.data.areaList;
                }
            });
        },
        getVillageList() {
            let _this = this;

            this.$getApi(api.getVillageList).then((res) => {
                if (1 == res.code) {
                    _this.villageList = res.data.building;

                    if (_this.villageList.length > 0) {
                        _this.getBuildList();
                    }
                }
            });
        },
        getBuildList() {
            let _this = this;
            let { villageList, villageIndex } = this;

            this.$getApi(api.getBuildList, {
                villageId: villageList[villageIndex].id,
            }).then((res) => {
                if (1 == res.code) {
                    _this.buildList = res.data.building;

                    if (_this.cesiumMakeNewMonomer.type == "MONOMER2") {
                        _this.getUnitList();
                    }
                }
            });
        },
        getUnitList() {
            let _this = this;
            let { buildList, buildIndex } = this;

            this.$getApi(api.getUnitList, {
                buildingId: buildList[buildIndex].id,
            }).then((res) => {
                if (1 == res.code) {
                    _this.unitList = res.data.cell;

                    if (_this.cesiumMakeNewMonomer.type == "MONOMER3") {
                        _this.getHouseList();
                    }
                }
            });
        },
        getHouseList() {
            let _this = this;
            let { unitList, unitIndex } = this;
            this.$getApi(api.getUnitInfo, {
                cellId: unitList[unitIndex].id,
            }).then((res) => {
                if (1 == res.code) {
                    let nameList = res.data.cellInfo.map((item) => {
                        return item.houseName;
                    });

                    _this.houseList = util.allListToGetHouseList(
                        res.data.cellInfo,
                        nameList
                    );
                }
            });
        },
        submitPoint() {
            // 提交标记点信息
            let _this = this;
            let {
                titleText,
                positionNo,
                toolDotClickGroupType,
                contentText,
                cesiumMakeNewPoint,
                imgListArr,
            } = this;

            if (titleText) {
                let params = {
                    type: cesiumMakeNewPoint.type,
                    title: titleText,
                    content: contentText,
                    pic: imgListArr,
                    id: "",
                    lon: cesiumMakeNewPoint.position.longitude,
                    lat: cesiumMakeNewPoint.position.latitude,
                    height: cesiumMakeNewPoint.position.height,
                    radius: 0,
                    positionNo: toolDotClickGroupType == 2 ? positionNo : "",
                };

                this.$getApi(api.saveDot, params).then((res) => {
                    window.toolMakePoint.savePoint();
                    _this.titleText = "";
                    _this.contentText = "";
                    _this.changeToolSlideShowType(1);
                });
            } else {
                alert("请输入标题");
            }
        },
        cancelPoint() {
            let _this = this;

            _this.titleText = "";
            _this.contentText = "";
            window.toolMakePoint.cancelInput();
            _this.changeToolSlideShowType(1);
        },
        deletePoint() {},
        selectChange(type, event) {
            let _this = this;
            let index = event.target.selectedIndex;
            switch (type) {
                case "gridName":
                    this.areaNameIndex = index;
                    break;
                case "gridColor":
                    this.areaColorIndex = index;
                    window.toolMakeArea.changeColor(
                        this.areaColorList[index].color
                    );
                    break;
                case "villageName":
                    this.villageIndex = index;
                    this.getBuildList();
                    break;
                case "buildName":
                    this.buildIndex = index;

                    if (
                        this.cesiumMakeNewMonomer.type == "MONOMER2" ||
                        this.cesiumMakeNewMonomer.type == "MONOMER3"
                    ) {
                        this.getUnitList();
                    }
                    break;
            }
        },
        chooseUnit(unit, index) {
            this.unitIndex = index;
            if (this.cesiumMakeNewMonomer.type == "MONOMER3") {
                this.getHouseList();
            }
        },
        chooseHouseAll(houseModelIdx) {
            let _this = this;
            let { houseList, houseModelIndex, houseIndexList } = this;

            if (houseModelIdx != houseModelIndex) {
                this.houseList[houseModelIndex].forEach((item) => {
                    item.check = false;
                });
                this.houseIndexList = [];
                this.houseModelIndex = houseModelIdx;
            }

            if (this.houseIndexList.length < houseList[houseModelIdx].length) {
                this.houseList[houseModelIdx].forEach((item, index) => {
                    item.check = true;
                    let hasIndex = this.houseIndexList.some((houseIndex) => {
                        return houseIndex == index;
                    });

                    !hasIndex && this.houseIndexList.splice(index, 0, index);
                });
            } else {
                this.houseList[houseModelIdx].forEach((item) => {
                    item.check = false;
                });
                this.houseIndexList = [];
            }
        },
        chooseHouse(houseModelIdx, houseIdx) {
            let _this = this;
            let { houseList, houseModelIndex, houseIndexList } = this;

            if (houseModelIdx != houseModelIndex) {
                this.houseList[houseModelIndex].forEach((item) => {
                    item.check = false;
                });
                this.houseIndexList = [];
                this.houseModelIndex = houseModelIdx;
            }

            this.houseList[houseModelIdx].forEach((item, index) => {
                if (index == houseIdx) {
                    if (item.check) {
                        item.check = false;
                        this.houseIndexList = houseIndexList.reduce(
                            (newArr, curr) => {
                                if (curr != houseIdx) {
                                    newArr.push(curr);
                                }

                                return newArr;
                            },
                            []
                        );
                    } else {
                        item.check = true;
                        this.houseIndexList.push(index);
                    }
                }
            });
        },
        classifiedStratifyView() {
            // 分层预览
            let _this = this;
            let { houseList, houseModelIndex } = this;
            let { toolMakeMonomer } = window;
            return new Promise((resolve, reject) => {
                toolMakeMonomer.stratify(houseList[houseModelIndex]);
                resolve(1);
            });
        },
        submitGridArea() {
            let _this = this;
            let {
                areaList,
                areaNameIndex,
                areaColorList,
                areaColorIndex,
                cesiumMakeNewArea,
            } = this;

            let params = {
                coordinates: cesiumMakeNewArea.positionList,
                data: {
                    id: areaList[areaNameIndex].orgId,
                    type: cesiumMakeNewArea.type,
                    name: areaList[areaNameIndex].name,
                    position: {
                        lon: cesiumMakeNewArea.centerPosition.longitude,
                        lat: cesiumMakeNewArea.centerPosition.latitude,
                    },
                    colorDefault: areaColorList[areaColorIndex].color,
                    colorSelected: "white",
                },
            };

            this.$postApi(api.saveArea, params).then((res) => {
                // _this.toolMakePoint.savePoint();
                // _this.titleText = "";
                // _this.contentText = "";
                _this.changeToolSlideShowType(1);
            });
        },
        cancelGridArea() {
            let _this = this;

            window.toolMakeArea.cancelClick();
            _this.changeToolSlideShowType(1);
        },
        deleteGridArea() {
            let { areaList, areaNameIndex } = this;
            this.$getApi(api.monomerDelete, {
                areaId: areaList[areaNameIndex].orgId,
            }).then((res) => {
                if (1 == res.code) {
                    alert("删除成功");
                }
            });
        },
        chooseImage() {
            document.getElementById("upload_file").click(); //触发input-file文件上传控件 事件photoChange
        },
        photoChange(event) {
            // const file = e.target.files[0]
            // // 得到文件名称
            // const fileName = file.name
            // // 得到文件后缀
            // const fileExt = file.name.split('.').pop().toLocaleLowerCase()

            var reader = new FileReader();
            let fileData = this.$refs.InputFile.files[0];
            reader.readAsDataURL(fileData);
            let _this = this;
            reader.onload = function (e) {
                //这里的数据可以使本地图片显示到页面
                _this.addimg = e.srcElement.result;
            };
            // 使用formapi打包
            let formData = new FormData();
            formData.append("file", fileData);
            axios
                .post(
                    "http://renda.liaodukeji.com/fileCommon/webUploadFile",
                    formData
                )
                .then(function (res) {
                    let imgList = [];

                    _this.addimgtijiao = res.data.fileUrl;
                    imgList.push(_this.addimgtijiao);
                    _this.imgListArr = [..._this.imgListArr, ...imgList];
                    console.log(_this.imgListArr);
                    // imgList.push(_this.addimgtijiao)
                });
        },
        delimg(item, index) {
            let _this = this;
            _this.imgListArr.splice(index, 1);
        },
    },
};
