
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import api from "@/api/index.js";
import util from "@/assets/js/util";

import ToolTop from "../components/tool/top";
import ToolIconRight from "../components/tool/right.vue";
import ToolRoomLeft from "../components/slideRoom/toolRoomLeft";
import ToolRoomRight from "../components/slideRoom/toolRoomRight";
import Windialog from "../components/publicDillog/winDialog";

import CesiumRoom from "../components/cesiumRoom";
import Loading from "@/views/loading/index.vue";
import BottomRoom from "@/views/slideBottom/bottom";
import StreetIndex from "@/views/street/index.vue";
import GFZZIndex from "@/views/gfzz/index.vue";
import MSBZIndex from "@/views/msbz/index.vue";
import HLDJIndex from "@/views/hldj/index.vue";
import PAJKIndex from "@/views/pajk/index.vue";
import CSGLIndex from "@/views/csgl/index.vue";
import MonomerSlide from "@/views/monomerInfo/index.vue";
import crowdSlide from "@/views/monomerInfo/crowd.vue";

export default {
  name: "Home",
  components: {
    Windialog,
    BottomRoom,
    ToolTop,
    ToolIconRight,
    ToolRoomLeft,
    CesiumRoom,
    ToolRoomRight,
    Loading,
    StreetIndex,
    GFZZIndex,
    MSBZIndex,
    HLDJIndex,
    PAJKIndex,
    CSGLIndex,
    MonomerSlide,
    crowdSlide
  },
  computed: {
    ...mapGetters("publicInfo", ["nowOrgId"]),
    ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
    ...mapGetters("msbz", ["msbzData"]),
    ...mapGetters("pajk", ["pajkData"]),
    ...mapGetters("hldj", ["hldjData"]),
    ...mapState(["refreshNumber", "configInfo"]),
  },
  watch: {
    gfzzGetApi(newData, oldData) {
      if (newData) {
        this.getApiTimeString = new Date().getTime();
        this.startGetApi = true;
      }
    },
    startGetApi(newState, oldState) {
      let _this = this;
      let { startGetApiSetTime } = this;
      if (newState && newState != oldState) {
        setTimeout(() => {
          // _this.getApi();
        }, startGetApiSetTime);
      }
    },
    nowOrgId(newId, oldId) {
      let _this = this;
      if (this.ws) {
        this.websocketClose().then((code) => {
          _this.createWebSocket(newId);
        });
      } else {
        _this.createWebSocket(newId);
      }
    },
  },
  data() {
    return {
      startGetApi: false,
      getApiSetTime: 5000,
      getApiTimeString: new Date().getTime(),
      ws: null,
      wsUrl: "",
      socketHeart: {
        timeout: 6000,
        timeoutObj: null,
        serverTimeoutObj: null,
        code: "66666",
      },
      lockReconnect: false,
      reconnectTimer: null,
    };
  },
  mounted() {
    let _this = this;

    window.onresize = function () {
      _this.changeEChartsLoad(false);

      if (_this.windowResizeTimer) {
        clearTimeout(_this.windowResizeTimer);
        _this.windowResizeTimer = null;
      }

      _this.windowResizeTimer = setTimeout(() => {
        _this.changeEChartsLoad(true);
      }, 500);
    };
    // this.changeSlideRoomShowAction('street')
  },
  methods: {
    ...mapMutations("publicInfo", ["changeEChartsLoad"]),
    ...mapMutations("gfzz", ["changeGFZZGetApi", "changeGFZZData"]),
    ...mapMutations("msbz", ["changeGFZZGetApi", "changeGFZZData"]),
    ...mapMutations("pajk", ["changeGFZZGetApi", "changeGFZZData"]),
    ...mapMutations("csgl", ["changeGFZZGetApi", "changeGFZZData"]),
    ...mapMutations("hldj", ["changeGFZZGetApi", "changeGFZZData"]),
    ...mapActions("publicInfo", ["changeSlideRoomShowAction"]),
    ...mapActions(["changeCommonDataAction", "changeRefreshNumberAction"]),
    strikeRefsWithAreaCameraFlyTo() {
      let nowEntityId = this.$refs.cesiumRoom.$data.nowEntityId;
      let viewerInfo = {
        height: 1500,
        orientation: {
          heading: 90,
          pitch: -90,
          roll: 0,
        },
      };
      this.$refs.cesiumRoom.areaCameraFlyTo(nowEntityId, viewerInfo);
    },
    createWebSocket(orgId) {
      let _this = this;
      let { wsUrl, configInfo } = this;
      try {
        // this.wsUrl = configInfo.websocketApi + "/websocket/" + orgId ;
        this.wsUrl =
          configInfo.websocketApi +
          "/websocket/" +
          orgId +
          "/" +
          util.createNonceStr();
        console.log(this.wsUrl);
        this.ws = new WebSocket(_this.wsUrl);
        _this.websocketInit();
      } catch (e) {
        _this.reconnect(wsUrl);
      }
    },
    websocketInit() {
      let _this = this;
      let { refreshNumber, socketHeart, gfzzData, gfzzGetApi } = this;
      console.log(11);
      if ("WebSocket" in window) {
        // var ws = new WebSocket("ws://localhost:8880/websocket/a849dbae84d611e8aabc00163e06cded");

        this.ws.onopen = function () {
          _this.heartStart();
        };

        this.ws.onmessage = function (e) {
          var received_msg = JSON.parse(e.data);
          _this.heartStart();

          if (
            received_msg.content.code &&
            received_msg.content.code == socketHeart.code
          ) {
            console.log("心跳检测返回");
          } else {
            _this.changeRefreshNumberAction(++refreshNumber);

            if (received_msg.content.gfzz) {
              if (
                _this.gfzzGetApi &&
                received_msg.content.gfzz.point_exchange
              ) {
                received_msg.content.gfzz.point_exchange =
                  received_msg.content.gfzz.point_exchange.concat(
                    _this.gfzzData.point_exchange
                  );
              }

              _this.changeGFZZData(received_msg.content.gfzz);
            }

            if (received_msg.content.msbz) {
              _this.changeMSBZData(received_msg.content.msbz);
            }

            if (received_msg.content.pajk) {
              _this.changePAJKData(received_msg.content.pajk);
            }

            if (received_msg.content.csgl) {
              _this.changeCSGLData(received_msg.content.csgl);
            }

            if (received_msg.content.hldj) {
              _this.changeHLDJData(received_msg.content.hldj);
            }

            if (received_msg.content.common) {
              _this.changeCommonDataAction(received_msg.content.common);
            }
          }
        };

        this.ws.onclose = function () {
          // 关闭 websocket
          console.log("连接已关闭...");
        };
      }
    },
    websocketClose() {
      let _this = this;

      return new Promise((resolve, reject) => {
        _this.ws.close();
        _this.ws = null;
        _this.wsUrl = "";
        _this.socketHeart = {
          timeout: 6000,
          timeoutObj: null,
          serverTimeoutObj: null,
        };
        _this.lockReconnect = false;
        _this.reconnectTimer = null;
        resolve(1);
      });
    },
    heartStart() {
      let { timeout, code } = this.socketHeart;
      var _this = this;
      this.timeoutObj && clearTimeout(_this.timeoutObj);
      this.serverTimeoutObj && clearTimeout(_this.serverTimeoutObj);
      this.timeoutObj = setTimeout(function () {
        _this.ws.send(
          JSON.stringify({
            id: "",
            msgType: "",
            fromUser: "",
            toUser: "",
            content: {
              code: code,
            },
            dealUrl: "",
          })
        );
        _this.serverTimeoutObj = setTimeout(function () {
          console.log("后台挂掉，没有心跳了....");
          _this.websocketClose();
        }, timeout);
      }, timeout);
    },
    reconnect(url) {
      let _this = this;
      let { lockReconnect, reconnectTimer, nowOrgId } = this;
      if (lockReconnect) {
        return;
      }
      this.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      reconnectTimer && clearTimeout(reconnectTimer);
      this.reconnectTimer = setTimeout(function () {
        _this.createWebSocket(nowOrgId);
        _this.lockReconnect = false;
      }, 20000);
    },
    getApi() {
      let _this = this;
      let { getApiSetTime, refreshNumber } = this;

      setInterval(() => {
        _this
          .$getApi(api.refresh, {
            queryTime: _this.getApiTimeString,
          })
          .then((res) => {
            if (1 == res.code) {
              _this.getApiTimeString = res.data.queryTime;

              _this.changeGFZZData(res.data.gfzz);
              _this.changeMSBZData(res.data.msbz);
              _this.changePAJKData(res.data.pajk);
              _this.changeCSGLData(res.data.csgl);
              _this.changeHLDJData(res.data.hldj);
              _this.changeCommonDataAction(res.data.common);

              _this.changeRefreshNumberAction(++refreshNumber);
            }
          });
      }, getApiSetTime);
    },
  },
};
