
import { mapGetters } from "vuex";
import Modal from "@/components/publicDillog/index.vue";
import TitleText from "@/components/titleText.vue";

export default {
    components: {
        Modal,
        TitleText
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {
            show: false,
            left: "",
            top: "",
            activityList: [],
            hldjcolor: "",
            leftstatu: 0,
            topstatu: 0,
            leftlineheight: 0,
            toplineheight: 0,
            lineleft: "",
            linetop: "",
            toplineleft: "",
            toplinetop: "",
            diaValue: "",
            diaContent: "",
            posIndex: -1,
        };
    },

    computed: {
        ...mapGetters("hldj", ["hldjData", "hldjGetApi"]),
        swiperOptionsList() {
            let _this = this;

            return {
                activity: {
                    autoplay: {
                        disableOnInteraction: false,
                        delay: 3000,
                    }, //可选选项，自动滑动
                    slidesPerView: 2,
                    // width: 5 * fontBaseSize * scaleNum,
                    // height: ((windowHeight / 3) - (1.5 * fontBaseSize)),
                    observer: true,
                    direction: "vertical",
                },
            };
        },
    },
    watch: {
        hldjGetApi(newData, oldData) {
            let _this = this;

            if (newData && !oldData) {
                _this.changeList();
            }
        },
    },
    mounted() {
        let _this = this;
        if (this.hldjGetApi) {
            this.changeList();
        }
    },
    methods: {
        changeList() {
            let List = [];

            this.hldjData.partyActivity.forEach((item, index) => {
                if (index % 3 == 0) {
                    List.push(
                        this.hldjData.partyActivity.slice(index, index + 3)
                    );
                }
            });
            this.activityList = List;
        },

        changeStyle(item, index, e) {
            let { posIndex } = this;

            this.posIndex = index == posIndex ? -1 : index;
            this.diaContent = item.content;
            this.diaValue = item.photo;
            this.show = true;
            this.leftstatu = -74;
            this.topstatu = -20;
            this.hldjcolor = "#fc0000";
            this.leftlineheight = 210;
            this.toplineheight = 460;
            this.left = e.pageX - 300 + "px";
            this.top = e.pageY - 240 + "px";
            this.lineleft = e.pageX - 106 + "px";
            this.linetop = e.pageY - 140 + "px";
            this.toplineleft = e.pageX - 85 + "px";
            this.toplinetop = e.pageY - 450 + "px";
        },
        hideModal() {
            this.show = false;
        },
    },
};
