
import { mapGetters } from "vuex";
import Number from "@/components/number/index.vue";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    computed: {
        ...mapGetters("publicInfo", [
            "slideDomCssTransitionStart",
            "eChartsLoad",
        ]),
        ...mapGetters("street", ["streetGetApi", "streetData"]),
    },
    components: {
        Number,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {
            contentList: [
                {
                    title: "总户数",
                    number: 999,
                },
            ],

            numberStyle: {
                fontSize: 50,
                height: 50,
                width: 30,
                fontFamily: "DigitalReadoutThickUpright",
                color: "#2AF3FF",
            },
            pageData: {},
        };
    },
    watch: {
        slideDomCssTransitionStart(newStart, oldStart) {
            let _this = this;
            if (newStart) {
                _this.getContentList();
            }
        },
        eChartsLoad(newStaue, oldStaue) {
            let _this = this;
            let { streetGetApi } = this;

            if (streetGetApi && newStaue) {
                setTimeout(() => {
                    _this.drawEchart();
                }, 500);
            } else {
                let eChart = this.$echarts.init(
                    document.getElementById("householdNum")
                );

                eChart.dispose();
            }
        },
        streetGetApi(newData, oldData) {
            let _this = this;
            let { eChartsLoad } = this;

            if (newData && eChartsLoad) {
                _this.pageData = _this.streetData;
                setTimeout(() => {
                    _this.drawEchart();
                }, 500);
            }
        },
    },
    mounted() {
        if (this.streetGetApi) {
            this.pageData = this.streetData;
            setTimeout(() => {
                this.drawEchart();
                // _this.drawEchartRight();
            }, 500);
        }
    },
    methods: {
        drawEchart() {
            let _this = this;
            let { streetGetApi, pageData, streetData } = this;
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("householdNum")
            );

            let colorArray = [
                "#18A8F6",
                "#FFE32F",
                "#D9FF2F",
                "#90FF2F",
                "#3AFF2F",
                "#45FF70",
                "#23DE96",
                "#3AFFD4",
                "#23D9CD",
                "#2AF3FF",
                "#18E0F6",
                "#18C6F6",
            ];

            myChart.setOption({
                tooltip: {
                    show: false,
                    trigger: "item",
                },

                // legend: {
                //     top: "5%",
                //     left: "center",
                // },
                series: [
                    {
                        type: "pie",
                        center: ["50%", "50%"],
                        radius: ["70%", "80%"],
                        selectedMode: "single",
                        avoidLabelOverlap: false,
                        tooltip: {
                            show: true,
                            trigger: "item",
                            formatter: "{b}: {c}户",
                        },
                        itemStyle: {
                            normal: {
                                barBorderRadius: false,
                                color: "#1E9FFF",
                            },
                        },
                        label: {
                            show: false,
                            alignTo: "edge",
                            // formatter: "{b}: \n{c}个",
                            color: "#fff",
                            textBorderColor: "rgba(0,0,0,0)",
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(255, 255, 255, 0.3)",
                            },
                            label: {
                                show: true,
                                fontSize: "12",
                                textBorderColor: "rgba(0,0,0,0)",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: streetData.ph_h.map((item, index) => {
                            return {
                                value: item.value,
                                name: item.key,
                                itemStyle: {
                                    color: colorArray[index],
                                },
                            };
                        }),
                    },
                ],
            });
        },
        getContentList() {
            this.contentList[0].number = 256;
            // this.contentList[1].number = 154;
        },
    },
};
