
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import api from "@/api/index.js";

export default {
    name: "monomerBuild",
    computed: {
        ...mapGetters("publicInfo", ["slideShowType"]),
        ...mapGetters("cesiumInfo", ["nowChooseMonomerInfo", "nowChooseMonomerInfoObj"]),
    },
    watch: {
        nowChooseMonomerInfo(newInfo, oldInfo) {
            console.log(newInfo);
            if (this.slideShowType == "monomer_build") {
                this.getBuildInfo();
            }
        },
    },
    data() {
        return {
            buildInfo: {},
        };
    },
    mounted() {
        let _this = this;
        if (this.slideShowType == "monomer_build") {
            this.getBuildInfo();
        }
    },
    methods: {
        ...mapActions("publicInfo", ["changeSlideTypeAction"]),
        ...mapMutations("cesiumInfo", ["changeNowChooseMonomerInfoObj"]),
        getBuildInfo() {
            let _this = this;
            let { nowChooseMonomerInfo } = this;

            this.$getApi(api.getBuildingInfo, {
                buildingId: nowChooseMonomerInfo.id,
            }).then((res) => {
                console.log(res);
                if (1 == res.code) {
                    _this.buildInfo = res.data;
                }
            });
        },
        goUnitInfo(item) {
            let { buildInfo } = this;

            window.primitiveLayer.changePrimitiveColor(
                buildInfo.monomer.data.id,
                [0, 0, 0, 0]
            );

            window.primitiveLayer.changePrimitiveColor(item.cellId);
            this.changeSlideTypeAction("monomer_unit");
            this.changeNowChooseMonomerInfoObj({
                id: item.cellId,
            });
        },
        closeWindow() {
            let { buildInfo } = this;

            window.primitiveLayer.changePrimitiveColor(
                buildInfo.monomer.data.id,
                [0, 0, 0, 0]
            );

            this.changeSlideTypeAction("point");
        },
    },
};
