import {
  Viewer,
  Math,
  Cesium3DTileFeature,
  Cartesian2,
  Cartesian3,
  Cartographic,
  SceneTransforms,
  defined,
} from "cesium";

import "./info.css";
import util from "../../util.js";
import infoHtml from "./infoHtml";
import flvJs from "flv.js";
import DPlayer, { DPlayerVideo } from "dplayer";

// ====================
// 类
// ====================
/**
 * 信息工具。
 *
 * @alias InfoTool
 * @constructor
 * @param {Viewer} viewer Cesium视窗。
 */
class InfoTool {
  /**
   * 创建一个动态实体弹窗。
   *
   * @param {Viewer} viewer Cesium视窗。
   * @param {Number} options 选项。
   * @param {Cartesian3} options.position 弹出位置。
   * @param {HTMLElement} options.element 弹出窗元素容器。
   * @param {Function} callback 回调函数。
   * @ignore
   */
  static _createInfoTool(viewer, options, callback = undefined) {
    const cartographic = Cartographic.fromCartesian(options.position._value);
    const lon = Math.toDegrees(cartographic.longitude); //.toFixed(5);
    const lat = Math.toDegrees(cartographic.latitude); //.toFixed(5);

    // 注意，这里不能使用hide()或者display，会导致元素一直重绘。
    util.setCss(options.element, "opacity", "0");
    util.setCss(
      options.element.querySelector("div:nth-child(1)"),
      "height",
      "0"
    );
    util.setCss(
      options.element.querySelector("div:nth-child(2)"),
      "opacity",
      "0"
    );

    // 回调
    callback();

    // 添加div弹窗
    setTimeout(function () {
      InfoTool._popup(viewer, options.element, lon, lat, cartographic.height);
    }, 100);
  }
  /**
   * 弹出HTML元素弹窗。
   *
   * @param {Viewer} viewer Cesium视窗。
   * @param {Element|HTMLElement} element 弹窗元素。
   * @param {Number} lon 经度。
   * @param {Number} lat 纬度。
   * @param {Number} height 高度。
   * @ignore
   */
  static _popup(viewer, element, lon, lat, height) {
    // 设置元素效果
    util.setCss(element, "opacity", "1");
    util.setCss(
      element.querySelector("div:nth-child(1)"),
      "transition",
      "ease 0.2s"
    );
    util.setCss(
      element.querySelector("div:nth-child(2)"),
      "transition",
      "opacity 0.2s"
    );
    util.setCss(element.querySelector("div:nth-child(1)"), "height", "80px");
    util.setCss(
      element.querySelector("div:nth-child(2)"),
      "pointer-events",
      "auto"
    );
    util.setCss(element.querySelector(".content_room"), "opacity", "1");

    const divPosition = Cartesian3.fromDegrees(lon, lat, height);
    InfoTool._hookToGlobe(
      viewer,
      element,
      divPosition,
      [10, -parseInt(util.getCss(element, "height"))],
      true
    );
    viewer.scene.requestRender();
  }
  /**
   * 将HTML弹窗挂接到地球上。
   *
   * @param {Viewer} viewer Cesium视窗。
   * @param {Element} element 弹窗元素。
   * @param {Cartesian3} position 地图坐标点。
   * @param {Array} offset 偏移。
   * @param {Boolean} hideOnBehindGlobe 当元素在地球背面会自动隐藏，以减轻判断计算压力。
   * @ignore
   */
  static _hookToGlobe(viewer, element, position, offset, hideOnBehindGlobe) {
    const scene = viewer.scene,
      camera = viewer.camera;
    const cartesian2 = new Cartesian2();

    scene.preRender.addEventListener(function () {
      const canvasPosition = scene.cartesianToCanvasCoordinates(
        position,
        cartesian2
      ); // 笛卡尔坐标到画布坐标
      if (defined(canvasPosition)) {
        util.setCss(
          element,
          "left",
          //parseInt(canvasPosition.x + offset[0]) + "px"
          "calc(50% - 400px)"
        );
        util.setCss(
          element,
          "top",
          //parseInt(canvasPosition.y + offset[1]) + "px"
          "25%"
        );

        // 是否在地球背面隐藏
        if (hideOnBehindGlobe) {
          const cameraPosition = camera.position;
          let height =
            scene.globe.ellipsoid.cartesianToCartographic(
              cameraPosition
            ).height;
          height += scene.globe.ellipsoid.maximumRadius;
          if (!(Cartesian3.distance(cameraPosition, position) > height)) {
            util.setCss(element, "opacity", "1");
          } else {
            util.setCss(element, "opacity", "0");
          }
        }
      }
    });
  }

  _element;
  viewer;

  constructor(viewer, options = {}) {
    this.viewer = viewer;
    this._element = document.createElement("div");
    // this._element.id = "infoTool_" + util.getGuid(true);
    this._element.name = "infoTool";
    this._element.classList.add("info_tool_room");
    this.openId = [];

    let child_1 = document.createElement("div");
    util.setInnerHtml(
      child_1,
      `
            <div class="line"></div>
            <div class="line"></div>
        `
    );
    //child_1.classList.add("line_room");
    this._element.appendChild(child_1);

    let child_2 = document.createElement("div");
    child_2.classList.add("content_room");
    console.log(options);
    if (!options.type) {
      child_2.classList.add("tip_green");
    } else {
      child_2.classList.add("tip_" + options.type);
    }
    this._element.appendChild(child_2);

    viewer.container.appendChild(this._element);
  }

  /**
   * 添加。
   *
   * @param {Object} options 选项。
   * @param {Element} options.element 弹窗元素。
   * @param {Cartesian2|Cartesian3} options.position 点击位置。
   * @param {Cesium3DTileFeature} [options.inputFeature] 模型要素。
   * @param {String} options.type 类型（默认值为default，即任意点击模式；如果设置为info，即信息模式，只有点击Feature才会响应）。
   * @param {String} options.content 内容（只有类型为default时才起作用）。
   * @param {Function} callback 回调函数。
   */
  add(options, callback = undefined) {
    // 判断参数为空返回
    if (!options) {
      return;
    }
    console.log(options);
    // 点
    let position, cartesian2d, cartesian3d, inputFeature;
    console.log(options instanceof Cesium3DTileFeature);

    if (options instanceof Cesium3DTileFeature) {
      inputFeature = options;
      options = {};
    } else {
      console.log(
        options instanceof Cartesian2 || options instanceof Cartesian3
      );

      if (options instanceof Cartesian2 || options instanceof Cartesian3) {
        position = options;
        options = {};
      } else {
        position = options.position;
        inputFeature = options;
      }
      // 判断点位为空返回
      if (!position) {
        return;
      }

      console.log(position instanceof Cartesian2);

      if (position instanceof Cartesian2) {
        // 二维转三维
        // 如果支持拾取模型则取模型值
        cartesian3d =
          this.viewer.scene.pickPositionSupported &&
          defined(this.viewer.scene.pick(options.position))
            ? this.viewer.scene.pickPosition(position)
            : this.viewer.camera.pickEllipsoid(
                position,
                this.viewer.scene.globe.ellipsoid
              );
        cartesian2d = position;
      } else {
        console.log("需要检查marker的_value和单体之间的区别");
        cartesian3d = position._value;
        cartesian2d = SceneTransforms.wgs84ToWindowCoordinates(
          this.viewer.scene,
          cartesian3d
        );
      }
      // 判断点位为空返回
      if (!cartesian3d) {
        return;
      }
    }

    const that = this;

    // 1.弹窗内容
    let info = "";
    // if (options.type === "info") {
    if (
      options.type === "marker" ||
      options.type === "CAMERA" ||
      options.info.type === "collection"
    ) {
      // 拾取要素
      const feature = inputFeature || this.viewer.scene.pick(cartesian2d);
      console.log(feature);
      // 判断拾取要素为空返回
      if (!defined(feature)) {
        this.remove();
        return;
      }

      if (feature instanceof Cesium3DTileFeature) {
        // 3dtiles
        let propertyNames = feature.getPropertyNames();
        let length = propertyNames.length;
        for (let i = 0; i < length; ++i) {
          let propertyName = propertyNames[i];
          info +=
            '"' +
            (propertyName + '": "' + feature.getProperty(propertyName)) +
            '",\n';
        }
      } else if (feature.id) {
        // Entity
        if (feature.info) {
          this.openId.push();
          this._element.id = feature.info.id;
          let toolTipDom = document.querySelector(
            ".info_tool_room .content_room"
          );
          switch (feature.info.type) {
            case "CAMERA":
            case "safe_people_attention":
              info = infoHtml.getVideoTip(feature.info);
              if (!toolTipDom.classList.contains("tip_video")) {
                toolTipDom.classList.add("tip_video");
              }
              if (feature.info.platform === "2") {
                setTimeout(() => {
                  that.playLive(feature.info);
                }, 1000);
              }
              if (feature.info.platform === "0") {
                console.log("大华摄像头");
                let str = that.randomCoding(1000,9999);
                const websocket = new WebSocket(feature.info.wsUrl + str);
                let message = `{
                                  "from":"` + str + `",
                                  "to":"` + feature.info.wsId + `",
                                  "content":{
                                      "ip": "`+ feature.info.serialNumber +`",
                                      "username": "`+ feature.info.account +`",
                                      "password": "`+ feature.info.password +`",
                                      "channel": "`+ feature.info.channel +`",
                                      "stream": "`+ feature.info.stream +`",
                                      "cameratype": "1"
                                  }
                              }`;
                websocket.onopen = function (evt) {
                  //that.onOpen(evt);
                  websocket.send(message);
                };
                websocket.onclose = function (evt) {
                  that.onClose(evt);
                };
                websocket.onmessage = function (evt) {
                  let data = JSON.parse(evt.data);
                  if (data.content.code === 0) {
                    feature.info.content =
                      feature.info.rtmpUrl +
                      data.content.token;
                    if (!toolTipDom.classList.contains("tip_video")) {
                      toolTipDom.classList.add("tip_video");
                    }
                    setTimeout(() => {
                      that.playLive(feature.info);
                    }, 1000);
                    websocket.close();
                  } else {
                    feature.info.content = "http://180.101.226.115:58090/rtmpLive?app=live&stream=";
                  }
                };
                websocket.onerror = function (evt) {
                  console.log("error:evt");
                  console.log(evt);
                  console.log("error:evt");
                };
              }

              break;
            case "help":
              info = infoHtml.getHelpInfoTip(feature.info);
              break;
            case "SMART_WATER":
              info = infoHtml.getSmartWaterTip(feature.info);
              break;
            case "GARBAGE_STATION":
              console.log(feature.info);
              if (!toolTipDom.classList.contains("tip_garbage")) {
                toolTipDom.classList.add("tip_garbage");
              }
              info = infoHtml.getPropertyCompanyTip(feature.info);
              break;
            case "PROPERTY_COMPANY":
              info = infoHtml.getPropertyCompanyTip(feature.info);
              break;
            case "SHARED_PARKING":
              info = infoHtml.getSharedParkingTip(feature.info);
              break;
            case "fire":
              info = infoHtml.getFireTip(feature.info);
              break;
            default:
              break;
          }
        }
      }
    } else {
      options.content && (info = options.content);
    }

    // 2.生成特效
    // 添加之前先移除
    console.log(options.info.add);
    if (!options.info.add) {
      this.remove();
    }

    if (!info) {
      return;
    }

    options.position = cartesian3d;
    options.element = options.element || this._element;
    console.log(this._element);
    InfoTool._createInfoTool(this.viewer, options, function () {
      util.setCss(that._element, "z-index", "10");
      util.setInnerHtml(that._element.querySelector(".content_room"), info);
      typeof callback === "function" && callback();
    });
  }
  randomCoding(Min,Max) {
    return parseInt(Math.randomBetween(Min, Max));
  }

  onOpen(evt) {
    console.log("已连接", "system");
  }

  onClose(evt) {
    console.log("连接已断开", "system");
  }

  onMessage(evt) {
    console.log(evt.data, "server");
  }

  onError(evt) {
    console.log("错误:" + evt.data, "system");
  }

  /**
   * 移除。
   *
   */
  remove(entityId = undefined) {
    console.log(entityId);
    console.log(this._element);
    // if (entityId) {
    //     let removeDom = document.getElementById(entityId)
    //     console.log(removeDom)
    //     removeDom.remove()
    //     this._element = document.createElement("div")
    // } else {
    util.setCss(this._element, "opacity", "0");
    util.setCss(this._element, "z-index", "-1");
    util.setCss(
      this._element.querySelector("div:nth-child(1)"),
      "transition",
      ""
    );
    util.setCss(
      this._element.querySelector("div:nth-child(2)"),
      "transition",
      ""
    );
    util.setCss(this._element.querySelector("div:nth-child(1)"), "height", "0");
    util.setCss(
      this._element.querySelector("div:nth-child(2)"),
      "pointer-events",
      "none"
    );
    // }
  }

  // 播放视频
  playLive(data) {
    let _this = this;
    console.log("data");
    console.log(data);
    console.log("data");

    if (flvJs.isSupported()) {
      let videoElement = document.getElementById("videoElement");
      let flvPlayer = flvJs.createPlayer({
        type: "flv",
        isLive: true,
        hasAudio: false,
        //url: "http://180.101.226.115:58088/rtmpLive?app=live&stream=1bf013df-5447-4fc0-8a35-5958d4e4b3ad",
        url: data.content,
      });
      console.log(videoElement);

      console.log(flvPlayer, "flv对象");
      flvPlayer.attachMediaElement(videoElement);
      flvPlayer.load();
      flvPlayer.play();
    }

    // let video = {
    //     // url: res.data.UAVFLVURL,
    //     // url: "http://58.200.131.2:1935/livetv/cctv5",
    //     // url: "http://play3.skysys.cn/xl_uav/ch29.flv?auth_key=1622085946-0-0-c2365b65696fa892c0d0e80c9cbbc8bf",
    //     url: data.content,
    //     // url: "https://rtmp01open.ys7.com:9188/v3/openlive/E52103600_1_1.flv?expire=1658382999&id=339767982039818240&t=f06e5e107ad4603a17a4d16abef64758ed85ba67608f242c056bee33660ced38&ev=100",
    //     type: "customFlv",
    //     customType: {
    //         customFlv: function (video, player) {
    //             const flvPlayer = flvJs.createPlayer({
    //                 type: "flv",
    //                 url: video.src,
    //             });
    //             flvPlayer.attachMediaElement(video);
    //             flvPlayer.load();
    //         },
    //     },
    // };

    // this.playVideo({
    //     video: video,
    //     domId: data.id
    // });
  }

  playVideo(data) {
    let { video } = data;
    let _this = this;

    let dp = new DPlayer({
      container: document.getElementById(data.domId || "dplayer"),
      video,
    });

    // if (this.playUrl === video.url) {
    //     if (this.isLiveShow) {
    //         // this.handleLiveVisibleChange(false);
    //         this.isLiveShow = false;
    //     } else {
    dp.play();
    //         this.isLiveShow = true;
    //     }
    // } else {
    //     this.playUrl = video.url;
    //     this.isLiveShow = true;

    if (dp) {
      //@ts-ignore
      dp.switchVideo(video);
    } else {
      dp = new DPlayer({
        live: true,
        mutex: false,
        // container: _this.$refs.dplayer as HTMLElement,
        container: document.getElementById(data.domId || "dplayer"),
        video,
      });
    }
    // }
  }
}

export default InfoTool;
