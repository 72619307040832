
import { mapGetters, mapMutations } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import api from "@/api/index";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    components: {
        Swiper,
        SwiperSlide,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    computed: {
        ...mapGetters("msbz", ["msbzData", "msbzGetApi"]),
        swiperList() {
            let _this = this;

            return {
                livelyFacilitySwiper: _this.$refs.livelyFacilitySwiper.$swiper,
            };
        },
        facilitySwiperOption() {
            let _this = this;
            return {
                autoplay: {
                    disableOnInteraction: false,
                    delay: 4500,
                },
                direction: "vertical",
                observer: true,
                slidesPerView: 2,
                on: {
                    init: function (swiper) {
                        //Swiper初始化了
                        setTimeout(() => {
                            let { swiperList } = _this;
                            swiperList.livelyFacilitySwiper.$el[0].onmouseenter =
                                function () {
                                    swiperList.livelyFacilitySwiper.autoplay.stop();
                                };
                            swiperList.livelyFacilitySwiper.$el[0].onmouseleave =
                                function () {
                                    swiperList.livelyFacilitySwiper.autoplay.start();
                                };
                        }, 1000);
                    },
                },
            };
        },
    },
    watch: {
        msbzGetApi(newData, oldData) {
            if (newData) {
                this.getNewFacilityList();
                this.getDotIcon();
                // this.facilityList = this.msbzData.guarantee;
            }
        },
    },
    data() {
        return {
            bgColorIndex: -1,
            facilityList: [],
            facilitySelectIndex: -1,
            dotIconList: [],
            dotIconSelectIndex: -1,
            chooseDotInfo: {
                type: "",
            },
        };
    },
    mounted() {
        if (this.msbzGetApi) {
            this.getNewFacilityList();
            this.getDotIcon();
        }
    },

    methods: {
        ...mapMutations("cesiumInfo", ["changeCesiumMarkerList"]),
        getDotIcon() {
            let _this = this;
            this.$getApi(api.getDotIcon).then((res) => {
                if (1 == res.code) {
                    _this.dotIconList = res.data.dotIconList;
                }
            });
        },
        showIcon(dotInfo, rowIndex, colIndex) {
            let _this = this;
            // TODO: strikeRefsWithAreaCameraFly 未处理
            // this.$emit("strikeRefsWithAreaCameraFly");
            let { dotIconList, chooseDotInfo } = this;

            this.$getApi(api.getDotList, {
                type: dotInfo.type,
                page: 1,
                size: 1000,
            }).then((res) => {
                if (1 == res.code) {
                    let markerList = [];
                    if (chooseDotInfo.type == dotInfo.type) {
                        _this.facilitySelectIndex = -1;
                        _this.dotIconSelectIndex = -1;
                        _this.chooseDotInfo = {
                            type: "",
                        };
                    } else {
                        _this.facilitySelectIndex = rowIndex;
                        _this.dotIconSelectIndex = colIndex;
                        _this.chooseDotInfo = dotInfo;

                        markerList = res.data.dotList.map((item) => {
                            return {
                                id: item.id,
                                info: {
                                    type: item.type,
                                    id: item.id,
                                },
                                position: {
                                    lon: item.lon,
                                    lat: item.lat,
                                    height: item.height,
                                },
                                text: "",
                                icon: {
                                    src: dotIconList.filter((dot) => {
                                        return dot.type == item.type;
                                    })[0].image,
                                    width: 45,
                                    height: 45,
                                },
                            };
                        });
                    }

                    _this.changeCesiumMarkerList(markerList);
                }
            });
        },
        changeBg(index) {
            let _this = this;
            _this.bgColorIndex = index;
        },
        getNewFacilityList() {
            let _this = this;
            let { msbzData, facilityList } = this;
            msbzData.guarantee.forEach((item, index) => {
                if (index % 4 == 0) {
                    _this.facilityList.push(
                        msbzData.guarantee.slice(index, index + 4)
                    );
                }
            });
        },
    },
};
