
import { mapGetters, mapActions } from "vuex";
import Number from "@/components/number/index.vue";
import TitleText from "@/components/titleText.vue";

export default {
    name: "componentDemo",
    computed: {
        ...mapGetters("publicInfo", [
            "slideDomCssTransitionStart",
            "eChartsLoad",
        ]),
        ...mapGetters("hldj", ["hldjData", "hldjGetApi"]),
    },

    components: {
        Number,
        TitleText,
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    data() {
        return {
            partyNumber: 0,
            partyPartNumber: [],
        };
    },
    watch: {
        hldjGetApi(newData, oldData) {
            if (newData) {
                this.getCmpData();
                this.drawEchart();
            }
        },
    },
    mounted() {
        if (this.hldjGetApi) {
            this.getCmpData();
            this.drawEchart();
        }
    },
    methods: {
        ...mapActions("cesiumInfo", ["changeCesiumMarkerList"]),
        getCmpData() {
            this.partyNumber = this.hldjData.party_number;
            this.partyPartNumber = this.hldjData.party_part_number;
        },
        drawEchart() {
            let { partyPartNumber } = this;

            let yData = [];
            let xData = [];
            partyPartNumber.map((item) => {
                yData.push(item.key);
                xData.push(item.value);
            });
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("organizationRight")
            );

            myChart.setOption({
                grid: {
                    left: "5%",
                    right: "5%",
                    top: "5%",
                    bottom: "5%",
                    containLabel: true,
                },
                tooltip: {
                    trigger: "item",
                    textStyle: {
                        align: "left",
                    },
                    formatter: "{b}: {c}人",
                },
                xAxis: {
                    type: "value",
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "rgba(255, 255, 255, 1)",
                        },
                    },
                },
                yAxis: {
                    type: "category",
                    axisLine: {
                        show: false,
                    },
                    labelLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "rgba(255, 255, 255, 1)",
                            fontSize: 14,
                        },
                    },
                    data: yData,
                },
                series: [
                    {
                        name: "",
                        type: "bar",
                        barWidth: 15,
                        data: xData,
                        itemStyle: {
                            normal: {
                                color: this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    1,
                                    0,
                                    [
                                        {
                                            offset: 0,
                                            color: "#E70626", // 0% 处的颜色
                                        },
                                        {
                                            offset: 0.6,
                                            color: "#EB920A", // 60% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "#FFE32A ", // 100% 处的颜色
                                        },
                                    ],
                                    false
                                ),
                            },
                        },
                    },
                ],
            });
        },
    },
};
