
export default {
    name: "modal",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        tranLeft: {
            type: String,
            default: "",
        },
        tranTop: {
            type: String,
            default: "",
        },
        lefttranLeft: {
            type: String,
            default: "",
        },
        lefttranTop: {
            type: String,
            default: "",
        },
        topLineLeft: {
            type: String,
            default: "",
        },
        toplineTop: {
            type: String,
            default: "",
        },
        leftRotate: {
            type: Number,
            default: -60,
        },
        topRotate: {
            type: Number,
            default: -18,
        },
        leftLineheight: {
            type: Number,
            default: 415,
        },
        topLineheight: {
            type: Number,
            default: 511,
        },
        hldjColor: {
            type: String,
            default: "#1965cb",
        },
        leftStatu: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            left: "",
            top: "",
            isleft: "",
            isLeftOne: "",
        };
    },
    mounted() {
        //     this.node = document.querySelector('.modal-container')
    },
    methods: {
        hideModal() {
            this.$emit("hideModal");
        },

        submit() {
            this.$emit("submit");
        },
    },
};
