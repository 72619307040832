
import { CollBox, Ball } from "@/assets/js/public/collision";
import publicFunc from "@/assets/js/public/index";
import TitleText from "@/components/titleText.vue";
import { mapState, mapGetters } from "vuex";

export default {
    name: "componentDemo",
    props: {
        title: {
            type: String,
            default: () => {
                return "组件标题";
            },
        },
        slideType: {
            type: String,
            default: () => "left",
        },
    },
    components: {
        TitleText,
    },
    computed: {
        ...mapGetters("gfzz", ["gfzzData", "gfzzGetApi"]),
        ...mapGetters("config", ["configInfo"]),
        ...mapState(["refreshNumber"]),
    },
    watch: {
        gfzzGetApi(newData, oldData) {
            if (newData) {
                this.cmpData = this.gfzzData;
                this.ballContainer();
            }
        },
        refreshNumber(newNumber, oldNumber) {
            if (newNumber > 0) {
                this.newSignMember();
            }
        },
    },
    data() {
        return {
            signList: [],
            signNumber: 0,
            signMaxNumber: 10,
            redemptionList: [],
            cmpData: {
                point_sign: [],
                point_sign_first: {},
                point_exchange: [],
                point_sign_count: 0,
            },
        };
    },
    mounted() {
        let { gfzzData } = this;
        if (this.gfzzGetApi) {
            this.cmpData = {
                point_sign: gfzzData.point_sign,
                point_sign_first: gfzzData.point_sign_first,
                point_exchange: gfzzData.point_exchange,
                point_sign_count: gfzzData.point_sign_count,
            };
            this.ballContainer();
        }
    },
    methods: {
        ballContainer() {
            // 民意直通车生成碰撞球
            let _this = this;
            var says = this.cmpData.point_sign;
            // var saysLength = says.length ? says.length : 6;
            var oC = document.getElementById("recordContainer");
            var mxwidth = oC.offsetWidth;
            var mxheight = oC.offsetHeight;

            var oB = new CollBox("recordContainer");
            oB.ballRun();

            says.forEach((item, index) => {
                var b = publicFunc.rand(5, 10);
                var x = publicFunc.rand(b, mxwidth - b);
                var y = publicFunc.rand(b, mxheight - b);
                var ball = new Ball({
                    b: b,
                    x: x,
                    y: y,
                    sx: publicFunc.rand(1, 2),
                    sy: publicFunc.rand(1, 2),
                    opa: 1,
                    fontSize: publicFunc.rand(10, 16),
                    callBack: function (n) {
                        this.setOpa(publicFunc.rand(30, 100) / 100);
                    },
                });

                ball.setHTML(`
                    <div class="_tagBall">
                        <img class="_ball_image" src="${item.icon}" />
                        <div class="_ball_name _ball_name_bg_transparent">${item.name}</div>
                    </div>
                `);

                oB.addBall(ball);
            });
        },

        signInMemberMounted() {
            let _this = this;

            let { signMaxNumber, cmpData } = this;

            this.signNumber = cmpData.point_sign.length;
            cmpData.point_sign.forEach((item, index) => {
                setTimeout(() => {
                    _this.signList.push(
                        Object.assign(item, {
                            left: publicFunc.rand(0, 70),
                            top: publicFunc.rand(0, 60),
                        })
                    );
                    if (_this.signList.length > signMaxNumber) {
                        _this.signList.splice(0, 1);
                    }
                }, 1000 * index);
            });
        },
        newSignMember() {
            let _this = this;

            let { signMaxNumber, cmpData } = this;

            cmpData.point_sign &&
                cmpData.point_sign.forEach((item) => {
                    _this.signList.push(
                        Object.assign(item, {
                            left: publicFunc.rand(0, 70),
                            top: publicFunc.rand(0, 60),
                        })
                    );
                    if (_this.signList.length > signMaxNumber) {
                        _this.signList.splice(0, 1);
                    }
                });
        },
    },
};
